import React from 'react';
import { connect } from 'react-redux';
import Router from './../../Router';
import './App.css';
import { watchExpirationTime } from '../../store/actions';

class App extends React.Component{

    componentDidMount() {
        if (this.props.isAuth) {
            this.props.onWatchExpirationTime()
        }
    }
    
    render() {
        return (
            <Router />
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuthenticated,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onWatchExpirationTime: () => dispatch(watchExpirationTime())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
import React from 'react';
import { Link } from 'react-router-dom';
import "./FiltroOpcion.css";

export default class FiltroOpcion extends React.Component{
    render() {
        return (
            <Link to={'/puestos/' + this.props.filtro} className="filtroOpcion">
                <img src={this.props.image} alt={this.props.alt} />
                <p>{this.props.text}</p>
            </Link>
        );
    }
}

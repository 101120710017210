import React from 'react';
import $ from "jquery";

import RegistroEncabezado from "./../RegistroEncabezado/RegistroEncabezado";
import RegistroNuevoIdioma from "./../Registro/RegistroNuevoIdioma";
import Select from 'react-select';


import "./Registro.css";

class RegistroPaso2 extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            lenguajes: [],
            limitacion: '',
            licencia: '',
            carro: '0'
        };
    }
    
    agregarIdioma(ev){
        ev.preventDefault();
        let lenguajes_list = this.props.data.lenguajes;
        lenguajes_list.push({id:"", name:"", level: ''});

        this.props.enviaDatos({lenguajes: lenguajes_list});
    }

    hideOrShowError = (name, errors, invalid) => {
        if(invalid){
            for(let item of errors){
                if(`${name}Error` === item.className){
                    document.querySelector('.' + item.className).innerHTML = item.error;
                    break;
                }
            }
        }
        else{
            $(`.${name}Error`).html('');
        }
    }

    languageHandler = (index, event) => {
        const value = event.target.value
        let lenguajes_list = this.props.data.lenguajes;
        lenguajes_list[index].name = value;
        this.props.enviaDatos({lenguajes: lenguajes_list})
    }

    languageLevelHandler = (index, event) => {
        const value = event.target.value
        let lenguajes_list = this.props.data.lenguajes;
        lenguajes_list[index].level = value;
        this.props.enviaDatos({lenguajes: lenguajes_list})
    }

    submitHandler = (e) => {
        e.preventDefault();

        let limitacion = $('#limitacion').val();
        //let licencia = $('#licencia').val();
        let carro = $('input[name=carro]:checked').val();
        let lenguajes = [];
        
        for (let i=0; i < this.props.data.lenguajes.length; i++){
            lenguajes[i] = {
                lenguaje: $('#lenguaje'+i).val(),
                nivel: $('#nivel'+i).val()
            }
        }

        let errors = [];

        // Validación

        // Limpiando mensajes de error anteriores
        $('.error').html('');

        // if (limitacion === ""){
        //     errors.push({className: 'limitacionError', error: "Este campo es requerido"});
        // }

        if (carro === ""){
            errors.push({className: 'limitacionError', error: "Este campo es requerido"});
        }

        for (let i in lenguajes){
            if (lenguajes[i].lenguaje === ''){
                errors.push({className: 'lenguajeError'+i, error: "Este campo es requerido"});
            }
            if (lenguajes[i].nivel === ''){
                errors.push({className: 'lenguajeNivelError'+i, error: "Este campo es requerido"});
            }
        }


        if (errors.length > 0){
            errors.map((item) => {
                document.querySelector('.' + item.className).innerHTML = item.error;
                return null;
            });
            
            // Scrolling to top with animation
            $('html,body').animate({ scrollTop: 0 }, 'slow');
            
        }
        else {
            const languages = lenguajes.map(item => item.lenguaje);
            const languages_level = lenguajes.map(item => item.nivel);
            const disabilities = [];
            const licenses = [];
            if (this.props.data.limitacion !== 'Ninguna' || this.props.data.limitacion !== '') {
                disabilities.push(this.props.data.limitacion);
            }
            if (this.props.data.licencia !== '') {
                licenses.push(this.props.data.licencia);
            }

            this.props.enviaDatos({
                languages,
                languages_level,
                disabilities,
                licenses,
                vehicle: this.props.data.carro
            });
            this.props.goNext();
        }
    }

    backHandler = (e) => {
        e.preventDefault();
        this.props.retornaPaso1();
    }

    changeHandler = event => {
        const name = event.target.id;
        const value = event.target.value;

        let updatedProperty = {};
        updatedProperty[name] = value;

        this.props.enviaDatos(updatedProperty);
    }

    eliminaIdioma = (e) => {
        e.preventDefault();
        this.props.data.lenguajes.splice(e.target.id,1);
        this.props.enviaDatos({lenguajes: this.props.data.lenguajes});
    }

    render() {
        let limitaciones, licencias, idiomas, idiomas_nivel = null;
        if (this.props.infoRegistro) {
            limitaciones = this.props.infoRegistro.disabilities.map(item => ({label: item.name, value: item.id }));
            licencias = this.props.infoRegistro.licenses.map(item => ({label: item.type, value: item.id }));
            idiomas = this.props.infoRegistro.languages.map( data => <option key={data.id} value={data.id}>{data.language}</option>);
            idiomas_nivel = this.props.infoRegistro.languages_level.map( data => <option key={data.id} value={data.id}>{data.level}</option>);
        }
        return (
            <div className="registro paso2">
                <RegistroEncabezado paso="2"></RegistroEncabezado>
                <form onSubmit={this.submitHandler}>
                    <div className="content container">
                        <h1>Características</h1>
                        <h4>4. ¿Tenés alguna limitación permanente?</h4>
                        <p>Ejemplos: uso de silla de ruedas; dificultad para oir, hablar, caminar o utilizar brazos o piernas; problemas de lenguaje u otros. Si no tenés alguna limitación permanente, no hay ningún problema. Podés dejar este espacio vacío. </p>
                        <div className="error limitacionError"></div>
                        {/* <select id="limitacion" onChange={this.changeHandler} value={this.props.data.limitacion}>
                            <option value="">* Elegir limitación permanente</option>
                            <option value="Ninguna">Ninguna</option>
                            {limitaciones}
                        </select> */}
                        <Select 
                            options={ limitaciones } 
                            isMulti
                            inputId="limitacion"
                            placeholder="* Elegir limitación permanente"
                            value={ this.props.data.limitacion }
                            defaultValue={ this.props.data.limitacion }
                            onChange={ (selectedOptions) => this.changeHandler({target:{value: selectedOptions, id: 'limitacion'}}) }
                            noOptionsMessage={() => null}
                            className="select-component"
                        />
                        <h4>5. ¿Tenés licencia?</h4>
                        <p>Si no tenés, no hay ningún problema. Podés dejar este espacio vacío</p>

                        {/* <select id="licencia" onChange={this.changeHandler} value={this.props.data.licencia}>
                            <option value="">Tipo de licencia</option>
                            {licencias}
                        </select> */}
                        <Select 
                            options={ licencias } 
                            isMulti
                            inputId="licencia"
                            placeholder="Tipo de licencia"
                            value={ this.props.data.licencia }
                            defaultValue={ this.props.data.licencia }
                            onChange={ (selectedOptions) => this.changeHandler({target:{value: selectedOptions, id: 'licencia'}}) }
                            noOptionsMessage={() => null}
                            className="select-component"
                        />
                        <div className="error carroError"></div>
                        <h4>6. ¿Tenés carro propio?</h4>
                        <div className="flex-labels">
                            <label><input type="radio" id="carro" name="carro" value="1"  onChange={this.changeHandler} checked={ this.props.data.carro === '1'} /> Sí</label>&nbsp;
                            <label><input type="radio" id="carro" name="carro" value="0"  onChange={this.changeHandler} checked={ this.props.data.carro === '0'} /> No</label>
                        </div>
                        
                        
                        <h4>7. ¿Hablás algún otro idioma además del español?</h4>
                        <p>Podés agregar varios idiomas. Si no hablás otro idioma no hay ningún problema, dejá este espacio vacío. </p>
                        
                        <div className="contenedorlanguages">
                        {
                            this.props.data.lenguajes.map((lenguaje,index) => (
                                <RegistroNuevoIdioma 
                                    index={index}
                                    setLanguage={this.languageHandler}
                                    setLanguageLevel={this.languageLevelHandler} 
                                    eliminaIdioma={this.eliminaIdioma}
                                    idiomas_nivel={idiomas_nivel} 
                                    idiomas={idiomas} key={index}
                                    name={this.props.data.lenguajes[index].name ? this.props.data.lenguajes[index].name : ''}
                                    value={this.props.data.lenguajes[index].level ? this.props.data.lenguajes[index].level : ''} 
                                />
                            ))
                        }
                        </div>
                        <a onClick={this.agregarIdioma.bind(this)} href="/idioma/add">+ Agregar idioma</a>
                        <div className="buttons">
                            <button className="btn btn-primary white" onClick={this.backHandler}>ATRÁS</button>
                            <input type="submit" value="CONTINUAR" className="btn btn-primary" />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default RegistroPaso2;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { Link } from 'react-router-dom';
import { fetchProfileData, updateProfile } from '../../store/actions';
import Spinner from '../ui/Spinner';
import Afinidades from '../Puestos/Afinidades';
import RecorridoBlock from '../Recorrido/RecorridoBlock';
import { fetchPuestos } from '../../store/actions';
import "./Perfil.css";

//Images
import ping from '../../assets/images/ping.png';
//import notification from '../../assets/images/notification.png';
import medal from '../../assets/images/medalla-large.png';
import medallaMedium from '../../assets/images/star-filled.svg';
//import spanish from './spanish.png'
//import english from './english.png'
//import german from './german.png'
//import filtro from './../../assets/images/filter.png';


/*const languagesFlags = [
    {
        url: english
    },
    {
        url: german
    },
    {
        url: spanish
    },
    
]*/

class Perfil extends Component {

    state = {
        showProfile: true,
        showAllPath: true,
        showJobsOnly: false,
        showEventsOnly: false,
        showOpportunities: true,
        searchText: ''
    }

    componentDidMount() {
        this.props.onFetchData();
        this.props.onFetchDataJobs();
    }

    hideOpportunities = () => this.setState({ showOpportunities: false })

    updateProfileHandler = () => {
        const { profileData } = this.props;
        if (profileData) {
            this.props.onUpdateProfile({
                young_phone: profileData.user.phone,
                young__phone__family: profileData.user.phone_family,
                young__email: profileData.user.email,
                study: 1,
                academic_degree: 1,
                academic_center_status: true,
                experience: profileData.abilities.map(item => item.id),
                learn: profileData.interests.map(item => item.id),
                courses: [],
                licenses: profileData.licenses.map(item => item.id),
                vehicle: true,
                languages: profileData.languages.map(item => item.language.id),
                languages_level: profileData.languages.map(item => item.level.id),
            });
        }
    }

    showProfile = () => this.setState({ showProfile: true });

    showPath = () => this.setState({ showProfile: false });

    showAllPath = () => this.setState({ showAllPath: true, showJobsOnly: false, showEventsOnly: false });

    showJobs = () => this.setState({ showAllPath: false, showJobsOnly: true, showEventsOnly: false });

    showEvents = () => this.setState({ showAllPath: false, showJobsOnly: false, showEventsOnly: true });

    inputChangedHandler = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    renderMedals = (medals) => {
        return medals.map(medalObject => {
            return (
                <div key={medalObject.id} className="medal-content flex-column">
                    <figure className="medal-image text-center">
                        <img src={medal} />
                    </figure>
                    <p className="medal-title">{medalObject.name}</p>
                </div>
            );
        });
    }

    renderCourses = (courses) => {
        return courses.map((course, i) => {
            return (
                <li key={i}>Curso libre de: {course}</li>
            );
        });
    }

    renderLanguages = (languages) => {
        return languages.map( (lang, i) => {
            return (
                <div className="flex flex-center justify-start" key={i}>
                    {/* <figure>
                        <img src={languagesFlags[i].url} />
                    </figure> */}
                    <span>{lang.language.language}</span>
                </div>
            );
        });
    }

    renderLicenses = (licenses) => {
        return licenses.map( (license, i) => {
            return (
                <li key={i}>{license.type}</li>
            );
        });
    }

    renderMyJobs = (data) => {
        const { searchText } = this.state;
        
        const filteredData = data.filter(item => {
            const title = item.job? item.job.title : item.title;
            return title.toLowerCase().search(searchText.toLowerCase()) !== -1;
        });
        console.log(filteredData);
        return filteredData.map((item, index) => {
            const caption = item.apply_on ? item.apply_on : item.type.caption;
            const title = item.job? item.job.title : item.title;
            const subtitle = item.job ? item.job.company ? item.job.company.name : item.location : item.location;
            const address = item.job ? `${ item.job.canton.name}, ${item.job.province.name}` : `${ item.canton.name}, ${item.province.name}`;
            const distance = item.job ? item.job.distance : item.distance;
            const image = item.job ? item.job.company ? item.job.company.image : item.image : item.image;
            const isJob = item.job ? true : false;
            return (
                <RecorridoBlock 
                    key={ index }
                    caption={caption}
                    imgPrincipal={image}
                    imgPin={ping} 
                    puesto={title}
                    empresa={ subtitle }
                    direccion={address}
                    distancia={distance}
                    isJob={isJob}>
                </RecorridoBlock>
            );
        })
    }

    

    render() {
        const { profileData, applies, events  } = this.props

        // const jobsData = this.state.showAllPath || this.state.showJobsOnly ? this.props.applies : null;
        let myJourneyData = [];

        if (this.state.showAllPath) {
            myJourneyData = [
                ...applies,
                ...events
            ];
        } else if (this.state.showEventsOnly) {
            myJourneyData = events;
        } else {
            myJourneyData = applies;
        }

        let content = null;
        if (this.props.isLoading || !this.props.profileData) { 
            content = <Spinner />
        } 
        else {
            const opportunity = this.props.opportunities.length ? this.props.opportunities[0] : null;
            // const opportunity = {};

            content = (
                <div className="profile-general-information">
                    <div className="edit-profile-button">
                        <Link to={`/perfil/editar`}><i className="fa fa-pencil" aria-hidden="true"></i>Editar perfil</Link>
                    </div>
                    <figure style={ {backgroundImage: `url(${profileData.image})`} }></figure>
                    <div className="text-center">
                        <h3>{profileData.user.name}</h3>
                        <div className="profile-user-state">
                            <span>Estado: </span>
                            <select>
                                <option>Buscando oportunidades</option>
                            </select>
                        </div>
                        <div className="flex justify-start">
                            <p className="location"><i><img src={ping} alt="Pin"/></i><span>{`${ profileData.canton.name}, ${profileData.province.name}`}</span></p>
                        </div>
                    </div>
                    <div className="listadoFiltros">
                        <div 
                            className={`filtroTab ${ this.state.showProfile && 'active'}`} 
                            onClick={ this.showProfile }
                        >
                            Mi perfil
                        </div>
                        <div 
                            className={`filtroTab ${ !this.state.showProfile && 'active'}`} 
                            onClick={ this.showPath }
                        >
                            Mi recorrido
                        </div>
                    </div>
                    <div className={this.state.showProfile ? 'container profile-container active' : 'container profile-container'}>
                        {/* <div className="card profile-card">
                            <h5>Promotor</h5>
                            <div className="flex flex-center justify-start profile-card-content">
                                <figure className="card-portrait" style={ {backgroundImage: `url(https://static.makeuseof.com/wp-content/uploads/2015/11/perfect-profile-picture-all-about-face.jpg)`} }></figure>
                                <div className="user-info">
                                    <p>Adriana Marín</p>
                                    <p>Asignada hace: 3 meses</p>
                                </div>
                                <figure>
                                    <img src={notification} />
                                </figure>
                            </div>
                        </div> */}
                        <div className="card profile-card">
                            <h5>Medallas<span>{profileData.medals.length}</span></h5>
                            <div className="flex flex-center profile-card-content nowrap justify-start">
                                {this.renderMedals(profileData.medals)}
                            </div>
                            <div className="see-more-button">
                                <Link to={`/perfil/medallas`}>Ver más</Link>
                            </div>
                        </div>
                        { (this.state.showOpportunities && opportunity) && (
                            <>
                                <div className="mensajeBlue">
                                    Oportunidad.
                                    <span onClick={this.hideOpportunities }>x</span>
                                </div>
                                <div className="card profile-card bullying-card">
                                    <h5>{ opportunity.title }</h5>
                                    <div className="profile-card-content">
                                        <p>{ opportunity.description }</p>
                                        <div className="medallaTitle flex justify-start flex-center">
                                            <img src={medallaMedium} alt="Medalla" />
                                            <p>Importante: </p>
                                        </div>
                                        <p className="medallaContent">Si logras asistir a este taller, aumentarás tus posibilidades de obtener 10 puestos.</p>
                                    </div>
                                    <div className="see-more-button see-detail-event">
                                        <Link to={`/eventos/${opportunity.id}`}>Ver detalles</Link>
                                    </div>
                                </div>
                            </>
                        )}
                        
                        <div className="card profile-card">
                            <h5>¿Qué habilidades tenés?</h5>
                            <Afinidades affinities={profileData.abilities}></Afinidades>
                        </div>
                        <div className="card profile-card">
                            <h5>¿Qué querés aprender?</h5>
                            <Afinidades affinities={profileData.interests}></Afinidades>
                        </div>
                        <div className="card profile-card list-card vertical-list-card">
                            <h5>Experiencia laboral</h5>
                            { profileData.experiences && (
                                <ul>
                                    { profileData.experiences.map((item, index) => (
                                        <li key={index}>{item.position} en { item.company } ({ item.years } años)</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="card profile-card list-card vertical-list-card">
                            <h5>Educación y cursos</h5>
                            <ul>
                                <li>Estudia actualmente: {profileData.study_in_academic_center ? 'Sí' : 'No'}</li>
                                <li>
                                    {profileData.last_grade_coursed && (<p>Último nivel cursado: {profileData.last_grade_coursed.name}</p> )}
                                    <p>- {profileData.career_name}</p>
                                    <p>- {profileData.academic_center_name}</p>
                                </li>
                                {this.renderCourses(profileData.free_courses)}  
                            </ul>
                        </div>
                        <div className="card profile-card languages-card">
                            <h5>Idiomas</h5>
                            <div className="flex flex-center justify-start">
                                {this.renderLanguages(profileData.languages)}
                            </div>
                        </div>
                        <div className="card profile-card languages-card">
                            <h5>Transporte</h5>
                            <p>Licencias: </p>
                            <div className="profile-card list-card horizontal-list-card">
                                <ul className="flex flex-center justify-start">
                                    {this.renderLicenses(profileData.licenses)}
                                </ul>
                            </div>
                            <p>Vehículo propio: </p>
                            <div className="profile-card list-card horizontal-list-card">
                                <ul className="flex flex-center justify-start">
                                    <li>{profileData.own_car ? 'Sí' : 'No'}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={ !this.state.showProfile ? "container path-container active" : "container path-container" }>
                        <div className="searchControl path-search">
                            <input 
                                type="text" 
                                placeholder="Buscar puesto" 
                                className="filterBox" 
                                id="searchText"
                                value={ this.state.searchText }
                                onChange={ this.inputChangedHandler }
                            />
                            {/* <figure>
                                <img src={filtro} />
                            </figure> */}
                        </div>
                        <div className="path-buttons-container flex flex-center">
                            <button className={ this.state.showAllPath ? "active" : ''} onClick={ this.showAllPath } >Todo</button>
                            <button className={ this.state.showJobsOnly ? "active" : ''} onClick={ this.showJobs }>Puestos</button> 
                            <button className={ this.state.showEventsOnly ? "active" : ''} onClick={ this.showEvents }>Eventos</button>         
                        </div>
                        <div className="jobs-events-container">
                             {this.renderMyJobs(myJourneyData)}
                        </div>
                        <div></div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <TopTools />
                { content }
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profileData: state.profile.profileData && state.profile.profileData.young,
        isLoading: state.ui.isLoading,
        events: state.profile.profileData ? state.profile.profileData.my_road.events : [],
        applies: state.profile.profileData ? state.profile.profileData.my_road.applies : [],
        opportunities: state.puestos.opportunities
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchProfileData()),
        onFetchDataJobs: () => dispatch(fetchPuestos()),
        onUpdateProfile: data => dispatch(updateProfile(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Perfil);
import { put, select } from 'redux-saga/effects';
import axios from '../axios';
import * as actions from '../actions';
import * as selectors from './selectors';

export function* fetchPuestosSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        // axios.defaults.headers.common['Authorization'] = 'Bearer '+ accessToken;
        // console.log(axios)
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/puestos', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setPuestos(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* applyFiltersSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/puestos', action.filters ,{headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setPuestos(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* fetchPuestoSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/puesto/' + action.id, {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setPuesto(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* applyPuestoSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/puesto/' + action.id, {}, {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.puestoAppliedSuccess(response.data.job));
            yield put(actions.setPuesto(response.data));

            yield put(actions.uiShowModal(
                'Aplicaste con éxito al puesto', 
                'La empresa procederá a revisar tu perfil. Recordá que los puestos a los que aplicás quedan guardados en la sección Puestos aplicados.', 
                1, 
                'Cerrar', 
                '', 
                action.callback, 
                null
            ));

            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* reportPuestoSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        const data = {
            justification: action.justification
        }
        try {
            const response = yield axios.post('/api/joven/puesto/reportar/' + action.id, data, {headers:{'Authorization': 'Bearer ' + accessToken}});

            yield put(actions.uiShowModal(
                'Listo', 
                'Se ha reportado el puesto exitosamente. El puesto será evaluado por los encargados de la plataforma.', 
                1, 
                'Cerrar', 
                '', 
                action.callback, 
                null
            ));

            yield put(actions.puestoReportedSuccess(action.id));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* sendRatingSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/puesto/confirmar-contratacion', action.data, {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.uiStopLoading());
            yield put(actions.fetchPuestos());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}
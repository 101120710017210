import { put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from '../axios';
import * as actions from '../actions';
import * as selectors from './selectors';
import config from '../../config.json';

export function* fetchRegistrationDataSaga(action) {
    const registrationData = yield select(selectors.registrationData);
    if (!registrationData) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/registro-api');
            yield put(actions.setRegistrationData(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* checkRegistrationDataSaga(action) {
    yield put(actions.uiStartLoading());
    try {
        const response = yield axios.post('/comprobar-datos', {identification: action.id, email: action.email});
        if (response.data.identification && response.data.email) {
            yield put(actions.registrationUniqueDataSuccess());
        } 
        if(!response.data.identification && response.data.email){
            yield put(actions.authFailed('El número de identificación ya existe en el sistema'));
        }
        if(response.data.identification && !response.data.email){
            yield put(actions.authFailed('El correo electrónico ya existe en el sistema'));
        }
        if(!response.data.identification && !response.data.email){
            yield put(actions.authFailed('El número de identificación y el correo electrónico ya existen en el sistema'));
        }
        yield put(actions.uiStopLoading());
    } catch(error) {
        // console.log(error);
        // console.log(error.response.data.error);
        yield put(actions.authFailed("Se produjo un error inesperado."));
        yield put(actions.uiStopLoading());
    }
}

export function* signupSaga(action) {
    yield put(actions.uiStartLoading());
    try {
        const response = yield axios.post('/registro-api', action.data);
        if (response.data.success) {
            if (action.image) {
                const imageResponse = yield axios.post('/perfil/editar-foto', action.image ,{headers:{'Content-Type': 'multipart/form-data'}});
            }
            yield put(actions.signupSuccess());
        } else {
            yield put(actions.authFailed(response.data.message));
        }
        yield put(actions.uiStopLoading());
    } catch(error) {
        // console.log(error);
        // console.log(error.response.data.error);
        yield put(actions.authFailed("Se produjo un error inesperado."));
        yield put(actions.uiStopLoading());
    }
}

export function* loginSaga(action) {
    yield put(actions.uiStartLoading());
    const info = {
        'grant_type': config.endpoint.grant_type,
        'client_id': config.endpoint.client_id,
        'client_secret': config.endpoint.client_secret,
        'username': action.emailOrId,
        'password': action.password
    }
    try {
        const response = yield axios.post('/oauth/v2/token', info);
        yield put(actions.loginSuccess(response.data));
        yield put(actions.watchExpirationTime());
        yield put(actions.uiStopLoading());
    } catch(error) {
        // console.log(error);
        // console.log(error.response.data.error);
        yield put(actions.authFailed("Usuario o contraseña invalidos."));
        yield put(actions.uiStopLoading());
    }
}

export function* watchExpirationTimeSaga(action) {
    let expirationTime, expiresIn;

    while (true) {
        yield delay(1000);
        expirationTime = yield select(selectors.expirationTime);
        expiresIn = expirationTime - new Date().getTime() - 1000;
        // expiresIn = 3000;
        // console.log(expiresIn)
        if (expiresIn > 0) {
            yield delay(expiresIn)
        }
        yield put(actions.refreshToken());
    }
}

export function* refreshTokenSaga(action) {
    const refreshToken = yield select(selectors.refreshToken);
    if (refreshToken) {
        yield put(actions.uiStartLoading());
        const info = {
            'grant_type': 'refresh_token',
            'client_id': config.endpoint.client_id,
            'client_secret': config.endpoint.client_secret,
            'refresh_token': refreshToken,
        }
        try {
            const response = yield axios.post('/oauth/v2/token', info);
            yield put(actions.loginSuccess(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            // console.log(error.response.data.error);
            yield put(actions.authFailed("Token inválido."));
            yield put(actions.logout());
            yield put(actions.uiStopLoading());
            window.location.replace("/user/login");
        }
    }
}

export function* sendForgotPasswordSaga(action) {
    yield put(actions.uiStartLoading());
    try {
        const response = yield axios.post('/olvido-contrasena', { email: action.email });
        yield put(actions.uiStopLoading());
        if (action.callback) {
            action.callback();
        }
    } catch(error) {
        // console.log(error);
        // console.log(error.response.data.error);
        yield put(actions.uiStopLoading());
    }
}

import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import imgSrc from '../../assets/images/ic_chevron_left.svg';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 2.5px;
    background: #F9F9F9;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.2);
    position: fixed;
    width: 100%;
    z-index: 10;
`;

const Text = styled.p`
    color: #3F3F3F;
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
    margin: 0;
    margin-left: 10px;
`;

const backButton = props => {
    return (
        <Container onClick={() => props.history.goBack() }>
            <img className="back-image" src={ imgSrc } alt="<" />
            <Text>Atrás</Text>
        </Container>
    );
};

export default withRouter(backButton);
import React, { Component, Fragment } from 'react';

class Cursos extends Component {

    inputChangedHandler = event => {
        const updatedCourses = this.props.data.map((item, index) => {
            if (index.toString() === event.target.id) {
                return event.target.value
            }
            return item;
        });

        this.props.onChange(updatedCourses);
    }

    addNewCourse = () => {
        if(this.props.data[this.props.data.length - 1] == '') {
            return;
        }
        this.props.onChange([
            ...this.props.data,
            ''
        ]);
    }

    deleteCourse = e => {
        this.props.data.splice(e.target.id,1);
        this.props.onChange([
            ...this.props.data
        ]);
    }

    render() {
        return (
            <Fragment>
                { this.props.data.map((item, index) => (
                    <Fragment key={ index }>
                        <input 
                            type="text" 
                            id={ index }
                            name="curso_especial" 
                            placeholder="Nombre del curso" 
                            value={ item } 
                            onChange={ this.inputChangedHandler } 
                        />
                        <a className="remove-lang-button" onClick={this.deleteCourse} id={index} href="#/eliminar">- Eliminar curso</a>
                        <hr></hr>
                    </Fragment>
                ))}
                <a className="add-language-button" href="#agregar_curso" onClick={ this.addNewCourse }>+ Agregar curso</a>
            </Fragment>
        );
    }
}

export default Cursos;
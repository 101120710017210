import axios from "axios";

const instance = axios.create({
  baseURL: "https://oferentes.accionjoven.org",
  //baseURL: "http://localhost:8000",
  // headers: {
  //     'Content-Type': 'multipart/form-data'
  // }
});

// instance.interceptors.request.use(config => config, error => {
//     console.log(error)
//     return Promise.reject(error);
// })

instance.interceptors.response.use(
  (config) => config,
  (error) => {
    if (!error.response) {
      alert("Error de conexión");
    }
    return Promise.reject(error);
  }
);

export default instance;

import React from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import AfinidadIndicador from './AfinidadIndicador';
import Afinidades from '../Puestos/Afinidades';
import { BackButton, Spinner } from '../ui';
import moment from 'moment';
import 'moment/locale/es';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import "./Eventos.css";
import "../Puestos/Puestos.css";

// Images
import BottomTools from '../Internal/BottomTools';
import imgEventDetalle from '../../assets/images/evento-detalle.png';
import ping from '../../assets/images/ping.png';
import medallaMedium from '../../assets/images/medalla-medium.png';
import iconInfo from '../../assets/images/icon-info.png';
import iconEdad from '../../assets/images/icon-edad.png';
import pinNegro from '../../assets/images/pin-negro.png';
import iconBeneficio from '../../assets/images/icon-beneficios.png';
import iconTiempo from '../../assets/images/icon-time.png';
import helpIcon from '../../assets/images/icon-ayuda.png';
import { fetchEvent, toggleEventAssistence, sendEventCode } from '../../store/actions/events';
import { Redirect } from 'react-router-dom';

let detailImage = {
    backgroundImage: 'url(' + imgEventDetalle + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
}


class EventoDetalle extends React.Component{
    
    state = {
        code: ''
    }

    componentDidMount() {
        this.props.onFetchData(this.props.match.params.id);
    }


    changeHandler = event => {
        const name = event.target.id;
        const value = event.target.value;

        this.setState({
            [name]: value
        })
    }

    toggleEventAssistenceHandler = () => {
        this.props.onToggleEventAssistence(
            this.props.currentEvent.id,
            () => this.props.history.replace('/eventos')
        )
    }

    sendEventCodeHandler = () => {
        this.props.onSendEventCode(
            this.props.currentEvent.id,
            this.state.code,
            () => this.props.history.replace('/perfil')
        );
    }
    
    render() {
        const { currentEvent, hasEvent, isEventDay, hasCode } = this.props;
        let content = null;
        console.log("~~~ Evento ~~~")
        
        console.log(currentEvent);
        if (this.props.isLoading || !this.props.currentEvent) { 
            content = <Spinner />
        } else {
            if (currentEvent.state === 'Suspendido' || currentEvent.state === 'Eliminado') {
                return <Redirect to="/eventos" />;
            }
            content = (
                <div className="container">
                    {/* <div className="nav-control"><a href="#tbd">&lt; Atr&aacute;s</a></div> */}
                    <div className="job-detail flex">
                        <figure
                            style={{
                                
                                backgroundImage: 'url(' + currentEvent.image + ')',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'

                            }}>
                        </figure>
                        <div>
                            <h3>{ currentEvent.title }</h3>
                            <p className="company-name">{ currentEvent.created_by_name }</p>
                            <div className="flex">
                                <p className="location flex nowrap"><i><img src={ping} alt="Pin"/></i><span>{`${ currentEvent.canton.name}, ${currentEvent.province.name}`}</span></p>
                            </div>
                            <p className="distancia">{currentEvent.distance}km de mí</p>
                        </div>
                    </div>
                    <div className="row job-information content">
                        { !hasCode && (
                            <>
                                <div className="medallaTitle flex justify-start flex-center">
                                    <img src={medallaMedium} alt="Medalla" />
                                    <p>Medalla disponible</p>
                                    <Tooltip placement="left" mouseEnterDelay={0.5} trigger={['hover']} overlay={<span>Si asistís a este evento, aprenderás cosas nuevas, conocerás nuevas personas, y se fortalecería tu perfil con la habilidad a ganar. Esto será una medalla que se mostrará en tu perfil y todos podrán verla.</span>}>
                                        <img src={iconInfo} alt="Información" />
                                    </Tooltip>
                                </div>
                                <p className="medallaContent">Obtendrás una medalla de participación por asistir a este taller. Esta medalla se mostará en tu perfil, ¡todos podrán verla!</p>
                            </>
                        ) }
                        <section className="col-md-12 descripcion">
                            <p className="subTitulo">Descripción del evento</p>
                            <p>{ currentEvent.description }</p>
                            {/* <p className="subTitulo">Objetivo principal</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p> */}
                        </section>
                        
                        { currentEvent.main_objective && (
                            <section className="col-md-12 descripcion">
                                <p className="subTitulo">Objetivo principal</p>
                                <p>{ currentEvent.main_objective }</p>
                            </section>
                        ) }

                        <div className="col-md-12">
                            <Tooltip placement="right" mouseEnterDelay={0.5} trigger={['hover']} overlay={<span>La afinidad compara tus intereses con los temas del evento. Los eventos que presentan más círculos llenos, podrían ser los de mayor interés para vos.</span>}>
                                <p className="afinidad">Tu afinidad con el evento: <AfinidadIndicador afinidad={ currentEvent.for_you } /></p>
                            </Tooltip>
                            <Afinidades affinities={currentEvent.affinities}></Afinidades>
                        </div>
                        { currentEvent.age_range !== '' && (
                            <section className="col-md-12 edad">
                                <p className="subTitulo">Rango de edad recomendado para asistir</p>
                                <p><img src={iconEdad} alt="Edad" /> { currentEvent.age_range }</p>
                            </section>
                        )}
                        <div className="col-md-12">
                            <p className="subTitulo">Afinidades</p>
                            <Afinidades affinities={ [currentEvent.principal_affinity]}></Afinidades>
                        </div>
                        <section className="col-md-12 detalles">
                            <p className="subTitulo">Detalles</p>
                            <div className="flex nowrap justify-start">
                                <img src={pinNegro} alt="Pin" />
                                <div>
                                    {/* <p className="subTitulo">Alimentos S.A. Oficinas centrales</p> */}
                                    <p>{`${ currentEvent.district.name}, ${currentEvent.canton.name}, ${currentEvent.location}`}</p>
                                    {/* <p>Tibás, San José. De la esquina de la comisaría, 25m Norte y 50 Este, frente a la panadería Mis Panes.</p> */}
                                </div>
                            </div>
                            <div className="flex justify-start flex-center dates-event-container">
                                <img src={iconTiempo} alt="Tiempo" />
                                {
                                   currentEvent.event_dates.map( date => (
                                    <p>
                                        { moment.utc(new Date(date.date)).format('dddd, D [de] MMMM [del] YYYY') }
                                        <br />
                                        { moment.utc(new Date(date.start)).format('hh:mm a') } - { moment.utc(new Date(date.end)).format('hh:mm a') }
                                    </p>
                                   )) 
                                }
                            </div>
                            {
                                currentEvent.detail && (
                                    <div className="flex nowrap justify-start flex-center">
                                        <img src={iconBeneficio} alt="Beneficios" />
                                        <p>{ currentEvent.detail }</p>
                                    </div>
                                )
                            }
                        </section>
                    </div>
                    <div className="asistirEvento row">
                        { (isEventDay && hasEvent) ? !hasCode && (
                             <div className="day-event-container flex flex-column justify-center flex-center">
                                <input 
                                    placeholder="Ingrese el código aquí" 
                                    id="code"
                                    value={ this.state.code }
                                    onChange={ this.changeHandler }
                                    type="number"
                                />
                                <button onClick={ this.sendEventCodeHandler }>Enviar código</button>
                            </div>
                        ) : (
                            <div className="job-actions flex flex-column justify-center flex-center day-event-change">
                                {/* <button onClick={this.openReportJobModal}><span>Reportar evento</span> <img src={helpIcon}/></button> */}
                                { !hasEvent && <p>¿Querés ganar esta medalla?</p> }
                                <button onClick={ this.toggleEventAssistenceHandler }>{ hasEvent ? 'CANCELAR PARTICIPACIÓN' : 'ASISTIR AL EVENTO' }</button>
                            </div>
                        )}
                    </div>
                </div>
            )
        }
        return (
            <div className="eventos detalle">
                <TopTools></TopTools>
                <BackButton />
                { content }
                <BottomTools></BottomTools>
            </div>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        currentEvent: state.events.currentEvent && state.events.currentEvent.event,
        hasEvent: state.events.currentEvent ? state.events.currentEvent.hasEvent : false,
        isEventDay: state.events.currentEvent ? state.events.currentEvent.is_eventDay : false,
        hasCode: state.events.currentEvent ? state.events.currentEvent.hasCode : false,
        // isEventDay: true,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: id => dispatch(fetchEvent(id)),
        onToggleEventAssistence: (id, callback) => dispatch(toggleEventAssistence(id, callback)),
        onSendEventCode: (eventId, code, callback) => dispatch(sendEventCode(eventId, code, callback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventoDetalle);
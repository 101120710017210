import React, { Component } from 'react';
import { connect } from 'react-redux';

import { sendForgotPassword } from '../../store/actions';
import { Spinner } from '../ui';
import imgUsuario from './../../assets/images/usuario.png';
import './OlvidoContrasena.css';

const cssUsuario = {
    backgroundImage: 'url('+ imgUsuario + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '15px',
  }


class OlvidoContrasena extends Component {

    state = {
        email: ''
    }

    submitFormHandler = event => {
        event.preventDefault();
        this.props.onSend(this.state.email, () => {
            alert("Se te ha enviado un correo para reestablecer la contraseña");
            this.props.history.replace('/user/login');
        })
    }

    inputChangedHandler = e => {
        this.setState({
            email: e.target.value
        });
    }

    render() {
        const {goBack} = this.props.history;
        if (this.props.isLoading) {
            return <Spinner />
        }
        return (
            <div className="forgot-container flex flex-center justify-center">
                <h1>Recuperar contraseña</h1>
                <p>Indicanos el correo electrónico que registraste y te enviaremos un link<br></br> de ayuda para restablecer tu contraseña.</p>
                <form onSubmit={ this.submitFormHandler }>
                    <input 
                        type="email" 
                        placeholder="Correo electrónico" 
                        id="email" 
                        value={ this.state.email }
                        onChange={ this.inputChangedHandler }
                        style={cssUsuario}
                    />
                    <div className="forgot-buttons flex flex-center">
                        <button onClick={ () => goBack()}>Cancelar</button>
                        <button>Recuperar</button>
                    </div>
                    
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSend: (email, callback) => dispatch(sendForgotPassword(email, callback))
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(OlvidoContrasena);

import React from 'react';
import './BottomTools.css';
import { NavLink } from 'react-router-dom';
// Images
import icpuestos from './../../assets/images/puestos-icon.png';
import iceventos from './../../assets/images/Libretita.png';
import icayuda from './../../assets/images/Alerta.png';
import icperfil from './../../assets/images/Perfil.png';


export default class BottomTools extends React.Component{
    render () {
        return (
        <div className="bottomTools">
            <NavLink className="nav-puestos" to="/puestos"><img src={icpuestos} alt="Puestos" /><br />Puestos</NavLink>
            <NavLink className="nav-eventos" to="/eventos"><img src={iceventos} alt="Eventos" /><br />Eventos</NavLink>
            <NavLink className="nav-ayuda" to="/ayuda"><img src={icayuda} alt="Ayuda" /><br />Ayuda</NavLink>
            <NavLink className="nav-perfil" to="/perfil"><img src={icperfil} alt="Perfil" /><br />Perfil</NavLink>
        </div>
        );
    }
}
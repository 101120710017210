import React from 'react';
import PropTypes from 'prop-types';
import starSrc from '../../../assets/images/star.svg';
import filledStarSrc from '../../../assets/images/star-filled.svg';
import Centered from '../CenteredContainer';
import classes from './Stars.module.css';

const stars = props => {
    const elements = [];
    for (let i = 1; i <= 5; i++) {
        let imageSrc = props.value >= i ? filledStarSrc : starSrc;
        elements.push(<img key={i} src={ imageSrc } alt="estrella" onClick={() => props.onChange(i) } />);
    }
    return (
        <Centered>
            <div className={ classes.container }>
                { elements }
            </div>
        </Centered>
    );
};

stars.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default stars;
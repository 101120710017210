import React from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import PuestoBlock from '../Puestos/PuestoBlock';
import { Link } from 'react-router-dom';
import { fetchPuestos, applyFilters, setPuesto, sendRating } from '../../store/actions';

import "./Puestos.css";

// Images
import clock from './../../assets/images/clock.png';
import filtro from './../../assets/images/filter.png';
import BottomTools from '../Internal/BottomTools';
import ping from '../../assets/images/ping.png';
import Filtros from './Filtros';
import Spinner from '../ui/Spinner';
import RateModal from '../Modal/RateModal';

class Puestos extends React.Component{

    state = {
        showFilter: false,
        searchText: '',
        showApplied: false,
    }

    componentDidMount(){
        if (!this.props.jobs.length) {
            this.props.onFetchData()
        }
    }

    inputChangedHandler = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    toggleFilter = () => {
        this.setState(prevState => {
            return {
                showFilter: !prevState.showFilter
            }
        })
    }

    showAppliedJobs = () => this.setState({ showApplied: true });

    showAvailableJobs = () => this.setState({ showApplied: false });

    closeMensajeBlue(evt){
        evt.preventDefault();
        evt.target.parentNode.style.display = 'none';
    }

    searchHandler = () => {
        this.props.onApplyFilters({
            name: this.state.searchText
        });
    }

    render() {
        const data = this.state.showApplied ? this.props.applies : this.props.jobs;
        console.log(data);
        const jobs = data.map(item => (
            <PuestoBlock 
                key={ item.id }
                id={ item.id }
                imgPrincipal={item.image} 
                imgPin={ping}
                puesto={ item.title }
                empresa={ item.created_by_name }
                direccion={`${ item.canton.name}, ${item.province.name}`}
                afinidad={ item.for_you }
                // onOpenDetail={() => this.props.onSetCurrentJob(item)}
            />
        ))

        const noJobsMessage = !this.state.showApplied && this.props.jobs.length == 0 ? 
        <p className="no-jobs">No se encontraron resultados para la búsqueda realizada. Aplicá una nueva combinación de filtros para ver resultados.</p> : this.state.showApplied && this.props.applies.length == 0 ? <p className="no-jobs">No has aplicado a ningún puesto.</p> : null;

        const description = this.state.showApplied ? 
            (
                <p className="legend">Puestos a los que ya aplicaste. Podés ver si un puesto sigue abierto o si ya cerró (podés eliminarlos de la lista).</p>
            ) : (
                <p className="legend">
                    Puestos en la zona. Podés aplicar a varios al mismo tiempo. 
                    En <strong>Puestos aplicados</strong> verás los puestos donde ya aplicaste. 
                </p>
            )
        

        const ratingModals = this.props.continueWorking.map(item => (
            <RateModal 
                key={ item.id }
                text={ item.texto}
                id={ item.id }
                onSendData={ this.props.onSendRating }
            />  
        ))
            
        return (
            <div className="puestos">
                <TopTools></TopTools>

                { this.state.showFilter ? 
                    <Filtros
                        filters={ this.props.filters }
                        onClose={ this.toggleFilter }
                        data={ this.props.selectedFilters }
                        onFilter={ this.props.onApplyFilters }
                    /> 
                : null }

                { ratingModals }
                
                <div className="header">
                    <h1>Puestos disponibles</h1>
                    { this.props.opportunities.length ? (
                        <Link to="/puestos/oportunidades" className="mensajeBlue">
                            <img src={clock} alt="clock" /> 
                            Averigua cómo aumentar tus oportunidades.
                            {/* <span onClick={this.closeMensajeBlue}>x</span> */}
                            <i onClick={ this.closeMensajeBlue } className="fa fa-close" aria-hidden="true"></i>
                        </Link>
                    ) : null}
                </div>
                <div className="searchControl">
                    <input 
                        type="text" 
                        placeholder="Buscar puesto" 
                        className="filterBox" 
                        id="searchText"
                        value={ this.state.searchText }
                        onChange={ this.inputChangedHandler }
                        //onBlur={ this.searchHandler }
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                this.searchHandler();
                            }
                        }}
                    />
                    <div className="filtroIcon" onClick={ this.toggleFilter }><img src={filtro} alt="Filtro" /></div>
                </div>

                <div className="listadoFiltros">
                    <div 
                        className={`filtroTab ${ !this.state.showApplied && 'active'}`} 
                        onClick={ this.showAvailableJobs }
                    >
                        Buscar Puestos
                    </div>
                    <div 
                        className={`filtroTab ${ this.state.showApplied && 'active'}`} 
                        onClick={ this.showAppliedJobs }
                    >
                        Puestos Aplicados
                    </div>
                </div>
                    { description}
                    { noJobsMessage }
                    { jobs }
                    { this.props.isLoading && <Spinner /> }
                <BottomTools></BottomTools>
            </div>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        jobs: state.puestos.jobs,
        applies: state.puestos.applies,
        opportunities: state.puestos.opportunities,
        continueWorking: state.puestos.continueWorking,
        filters: state.puestos.filters,
        selectedFilters: state.puestos.data,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchPuestos()),
        onApplyFilters: filters => dispatch(applyFilters(filters)),
        onSetCurrentJob: job => dispatch(setPuesto(job)),
        onSendRating: data => dispatch(sendRating(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Puestos);
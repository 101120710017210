import * as actionTypes from './actionTypes';

export const fetchCantons = provinceId => {
    return {
        type: actionTypes.FETCH_CANTONS,
        provinceId
    };
};

export const fetchDistrics = cantonId => {
    return {
        type: actionTypes.FETCH_DISTRICTS,
        cantonId
    };
};

export const setCantons = cantons => {
    return {
        type: actionTypes.SET_CANTONS,
        cantons
    };
};

export const setDistrics = districts => {
    return {
        type: actionTypes.SET_DISTRICTS,
        districts
    };
};

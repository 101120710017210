import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './Oportunidades.module.css';
import moment from 'moment';
import 'moment/locale/es';


const oportunidad = ({ data }) => {
    return (
        <div className={ classes.box }>
            <div className={ classes.content }>
                <img src={ data.image } alt="portada" />
                <div>
                    <h2>{ data.title }</h2>
                    <p><span className={ classes.location }/>{ data.location }</p>
                    <span>
                        { moment(new Date(data.event_dates[0].date)).format('DD [de] MMMM YYYY')}
                    </span>
                </div>
            </div>
            <Link to={`/eventos/${data.id}`}>Ver detalles</Link>
        </div>
    );
};

oportunidad.propTypes = {
    data: PropTypes.object.isRequired
};

export default oportunidad;
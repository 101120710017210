import React from 'react';
import "./Registro.css";

export default class RegistroNuevoIdioma extends React.Component{
    render() {
        return (
            <div className="registroNuevoIdioma">
                <div className={ "error lenguajeError" + this.props.index}></div>
                <p className="form-subtitle">Idioma {this.props.index + 1}</p>
                <select className="ctlLenguaje" id={ "lenguaje" + this.props.index} onChange={this.props.setLanguage.bind(this, this.props.index)} value={this.props.name}>
                    <option value="">Elegir idioma</option>
                    {this.props.idiomas}
                </select>
                <div className={ "error lenguajeNivelError" + this.props.index}></div>
                <p>¿Cuál es tu nivel de manejo del idioma?</p>
                <select className="ctlLenguajeNivel" id={"nivel" + this.props.index} onChange={this.props.setLanguageLevel.bind(this, this.props.index)} value={this.props.value}>
                    <option value="">Elegir</option>
                    {this.props.idiomas_nivel}
                </select>
                <a onClick={this.props.eliminaIdioma} id={this.props.index} href="#/eliminar">- Eliminar idioma</a>
                <hr></hr>
            </div>
        );
    }
}
import React from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { BackButton, Spinner } from '../ui';
import AfinidadIndicador from './AfinidadIndicador';
import Afinidades from './Afinidades';
import { fetchPuesto, setPuesto, uiShowModal, uiHideModal, reportPuesto, applyToPuesto } from '../../store/actions';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import "./Puestos.css";

//Images
import clock from './../../assets/images/clock.png';
import ping from '../../assets/images/ping.png';
//import check from '../../assets/images/check.png';
import blackClock from '../../assets/images/clock-black.svg';
import blackDollar from '../../assets/images/dollar-black.svg';
import helpIcon from '../../assets/images/icon-ayuda.png';


class PuestoDetalle extends React.Component{

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onFetchData(this.props.match.params.id);
    }

    openApplyJobModal = () => {
        this.props.onApplyJob(
            this.props.currentJob.id,
            () => this.props.history.replace('/puestos')
        )
    }

    closeMensajeBlue(evt){
        evt.preventDefault();
        evt.target.parentNode.style.display = 'none';
    }

    openReportJobModal = () => {
        this.props.onShowModal(
            'Reportar puesto', 
            'Si creés que las condiciones del puesto no son las mejores podés indicarnos por qué y reportar el puesto.', 
            2, 
            'Reportar', 
            'Entendido', 
            () => this.props.history.push('/puestos/reportar/' + this.props.currentJob.id),
            null, //null also closes the modal
        );
    }

    renderRequirements = (job) => {
        return job.job_requirements.map((requirement, index) => {
            return (
              <p className="requirement" key={ index }>- {requirement.title}</p>
            );
        });
    }
    
    render() {
        const { currentJob, hasApplied } = this.props;
        console.log("**** PuestoDetalle - render()");
        console.log(currentJob);
        let content = null;
        if (this.props.isLoading || !this.props.currentJob) {
            content = <Spinner />
        } else {
            content = (
                <div className="container">
                    <div className="job-detail flex">
                        <figure
                            style={{
                                backgroundImage: `url(${ currentJob.image })`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }}>
                        </figure>
                        <div>
                            <h3>{ currentJob.title }</h3>
                            {<p className="company-name">{ currentJob.created_by_name }</p>}
                            <div className="flex">
                                <p className="location flex nowrap"><i><img src={ping} alt="Pin"/></i><span>{currentJob.location}</span></p>
                            </div>
                            <p className="distancia">{currentJob.distance}km de mí</p>
                        </div>
                    </div>
                    <div style={hasApplied ? {display: 'none'} : null} className="mensajeBlue large-message">
                        <img src={clock} alt="clock" /> 
                        <span>Si aplicas a esta oferta, serás uno de los primeros 10 en aplicar. ¡Aprovecha!</span>
                        <span onClick={this.closeMensajeBlue}>x</span>
                    </div>
                    <div className="row job-information">
                        <div className="col-md-12">
                            {/*<div className="job-status">
                                <p className="flex flex-center justify-start">Estado: <span>Disponible</span><img src={check} alt="check" /></p>
                            </div>*/}
                                <p className="afinidad">Tu afinidad con el puesto: <AfinidadIndicador afinidad={ currentJob.for_you } /></p> 
                            <Afinidades affinities={ currentJob.affinities }></Afinidades>
                        </div>
                        <div className="col-md-12 job-status">
                            <p>Contrato: { currentJob.duration } { currentJob.duration === "Tiempo específico" ? `(${currentJob.undefined_time})` : ''}</p>
                            <p>Descripción del puesto</p>
                            <p>{ currentJob.description }</p>
                        </div>
                        <section className="col-md-12 job-info-icons flex flex-center justify-center">
                            { currentJob.job_schedules.length && (
                                 <div className="text-center info-with-border">
                                        <figure >
                                            <Tooltip placement="left" mouseEnterDelay={0.5} trigger={['hover']} overlay={<span>Horario</span>}>
                                                <img src={blackClock } alt="horario" />
                                            </Tooltip>
                                        </figure>
                                    <p>Jornada { currentJob.job_type.title }</p>
                                    { currentJob.job_schedules.map(schedule => (
                                        <p key={ schedule.id}>{ schedule.job_day.title }: { schedule.start } - { schedule.end }</p>
                                    ))}
                                </div>
                            )}
                            <div className="text-center">
                                <figure >
                                    <Tooltip placement="left" mouseEnterDelay={0.5} trigger={['hover']} overlay={<span>Salario aproximado</span>}>
                                        <img src={blackDollar} alt="salario" />
                                    </Tooltip>
                                </figure>
                                <p>₡ { currentJob.salary } al mes</p>
                                <p>Salario aproximado</p>
                            </div>
                        </section>
                        <div className="col-md-12 job-status">
                            <p>Requisitos deseables</p>
                            {this.renderRequirements(currentJob)}
                        </div>
                    </div>
                    <div className="row job-actions flex flex-column justify-center flex-center">
                        { !currentJob.reported && (
                            <button className="flex flex-center" onClick={ this.openReportJobModal }><span>Reportar puesto</span> <img alt="" src={helpIcon}/></button>
                        )}
                        <button style={hasApplied ? {opacity: 0.6} : null} onClick={ this.openApplyJobModal } disabled={ hasApplied }>{ hasApplied ? 'Ya aplicaste a este puesto' : 'Aplicar para este puesto' }</button>
                    </div> 
                    <br />
                    <br />
                </div>
            )
        }
        return (
            <div className="detalleContainer">
                <TopTools></TopTools>
                <BackButton />
                { content }
                <BottomTools></BottomTools>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentJob: state.puestos.currentPuesto && state.puestos.currentPuesto.job,
        // hasApplied:  false,
        hasApplied: state.puestos.currentPuesto ? state.puestos.currentPuesto.apply_to_job : false,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: id => dispatch(fetchPuesto(id)),
        onSetCurrentJob: job => dispatch(setPuesto(job)),
        onShowModal: (title, text, variant, firstButtonText, secondButtonText, firstButtonAction, secondButtonAction) => dispatch(uiShowModal(title, text, variant, firstButtonText, secondButtonText, firstButtonAction, secondButtonAction)),
        onHideModal: () => dispatch(uiHideModal()),
        onReportJob: (id, callback) => dispatch(reportPuesto(id, callback)),
        onApplyJob: (id, callback) => dispatch(applyToPuesto(id, callback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PuestoDetalle);
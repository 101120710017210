import React from 'react';
import { connect } from 'react-redux';
import $ from "jquery";
import RegistroPaso1 from "./RegistroPaso1";
import RegistroPaso2 from "./RegistroPaso2";
import RegistroPaso3 from "./RegistroPaso3";
import RegistroPaso4 from "./RegistroPaso4";
import RegistroPaso5 from "./RegistroPaso5";
import RegistroFinal from "./RegistroFinal";
import { signup, signupInit, checkRegistrationData, uiShowModal, uiHideModal } from '../../store/actions';
import Spinner from '../ui/Spinner';
import "./Registro.css";

class Registro extends React.Component{
    
    state = {
        step1: {
            identification: '',
            name: '',
            phone: '',
            phoneFamily: '',
            email: '',
            password: '',
            direccion: '',
            location: '',
            latitude: 9.9325427,
            longitude: -84.0795782,
            district: '',
            canton: '',
            province: '',
        },
        step2: {
            lenguajes: [],
            limitacion: [],
            licencia: [],
            carro: '0'
        },
        step3: {
            seleccionadas: [],
        },
        step4: {
            seleccionados: [],
        },
        step5: {
            study: '0',
            academic_center: '0',
            academic_center_degree: '',
            career_name: '',
            academic_center_name: '',
            courses: [''],
            position_company: [],
            position_position: [],
            position_years: [],
            position_description: []
        },
        step6: "",
        currentStep: 1,
        imageFile: null
    }

    componentDidMount() {
        this.props.onInit();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error && this.props.error) {
            alert(this.props.error);
        }
    }

    obtenerDatos1 = data => {
        this.setState({
            ...this.state,
            step1: {...this.state.step1, ...data}
        });
    }

    obtenerDatos2 = data => {
        this.setState({
            ...this.state,
            step2: {...this.state.step2, ...data}
        });
    }

    obtenerDatos3 = data => {
        this.setState({
            ...this.state,
            step3: {...this.state.step3, ...data}
        })
    }

    obtenerDatos4 = data => {
        this.setState({
            ...this.state,
            step4: {...this.state.step4, ...data}
        })
    }

    obtenerDatos5 = data => {
        this.setState({
            ...this.state,
            step5: {...this.state.step5, ...data}
        })
    }

    obtenerDatosFinales = data => {
        const {carro, licencia, lenguajes, limitacion, ...step2} = this.state.step2;
        const {seleccionadas, ...step3} = this.state.step3;
        const {seleccionados, ...step4} = this.state.step4;
        let imageFormData = null;

        if (this.state.imageFile) {
            imageFormData = new FormData();
            imageFormData.append('young_photo', this.state.imageFile, 'young_photo');
            imageFormData.append('email', this.state.step1.email);
        }

        this.props.onSignup({
            ...this.state.step1,
            ...step2,
            ...step3,
            ...step4,
            ...data
        }, imageFormData);
    }

    verificarDatosUnicos = (id, email) => {
        this.props.checkEmailAndIdentification(id, email)
    }

    goNext = () => {
        const step = this.state.currentStep;
        this.setState({
            currentStep: step + 1,
        }) 
        $('html,body').animate({ scrollTop: 0 }, 'slow');
    }

    goBack = () => {
        const step = this.state.currentStep;
        this.setState({
            currentStep: step - 1
        })
        $('html,body').animate({ scrollTop: 0 }, 'slow');
    }


    selectImageHandler = event => {
        this.setState({ imageFile: event.target.files[0] });
    }

    openModal = (text) => {
        this.props.onShowModal(
            '¡Aviso!',
            text,  
            1, 
            'Entendido', 
            null, //null also closes the modal
        );
    }

    render() {
        if (this.props.isLoading) {
            return <Spinner />
        }
        let content = null;
        // if(this.props.uniqueDataSuccessful && this.state.currentStep == 2){
        //     this.props.onInit();
        // }
        // if(this.props.uniqueDataSuccessful && this.state.currentStep == 1) {
        //     this.goNext();
        // }
        switch(this.state.currentStep) {
            case 1: 
                content = (
                    <RegistroPaso1
                        data={this.state.step1}
                        checkData={this.verificarDatosUnicos}
                        goNext={this.goNext} 
                        enviaDatos={this.obtenerDatos1}
                        provinces={ this.props.registrationData ? this.props.registrationData.provinces : [] }
                        imageFile={ this.state.imageFile }
                        onImageSelected={ this.selectImageHandler }
                        openModal={this.openModal}
                    />
                );
                break;
            case 2: 
                content = (
                    <RegistroPaso2
                        data={this.state.step2} 
                        infoRegistro={this.props.registrationData} 
                        enviaDatos={this.obtenerDatos2} 
                        retornaPaso1={this.goBack}
                        goNext={this.goNext}  
                    />
                );
                break;
            case 3: 
                content = (
                    <RegistroPaso3
                        data={this.state.step3} 
                        sectors={ this.props.registrationData ? this.props.registrationData.sectors : []} 
                        enviaDatos={this.obtenerDatos3} 
                        retornaPaso2={this.goBack}
                        goNext={this.goNext}
                        openModal={this.openModal} 
                    />
                );
                break;
            case 4: 
                content = (
                    <RegistroPaso4
                        data={this.state.step4} 
                        sectors={ this.props.registrationData ? this.props.registrationData.sectors : []} 
                        enviaDatos={this.obtenerDatos4} 
                        retornaPaso3={this.goBack}
                        goNext={this.goNext}
                        openModal={this.openModal}   
                    />
                );
                break;
            case 5: 
                content = (
                    <RegistroPaso5
                        data={this.state.step5}  
                        academicDegrees={ this.props.registrationData ? this.props.registrationData.academic_degrees : []} 
                        careers={ this.props.registrationData ? this.props.registrationData.careers : []} 
                        enviaDatos={this.obtenerDatos5}
                        enviaDatosFinales={this.obtenerDatosFinales} 
                        retornaPaso4={this.goBack}
                        goNext={this.goNext}   
                    />
                );
                break;
            default: 
                    content = <Spinner />;
                    break;
        }
        if (this.props.isSignupSuccessful) {
            content = (
                <RegistroFinal />
            );
        }
        return (
            <div className="registro">
                { content }
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
        isAuth: state.auth.isAuthenticated,
        error: state.auth.error,
        isSignupSuccessful: state.auth.isSignupSuccessful,
        uniqueDataSuccessful: state.auth.uniqueDataSuccessful,
        registrationData: state.auth.registrationData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSignup: (data, image) => dispatch(signup(data, image)),
        onInit: () => dispatch(signupInit()),
        onShowModal: (title, text, variant, firstButtonText, secondButtonText, firstButtonAction, secondButtonAction) => dispatch(uiShowModal(title, text, variant, firstButtonText, secondButtonText, firstButtonAction, secondButtonAction)),
        onHideModal: () => dispatch(uiHideModal()),
        checkEmailAndIdentification: (id, email) => dispatch(checkRegistrationData(id, email))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Registro);
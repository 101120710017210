import React from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import EventoBlock from '../Eventos/EventoBlock';
import moment from 'moment';
import 'moment/locale/es';

import "./Eventos.css";

// Images
import filtro from './../../assets/images/filter.png';
import BottomTools from '../Internal/BottomTools';
import ping from '../../assets/images/ping.png';
import Spinner from '../ui/Spinner';

import Filtros from './Filtros';

import { fetchEvents, applyEventsFilters } from '../../store/actions';


class Eventos extends React.Component {

    state = {
        showFilter: false,
        searchText: '',
        showApplied: false,
        showAlerts: true
    }

    componentDidMount() {
        // if (!this.props.events.length) {
            this.props.onFetchData()
        // }
    }

    inputChangedHandler = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    toggleFilter = () => {
        this.setState(prevState => {
            return {
                showFilter: !prevState.showFilter
            }
        })
    }

    showMyEvents = () => this.setState({ showApplied: true });

    showAvailableEvents = () => this.setState({ showApplied: false });

    closeMensajeBlue = () => this.setState({ showAlerts: false });

    searchHandler = () => {
        this.props.onApplyFilters({
            name: this.state.searchText
        });
    }
    
    render() {
        const data = this.state.showApplied ? this.props.myEvents : this.props.events;
        
        const events = data.map(item => (
            <EventoBlock 
                key={ item.id }
                id={ item.id }
                imgPrincipal={ item.image } 
                imgPin={ping}
                Evento={ item.title }
                empresa={item.created_by_name}
                direccion={`${ item.canton.name}, ${item.province.name}`}
                fecha_evento={ moment(new Date(item.event_dates[0].date)).format('DD [de] MMMM YYYY') }
                afinidad={ item.for_you }
                state={ item.state }
            />
        ));
        
        const alerts = this.props.alerts.map((item, index) => (
            <p key={ index }>- { item }</p>
        ));

        const noEventsMessage = !this.state.showApplied && this.props.events.length == 0 ? 
        <p className="no-jobs">No se encontraron resultados para la búsqueda realizada. Aplicá una nueva combinación de filtros para ver resultados.</p> : this.state.showApplied && this.props.myEvents.length == 0 ? <p className="no-jobs">No has confirmado tu asistencia a ningún evento.</p> : null;

        return (
            <div className="eventos">
                <TopTools></TopTools>

                { this.state.showFilter ? 
                    <Filtros
                        filters={ this.props.filters }
                        onClose={ this.toggleFilter }
                        data={ this.props.selectedFilters }
                        onFilter={ this.props.onApplyFilters }
                    /> 
                : null }

                <div className="header">
                    <h1>Eventos</h1>
                    { (this.state.showAlerts && this.props.alerts.length) ? (
                        <div className="mensajeBlue">
                            <div>
                                { alerts }
                            </div>
                            <i onClick={ this.closeMensajeBlue } className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    ) : null }
                </div>
                <div className="searchControl">
                    <input 
                        type="text" 
                        placeholder="Buscar evento" 
                        className="filterBox" 
                        id="searchText"
                        value={ this.state.searchText }
                        onChange={ this.inputChangedHandler }
                        //onBlur={ this.searchHandler }
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                this.searchHandler();
                            }
                        }}
                    />

                    <div className="filtroIcon" onClick={ this.toggleFilter }><img src={filtro} alt="Filtro" /></div>
                </div>

                {/* <div className="listadoFiltros">
                    <a href="/puestos/sin_aplicar" className="filtroSinAplicar active">Todos los eventos</a>
                    <a href="/puestos/aplicados" className="filtroAplicados">Mis Eventos</a>
                </div> */}
                <div className="listadoFiltros">
                    <div 
                        className={`filtroTab ${ !this.state.showApplied && 'active'}`} 
                        onClick={ this.showAvailableEvents }
                    >
                        Todos los eventos
                    </div>
                    <div 
                        className={`filtroTab ${ this.state.showApplied && 'active'}`} 
                        onClick={ this.showMyEvents }
                    >
                        Mis Eventos
                    </div>
                </div>
                { noEventsMessage }
                { events }
                { this.props.isLoading && <Spinner /> }
                
                <BottomTools></BottomTools>
            </div>
            
        );
    }
}


const mapStateToProps = state => {
    return {
        events: state.events.events,
        myEvents: state.events.myEvents,
        filters: state.events.filters,
        alerts: state.events.alerts,
        selectedFilters: state.events.data,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchEvents()),
        onApplyFilters: filters => dispatch(applyEventsFilters(filters)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Eventos);
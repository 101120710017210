import * as actionTypes from '../actions/actionTypes';

const initialState = {
    conversations: [],
    messages: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CONVERSATIONS: return { ...state, conversations: action.conversations };
        case actionTypes.SET_MESSAGES: return { ...state, messages: action.messages };
        default: return state;
    }
}

export default reducer;

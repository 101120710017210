export const SET_REGISTRATION_DATA = 'SET_REGISTRATION_DATA';

export const SIGNUP_INIT = 'SIGNUP_INIT';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD';
export const CHECK_REGISTRATION_DATA = 'CHECK_REGISTRATION_DATA';
export const REGISTRATION_DATA_SUCCESS = 'REGISTRATION_DATA_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const WATCH_EXPIRATION_TIME = 'WATCH_EXPIRATION_TIME';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
// export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';

export const FETCH_PROFILE_DATA = 'FETCH_PROFILE_DATA';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const FETCH_EDIT_PROFILE_DATA = 'FETCH_EDIT_PROFILE_DATA';
export const SET_EDIT_PROFILE_DATA = 'SET_EDIT_PROFILE_DATA';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';
export const UI_SHOW_MODAL = 'UI_SHOW_MODAL';
export const UI_HIDE_MODAL = 'UI_HIDE_MODAL';

export const FETCH_CANTONS = 'FETCH_CANTONS';
export const SET_CANTONS = 'SET_CANTONS';

export const FETCH_DISTRICTS = 'FETCH_DISTRICTS';
export const SET_DISTRICTS = 'SET_DISTRICTS';

export const FETCH_PUESTOS = 'FETCH_PUESTOS';
export const SET_PUESTOS = 'SET_PUESTOS';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const FETCH_PUESTO = 'FETCH_PUESTO';
export const SET_PUESTO = 'SET_PUESTO';
export const APPLY_PUESTO = 'APPLY_PUESTO';
export const PUESTO_APPLIED = 'PUESTO_APPLIED';
export const REPORT_PUESTO_INIT = 'REPORT_PUESTO_INIT';
export const REPORT_PUESTO = 'REPORT_PUESTO';
export const PUESTO_REPORTED = 'PUESTO_REPORTED';
export const SEND_RATING = 'SEND_RATING'

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const SET_EVENTS = 'SET_EVENTS';
export const APPLY_EVENTS_FILTERS = 'APPLY_EVENTS_FILTERS';
export const FETCH_EVENT = 'FETCH_EVENT';
export const SET_EVENT = 'SET_EVENT';
export const TOGGLE_EVENT_ASISTANCE = 'TOGGLE_EVENT_ASISTANCE';
// export const SET_EVENT_ASISTENCE = 'SET_EVENT_ASISTENCE';
export const REPORT_EVENT = 'REPORT_EVENT';
export const EVENT_REPORTED = 'EVENT_REPORTED';
export const SEND_EVENT_CODE = 'SEND_EVENT_CODE';
export const SET_EVENT_CODE_STATUS = 'SET_EVENT_CODE_STATUS';

export const FETCH_MEDALS = 'FETCH_MEDALS';
export const SET_MEDALS = 'SET_MEDALS';

export const FETCH_HELP = 'FETCH_HELP';
export const SET_HELP = 'SET_HELP';
export const FETCH_CASE_DATA = 'FETCH_CASE_DATA';
export const SET_CASE_DATA = 'SET_CASE_DATA';
export const OPEN_CASE = 'OPEN_CASE';
export const SET_CASE_RESULT = 'SET_CASE_RESULT';

export const FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS';
export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_TO_FAJ = 'SEND_MESSAGE_TO_FAJ';

import { put, select } from 'redux-saga/effects';
import axios from '../axios';
import * as actions from '../actions';
import * as selectors from './selectors';

export function* fetchMedalsSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/medallas', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setMedals(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}
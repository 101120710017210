import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { Link } from 'react-router-dom';
import { fetchCaseData } from '../../store/actions';
import { BackButton, Spinner } from '../ui';
import iconInfo from '../../assets/images/Alerta.png';
import { Redirect } from 'react-router-dom';

import './Ayuda.css'

class Asistencia extends Component {

    componentDidMount() {
        this.props.onFetchCaseTypes();
    }
    
    render() {
        const { canApply, caseTypes, isLoading } = this.props;
        if (!canApply) {
            return <Redirect to="/ayuda" />;
        }
        let content = null;
        if (isLoading) {
            content = <Spinner />
        } else {
            content = (
                <div className="link-tabs-container">
                    { caseTypes.map(item => (
                        <Link key={ item.id } className="flex" to={`/ayuda/asistencia/` + item.id } >
                            <p>{ item.name }</p>
                            <span>></span>
                        </Link>
                    ))}
                </div>
            );
        }
        
        return (
            <div>
                <TopTools />
                <BackButton />
                <div className="container help-container sub-view">
                    <h4>Solicitud de asistencia</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <h4>Por qué necesitas ayuda?</h4>
                    {content}
                    {/* <div className="warning-message flex nowrap">
                        <img src={iconInfo} />
                        <p>Ya solicitaste una beca temporal, estamos revisando tu caso y te contactaremos pronto.</p>
                    </div> */}
                </div>
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        canApply: state.help.canApply,
        caseTypes: state.help.caseTypes,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCaseTypes: () => dispatch(fetchCaseData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Asistencia);

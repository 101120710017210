import * as actionTypes from '../actions/actionTypes';

const initialState = {
    cantons: [],
    districts: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CANTONS: return { ...state, cantons: action.cantons }
        case actionTypes.SET_DISTRICTS: return { ...state, districts: action.districts }
        default: return state;
    }
}

export default reducer;

import React from 'react';
import { Link } from 'react-router-dom';
import AfinidadIndicador from './AfinidadIndicador';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const puestoBlock = props => (
    <Link to={`/puestos/${ props.id }`} className="blockLink">
        <div className="PuestoBlock">
            <figure style={{backgroundImage: `url(${props.imgPrincipal})`}}></figure>
            <div className="PuestoBlockContent">
                <h3>{props.puesto}</h3>
                <p className="flex">{props.empresa}
                    <div className="VerMas flex flex-center">
                        <span>Ver + </span><i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </div> 
                    <span className="job-span-location"><i><img src={props.imgPin} alt="Pin"/></i>{props.direccion}</span>
                </p>
                <Tooltip placement="right" mouseEnterDelay={0.5} trigger={['hover']} overlay={<span>La afinidad compara tus habilidades con los requerimientos del puesto. Los puestos que presentan más círculos llenos, son los más adecuados para vos.</span>}>
                    <p className="afinidad flex flex-center justify-start">Tu afinidad con el puesto: 
                        <AfinidadIndicador afinidad={props.afinidad} />
                    </p> 
                </Tooltip>
            </div>
            {/* <Link 
                className="VerMas flex flex-center" 
                to={`/puestos/${ props.id }`} 
                // onClick={props.onOpenDetail }
            >
                Ver + <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </Link> */}
            
        </div>
    </Link>
);

export default puestoBlock;

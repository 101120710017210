import React, { Component } from 'react';
import classes from './Filtros.module.css';
import closeImg from '../../../assets/images/filter-symbol.svg';
import interesImg from '../../../assets/images/interes.svg';
import pinImg from '../../../assets/images/pin-negro.png';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

class Filtros extends Component {

    state = {
        showNearOffersFirst: false,
        distance: [0, 50],
        selectedProvinces: [],
        selectedAffinities: [],
        initialCall: true
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data && state.initialCall) {
            const updatedState = {
                showNearOffersFirst: props.data.my_province === 1,
                initialCall: false
            }
            if (typeof props.data.distance_filter_from !== 'undefined' && typeof props.data.distance_filter_to !== 'undefined') {
                updatedState.distance = [props.data.distance_filter_from, props.data.distance_filter_to]
            }
            if (props.data.province_filter && props.filters.provinces) {
                updatedState.selectedProvinces = props.data.province_filter.map(item => props.filters.provinces.find(p => p.id === item));
            }
            if (props.data.affinities && props.filters.affinities) {
                updatedState.selectedAffinities = props.data.affinities.map(item => props.filters.affinities.find(s => s.id === item));
            }
           
            return updatedState;
        }
        return null;
    }

   
    inputChangedHandler = event => {
        const key = event.target.id;
        const value = key === 'showNearOffersFirst' ? event.target.checked : event.target.value
        this.setState({
            [key]: value
        })
    }
   
    selectItemHandler = (array, item) => {
        const updatedArray = [ ...this.state[array], item ];
        this.setState({
            [array]: updatedArray
        })
    }

    removeItemHandler = (array, id) => {
        const updatedArray = this.state[array].filter(item => item.id !== id);
        this.setState({
            [array]: updatedArray
        })
    }

    applyFilterHandler = () => {
        const filters = {};
        
        if (this.state.selectedProvinces.length) {
            filters.province_filter = this.state.selectedProvinces.map(item => item.id);
        }
       
        if (this.state.selectedAffinities.length) {
            filters.affinities = this.state.selectedAffinities.map(item => item.id);
        }
        
        filters.distance_filter_from = this.state.distance[0];
        filters.distance_filter_to = this.state.distance[1];
        filters.my_province = this.state.showNearOffersFirst ? 1 : 0;

        this.props.onFilter(filters)
        this.props.onClose();
    }

    render() {
        const { filters } = this.props;
        let provinces, affinities = null;
        if (filters) {
            provinces = filters.provinces.map(item => {
                const isSelected = this.state.selectedProvinces.includes(item);
                return (
                    <div 
                        key={ item.id } 
                        className={ [classes.chip, isSelected ? classes.selected : null].join(' ') }
                        onClick={ () => isSelected ? this.removeItemHandler('selectedProvinces', item.id) : this.selectItemHandler('selectedProvinces' ,item) }
                    >
                        <span>{ item.name }</span>
                    </div>
                );
            });

            affinities = filters.affinities.map(item => {
                const isSelected = this.state.selectedAffinities.includes(item);
                return (
                    <div 
                        key={ item.id } 
                        className={ [classes.chip, isSelected ? classes.selected : null].join(' ') }
                        onClick={ () => isSelected ? this.removeItemHandler('selectedAffinities', item.id) : this.selectItemHandler('selectedAffinities' ,item) }
                    >
                        <span>{ item.name }</span>
                    </div>
                );
            });
        }
        return (
            <div className={ classes.backdrop } onClick={ this.props.onClose }>
                <div className={ classes.modal } onClick={ e => e.stopPropagation() }>
                    <div className={ `${classes.header} flex`}>
                        <h2>Filtros</h2>
                        <div className={classes.closeFiltersX} onClick={ this.props.onClose }>
                            <img src={ closeImg } alt="cerrar" className={ classes.close }  />
                        </div>
                    </div> 
                    <div className={ classes.content }>
                        <label className="near-me">
                            <input 
                                id="showNearOffersFirst"
                                type="checkbox" 
                                value={ this.state.showNearOffersFirst } 
                                onChange={ this.inputChangedHandler }
                                defaultChecked={ this.state.showNearOffersFirst }
                            />
                            Mostrar primero eventos cerca de mí
                        </label>
                        <section>
                            <img src={ pinImg } className={ classes.sectionIcon } alt="pin" />
                            <h3>Ubicación</h3>
                            <div className={ classes.chipsWrapper }>
                                { provinces }
                            </div>
                        </section>
                        <section>
                            <img src={ pinImg } className={ classes.sectionIcon } alt="pin" />
                            <h3>Distancia de viaje</h3>
                            <div className={ classes.sliderContainer }>
                                {/* <p>{ this.state.distance === '0' ? '-' : this.state.distance === '50' ? '+50' : this.state.distance } km</p> */}
                                <p>De { this.state.distance[0] }km a { this.state.distance[1] }km </p>
                                {/* <input 
                                    id="distance"
                                    type="range" 
                                    min="0" 
                                    max="50"
                                    value={ this.state.distance } 
                                    onChange={ this.inputChangedHandler }
                                /> */}
                                <Range 
                                    id="distance"
                                    min={ 0 }
                                    max={ 50 }
                                    value={ this.state.distance } 
                                    onChange={value => this.inputChangedHandler({ target: { id: 'distance', value: value }}) }
                                    handleStyle={[{ backgroundColor: '#FFFFFF', borderColor: '#A4A2A2' }, { backgroundColor: '#FFFFFF', borderColor: '#A4A2A2' }]}
                                    railStyle={{ backgroundColor: '#A4A2A2' }}
                                    trackStyle={[{ backgroundColor: '#F2C714' }]}
                                />

                                <div className={ classes.sliderLabels }>
                                    <span>1km</span>
                                    <span>+50km</span>
                                </div>
                            </div>
                        </section>
                        <section>
                            <img src={ interesImg } className={ classes.sectionIcon } alt="sector de interes" />
                            <h3>Afinidad</h3>
                            <div className={ classes.chipsWrapper }>
                                { affinities }
                            </div>
                        </section>
                        <button className={ classes.button } onClick={ this.applyFilterHandler }>Filtrar</button>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Filtros;
import * as actionTypes from './actionTypes';

export const setPuestos = data => {
    return {
        type: actionTypes.SET_PUESTOS,
        jobs: data.jobs,
        opportunities: data.opportunities,
        continueWorking: data.continue_working,
        applies: data.applies,
        data: data.data,
        filters: data.filters
    };
};

export const fetchPuestos = () => {
    return {
        type: actionTypes.FETCH_PUESTOS,
    };
};

export const applyFilters = filters => {
    return {
        type: actionTypes.APPLY_FILTERS,
        filters
    };
};

export const fetchPuesto = id => {
    return {
        type: actionTypes.FETCH_PUESTO,
        id
    };
};

export const setPuesto = puesto => {
    return {
        type: actionTypes.SET_PUESTO,
        puesto
    };
};

//
//id of the job to apply
//callback is the action that will be called if the job application was successful, can be null.
//
export const applyToPuesto = (id, callback) => {
    return {
        type: actionTypes.APPLY_PUESTO,
        id,
        callback
    };
};

export const puestoAppliedSuccess = puesto => {
    return {
        type: actionTypes.PUESTO_APPLIED,
        puesto
    };
};

// export const reportPuestoInit = () => {
//     return {
//         type: actionTypes.REPORT_PUESTO_INIT,
//     };
// };

//
//id of the job to be reported
//action that will be called if the job was successfully reported, can be null.
//
export const reportPuesto = (id, justification, callback) => {
    return {
        type: actionTypes.REPORT_PUESTO,
        id,
        justification,
        callback
    };
};

export const puestoReportedSuccess = id => {
    return {
        type: actionTypes.PUESTO_REPORTED,
        id
    };
};

export const sendRating = data => {
    return {
        type: actionTypes.SEND_RATING,
        data
    };
};
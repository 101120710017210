import * as actionTypes from '../actions/actionTypes';

export const uiStartLoading = () => {
    return {
        type: actionTypes.UI_START_LOADING
    }
}

export const uiStopLoading = () => {
    return {
        type: actionTypes.UI_STOP_LOADING
    }
}

//Send all of the required props to open the modal
export const uiShowModal = (title, text, variant, firstButtonText, secondButtonText, firstButtonAction, secondButtonAction) => {
    return {
        type: actionTypes.UI_SHOW_MODAL,
        title,
        text,
        variant,
        firstButtonText,
        secondButtonText,
        firstButtonAction,
        secondButtonAction
    }
}

export const uiHideModal = () => {
    return {
        type: actionTypes.UI_HIDE_MODAL
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Centered, BackButton } from '../ui';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import classes from './Oportunidades.module.css';
import imgSrc from '../../assets/images/oportunidades.png'
import { fetchPuestos } from '../../store/actions';
import Oportunidad from './Oportunidad';

class Oportunidades extends Component {

    componentDidMount() {
        if (!this.props.opportunities.length) {
            this.props.onFetchData()
        }
    }
    
    render() {
        const { opportunities } = this.props;
        let text = '';
        if (opportunities.length) {
            text = opportunities.length > 1 
            ? 'Podés asistir a estas capacitaciones disponibles para hacer tu perfil más atractivo' 
            : 'Podés asistir a esta capacitación disponible para hacer tu perfil más atractivo';
        }
        return (
            <div>
                <TopTools />
                <BackButton />
                <div className={ `container ${classes.container}` }>
                    <Centered>
                        <img src={ imgSrc } alt="oportunidades" />
                        <h1>¡Aumentá tus oportunidades!</h1>
                        <p>Si lográs aumentar tus conocimientos podrás acceder a 20 puestos más. { text }</p>
                    </Centered>
                    { opportunities.map(item => (
                        <Oportunidad 
                            key={ item.id }
                            data={ item }
                        />
                    ))}
                </div>
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        opportunities: state.puestos.opportunities
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchPuestos())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Oportunidades);
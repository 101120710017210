import React from 'react';

import styles from "./NotFound.module.css";

// Images
import logo from './../../assets/images/faj-logo.png';


class NotFound extends React.Component{
    render() {
        const {goBack} = this.props.history;
        return (
            <div className={ styles.ingreso }>
                <div className={ styles['info-top'] }>
                    <img src={logo} alt="Fundaci&oacute;n Acci&oacute;n Joven" />
                    <h1>Error 404</h1>
                </div>
                <div className={styles.blackContainer}>
                    <div className={styles.arrow}>
                        <span className="glyphicon glyphicon-menu-down"></span>
                    </div>
                    <button
                        onClick={ () => goBack()} 
                        className={styles.button}>Volver</button>
                </div>
            </div>
        );
    }
}

export default NotFound;
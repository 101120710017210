import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { Link } from 'react-router-dom';
import { fetchConversation, setMessages } from '../../store/actions';
import { BackButton, Spinner } from '../ui';

//Images
import iconInfo from '../../assets/images/Alerta.png';

import './Mensajes.css'

class Mensajes extends Component {

    componentDidMount() {
        this.props.onFetchData();
        this.props.onSetMessages([]);
   
        this.conversationsInterval = setInterval(this.requestConversations, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.conversationsInterval);
    }
    requestConversations = () => {
        this.props.onFetchData();
    }

    renderConversations(){
        return this.props.conversations.map(conversation => {
            return(
                <Link key={conversation.conversation_id} to={{pathname: `/conversacion/${conversation.conversation_id}`, state: conversation}} className="flex conversation justify-start nowrap">
                    <figure style={{backgroundImage: `url(${conversation.image})`}}></figure>
                    <div>
                        <div className="flex justify-start flex-center">
                            <i className="fa fa-users" aria-hidden="true"></i>
                            <h4>{ conversation.from }</h4>
                        </div>
                        <p className="message-text-preview">{conversation.lastMessage}</p>
                        <span>{conversation.date}</span>
                    </div>
                </Link>
            );
        });
    }
    
    render() {
        const { conversations } = this.props;
        let content = (
            <div className="container messages-container">
                <h4>Tus mensajes</h4>
                <div className={ conversations.length ? "conversations-container show" : "conversations-container"}>
                    {this.renderConversations()}

                    {/* <div class="flex conversation justify-start nowrap">
                        <figure style={{backgroundImage: 'url(http://www.dimitri.co.uk/portraits/portraits-images/young-man-portrait-office.jpg)'}}></figure>
                        <div>
                            <div className="flex justify-start flex-center">
                                <i class="fa fa-home" aria-hidden="true"></i>
                                <h4>Daniel Aguilar</h4>
                            </div>
                            <p className="message-text-preview">Buenas tardes, te envie uno de los correos que me paso la administración para comnetarnos sobre el cambio de reglas.</p>
                            <span>12 de Agosto 2018</span>
                        </div>
                    </div> */}
                </div>
                <div className={ conversations.length ? "no-messages-container" : "no-messages-container show"}>
                    <p>Aún no tiene ningún mensaje.</p>
                    <div className="warning-message flex nowrap">
                        <img src={iconInfo} />
                        <p>Podés enviar mensajes a la Fundación Acción Joven (FAJ) o responder a mensajes que te ha enviado FAJ o cualquier municipalidad.</p>
                    </div>
                </div>
                <Link to={'/mensajes/faj'} className="principal-button">Enviar mensaje a FAJ</Link>
            </div>
        )
        return (
            <div>
                <TopTools />
                <BackButton />
                {content}
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        conversations: state.messaging.conversations,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchConversation()),
        onSetMessages: messages => dispatch(setMessages(messages))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mensajes);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EditarLenguajes extends Component {

    onAddNew = () => {
        this.props.onUpdate('languages', [ ...this.props.languages, '' ]);
        this.props.onUpdate('languages_level', [ ...this.props.languages_level, '' ]);
    }

    onRemove = index => {
        const updatedLanguages = this.props.languages.filter((item, i) => index !== i);
        const updatedLevels = this.props.languages_level.filter((item, i) => index !== i);

        this.props.onUpdate('languages',updatedLanguages);
        this.props.onUpdate('languages_level',updatedLevels);
    }

    onEdit = (event, index) => {
        const updatedArray = this.props[event.target.id].map((item, i) => {
            if (index === i) {
                return event.target.value;
            }
            return item;
        });

        this.props.onUpdate(event.target.id, updatedArray);
    }

    render() {
        return (
            <>
                
                { this.props.languages.map((item, index) => (
                    <div key={ index } className="registroNuevoIdioma">
                        <p className="form-subtitle">Idioma {index + 1}</p>
                        <select 
                            className="ctlLenguaje" 
                            id="languages" 
                            onChange={ e => this.onEdit(e, index) } 
                            value={ item }
                        >
                            <option value="">Elegir idioma</option>
                            {this.props.languagesOptions.map(option => (
                                <option key={ option.id } value={ option.id }>{ option.language }</option>
                            )) }
                        </select>
                        <p>¿Cuál es tu nivel de manejo del idioma?</p>
                        <select 
                            className="ctlLenguajeNivel" 
                            id="languages_level" 
                            onChange={ e => this.onEdit(e, index) }
                            value={ this.props.languages_level[index] }
                        >
                            <option value="">Elegir nivel</option>
                            {this.props.levelOptions.map(option => (
                                <option key={ option.id } value={ option.id }>{ option.level }</option>
                            )) }
                        </select>
                        <p className="add-language-button" onClick={() => this.onRemove(index) }>- Eliminar idioma</p>
                        <hr></hr>
                    </div>
                )) }
                <p className="add-language-button" onClick={ this.onAddNew }>+ Agregar idioma</p>
            </>
        );
    }
}

EditarLenguajes.propTypes = {
    languages: PropTypes.array.isRequired,
    languages_level: PropTypes.array.isRequired,
    languagesOptions: PropTypes.array.isRequired,
    levelOptions: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired
};

export default EditarLenguajes;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { fetchHelp } from '../../store/actions';
import { Panel, PanelGroup } from 'react-bootstrap';
import { BackButton, Spinner } from '../ui';

import './Ayuda.css'

class AyudaPreguntas extends Component {

    componentDidMount() {
        if (this.props.groups) {
            this.props.onFetchData();
        }
    }

    renderQuestions = () => {
        const group = this.props.groups.find(item => item.id === parseInt(this.props.match.params.id));
        if (group) {
            return (
                <div className="container help-container sub-view">
                    <h4>{ group.name }</h4>
                    <PanelGroup accordion id="accordion-example">
                        { group.faqs.map(faq => (
                            <Panel key={ faq.id } eventKey={ faq.id }>
                                <Panel.Heading>
                                    <Panel.Title toggle>{ faq.question }</Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>{ faq.answer }</Panel.Body>
                            </Panel>
                        ))}
                    </PanelGroup>
                </div>
            );
        }
        return null;
    }
    
    render() {
        let content = null;
        if (this.props.isLoading) {
            content = <Spinner />;
        } else {
            content = this.renderQuestions();
        }
        return (
            <div>
                <TopTools />
                <BackButton />
                {content}
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        groups: state.help.groups,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchHelp()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AyudaPreguntas);

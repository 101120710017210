import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { BackButton, Spinner } from '../ui';
import { fetchEditProfileData, updateProfile, fetchCantons, fetchDistrics } from '../../store/actions';
import Mapa from '../Registro/Mapa';
import iconInfo from '../../assets/images/icon-info.png';
import Select from 'react-select';
import Cursos from '../Registro/Cursos';
import EditarLenguajes from './EditarLenguajes';
import EditarExperiencia from './EditarExperiencia';

class EditarPerfil extends Component {

    state = {
        phone: '',
        phone_family: '',
        email: '',
        location: '',
        latitude: 9.9325427,
        longitude: -84.0795782,
        district: '',
        canton: '',
        province: '',
        hasUpdatedProvice: false,
        hasUpdatedCanton: false,
        licenses: [],
        experience: [],
        learn: [],
        vehicle: '',
        study: '',
        academic_center_status: '',
        academic_degree: '',
        courses: [],
        languages: [],
        languages_level: [],
        position_company: [],
        position_position: [],
        position_years: [],
        position_description: [],
        imageFile: null
    }

    componentDidMount() {
        this.props.onFetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.profileData !== prevProps.profileData && this.props.profileData) {
            this.fillForm();
        }
    }

    fillForm = () => {
        const { profileData } = this.props;

        const licenses = profileData.licenses.map(item => ({label: item.type, value: item.id }));
        const abilities = profileData.abilities.map(item => ({label: item.name, value: item.id }));
        const interests = profileData.interests.map(item => ({label: item.name, value: item.id }));
        const languages = profileData.languages.map(item => item.language.id);
        const languages_level = profileData.languages.map(item => item.level.id);

        const position_company = profileData.experiences != undefined ? profileData.experiences.map(item => item.company) : [];
        const position_position = profileData.experiences != undefined ? profileData.experiences.map(item => item.position) : [];
        const position_years = profileData.experiences != undefined ? profileData.experiences.map(item => item.years) : [];
        const position_description = profileData.experiences != undefined ? profileData.experiences.map(item => item.description) : [];

        this.setState({
            phone: profileData.phone,
            phone_family: profileData.phone_family,
            email: profileData.user.email,
            province: profileData.province.id,
            district: profileData.district.code,
            canton: profileData.canton.code,
            location: profileData.location,
            academic_degree: profileData.last_grade_coursed ? profileData.last_grade_coursed.id : '',
            latitude: parseFloat(profileData.latitude),
            longitude: parseFloat(profileData.longitude),
            licenses: licenses,
            experience: abilities,
            learn: interests,
            vehicle: profileData.own_car ? '1' :'0',
            study: profileData.study ? '1' :'0',
            academic_center_status: profileData.study_in_academic_center ? '1' :'0',
            courses: profileData.free_courses,
            languages: languages,
            languages_level: languages_level,
            position_company,
            position_position,
            position_years,
            position_description
        })
    }

    changeHandler = event => {
        const name = event.target.id;
        const value = event.target.value;


        this.setState({
            [name]: value
        })

        if (name === 'province') {
            this.props.onFetchCantons(value);
            this.setState({ canton: '', district: '', hasUpdatedProvice: true });
        }
        if (name === 'canton') {
            this.props.onFetchDistricts(value);
            this.setState({ district: '', hasUpdatedCanton: true });
        }
    }

    locationPickedHandler = (lat, lng) => {
        this.setState({
            latitude: lat,
            longitude: lng
        });
    }

    getSimpleValue = array => {
        return array.map(item => {
            return item.value;
        });
    }

    selectImageHandler = event => {
        this.setState({ imageFile: event.target.files[0] });
    }
 
    updateInformationHandler = (key, value) => {
        this.setState({ [key]: value });
    }

    updateInformationExperienceHandler = data => {
        this.setState({ 
            ...this.state, ...data
        });
    }

    updateProfileHandler = event => {
        event.preventDefault();
        const { profileData } = this.props;

        if (profileData) {

            const imageFormData = new FormData();
            imageFormData.append('young_photo', this.state.imageFile, 'young_photo');

            this.props.onUpdateProfile(
                {
                    young__phone: this.state.phone,
                    young__phone__family: this.state.phone_family,
                    young__email: this.state.email,
                    study: this.state.study,
                    academic_degree: this.state.academic_degree,
                    academic_center_status: this.state.academic_center_status,
                    learn: this.getSimpleValue(this.state.learn),
                    experience: this.getSimpleValue(this.state.experience),
                    courses: this.state.courses,
                    licenses: this.getSimpleValue(this.state.licenses),
                    vehicle: this.state.vehicle,
                    languages: this.state.languages,
                    languages_level: this.state.languages_level,
                    location: this.state.location,
                    latitude: this.state.latitude,
                    longitude: this.state.longitude,
                    district: this.state.district,
                    canton: this.state.canton,
                    province: this.state.province,
                    position_company: this.state.position_company,
                    position_position: this.state.position_position,
                    position_years: this.state.position_years,
                    position_description: this.state.position_description,
                },
                imageFormData
            );
        }
    }
    
    render() {
        const { provinces, academicDegrees, profileData, isLoading, licenses, sectors, languages_level, languages } = this.props;
        const cantons = this.state.hasUpdatedProvice ? this.props.cantons : this.props.initialCantons;
        const districts = this.state.hasUpdatedCanton ? this.props.districts : this.props.initialDistricts;

        const licensesOptions = licenses.map(item => ({label: item.type, value: item.id }));

        const sectorsOptions = sectors.map(item => {
            return {
                label: item.name,
                options: item.categories.map(item => ({label: item.name, value: item.id }))
            }
        });


        let content = null;
        if (isLoading || !profileData) { 
            content = <Spinner />
        } else {
            const avatarImage = this.state.imageFile ? URL.createObjectURL(this.state.imageFile) : profileData.image;
            content = (
                <div className="container path-container">
                    <div className="card">
                        <div className="profile-general-information edit-image flex flex-center justify-center">
                            <figure 
                                style={ {backgroundImage: `url(${avatarImage})`} }>
                            </figure>
                            <div>
                                <p>Agregar nueva fotografía</p>
                                {/* <button>Agregar</button> */}
                                <label className="custom-file-upload">
                                    <input type="file" onChange={ this.selectImageHandler } accept="image/*" />
                                    Agregar
                                </label>
                            </div>
                        </div>
                    </div>
                    <form style={{ paddingBottom: 35 }} onSubmit={ this.updateProfileHandler }>
                        <div className="card edit-info">
                            <h4>Información de contacto</h4>
                            <div className="flex justify-start flex-center">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                <div>
                                    <p>Celular personal</p>
                                    <input 
                                        onChange={this.changeHandler} 
                                        value={this.state.phone} 
                                        type="tel" 
                                        id="phone" 
                                        placeholder="Celular personal" 
                                    />
                                </div>
                            </div>
                            <div className="flex justify-start flex-center">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                                <div>
                                    <p>Celular de un familiar</p>
                                    <input 
                                        onChange={this.changeHandler} 
                                        value={this.state.phone_family} 
                                        type="tel" 
                                        id="phone_family" 
                                        placeholder="Celular de un familiar" 
                                    />
                                </div>
                            </div>
                            <div className="flex justify-start flex-center">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                <div>
                                    <p>Correo electrónico</p>
                                    <input 
                                        onChange={this.changeHandler} 
                                        value={this.state.email} 
                                        type="email" 
                                        id="email" 
                                        placeholder="Correo electrónico" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card edit-contact-info map-container">
                            <h4>Dirección</h4>
                            <p>Ubicá tu dirección dando click en la dirección correcta de tu casa en el mapa o seleccioná abajo tu dirección.</p>
                            
                            <Mapa 
                                latitude={ this.state.latitude }
                                longitude={ this.state.longitude }
                                onLocationClicked={ this.locationPickedHandler } 
                            />
                            <div className="selects-container">
                                <select id="province" onChange={this.changeHandler} value={ this.state.province } >
                                    <option value="">Provincia</option>
                                    { provinces.map(item => (
                                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                                    ))}
                                </select>
                                <br />
                                <select id="canton" onChange={this.changeHandler} value={ this.state.canton }>
                                    <option value="">Cantón</option>
                                    { cantons.map(item => (
                                        <option key={ item.code } value={ item.code }>{ item.name }</option>
                                    ))}
                                </select>
                                <br />
                                <select id="district" onChange={this.changeHandler} value={ this.state.district }>
                                    <option value="">Distrito</option>
                                    { districts.map(item => (
                                        <option key={ item.code } value={ item.code }>{ item.name }</option>
                                    ))}
                                </select>
                                <br />
                                <input 
                                    onChange={this.changeHandler} 
                                    value={this.state.location} 
                                    type="text" 
                                    id="location" 
                                    placeholder="Escriba la dirección en detalle" 
                                />
                                <br />
                                <div className="flex nowrap">
                                    <img src={iconInfo} alt="icon" />
                                    <p>Tu dirección exacta no se hará pública, con ella podemos ordenar mejor cuáles oportunidades están más cerca de vos, así que intenta que esté siempre actualizada.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card edit-contact-info">
                            <h4>Educación</h4>
                            <p>¿Estás estudiando actualmente?</p>
                            <div className="flex-labels">
                                <label>
                                    <input 
                                        type="radio" 
                                        id="study" 
                                        value="1" 
                                        checked={ this.state.study === '1'}
                                        onChange={ this.changeHandler }
                                    /> 
                                    Sí
                                </label>
                                <label>
                                    <input 
                                        type="radio" 
                                        id="study" 
                                        value="0" 
                                        checked={ this.state.study === '0'}
                                        onChange={ this.changeHandler }
                                    /> 
                                    No
                                </label>
                            </div>
                            <p>¿Cursaste estudios en algún centro académico?</p>
                            <div className="flex-labels">
                                <label>
                                    <input 
                                        type="radio" 
                                        id="academic_center_status" 
                                        value="1" 
                                        checked={ this.state.academic_center_status === '1'}
                                        onChange={ this.changeHandler }
                                    /> 
                                    Sí
                                </label>
                                <label>
                                    <input 
                                        type="radio" 
                                        id="academic_center_status" 
                                        value="0" 
                                        checked={ this.state.academic_center_status === '0'}
                                        onChange={ this.changeHandler }
                                    /> 
                                    No
                                </label>
                            </div>
                            <p>Seleccioná el último grado académico que cursaste.</p>
                            <select 
                                id="academic_degree" 
                                onChange={this.changeHandler} 
                                value={ this.state.academic_degree }
                            >
                                <option 
                                    value="">Educación
                                </option>
                                { academicDegrees.map(item => (
                                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                                ))}
                            </select>
                            
                            <p className="course-title">¿Has llevado algún curso libre o técnico?</p>
                            <Cursos data={ this.state.courses } onChange={updatedCourses => this.setState({courses: updatedCourses})} />

                            <br />
                            <br />
                            <p>¿Hablás algún otro idioma además del español?</p>
                            <EditarLenguajes 
                                languages={ this.state.languages }
                                languages_level={ this.state.languages_level}
                                languagesOptions={ languages}
                                levelOptions={ languages_level }
                                onUpdate={ this.updateInformationHandler }
                            />
                        </div>
                        <div className="card edit-contact-info">
                            <h4>Experiencia</h4>
                            <EditarExperiencia
                                position_company={ this.state.position_company }
                                position_position={ this.state.position_position }
                                position_years={ this.state.position_years }
                                position_description={ this.state.position_description }
                                onUpdate={ this.updateInformationExperienceHandler }
                            /> 
                        </div>
                        <div className="card edit-contact-info">
                            <h4>Licencias</h4>
                            <p>Seleccioná las licencias que tenés.</p>
                            <Select 
                                options={ licensesOptions } 
                                isMulti
                                inputId="licenses"
                                placeholder="Selecciona"
                                value={ this.state.licenses }
                                defaultValue={ this.state.licenses }
                                onChange={ (selectedOptions) => this.setState({ licenses: selectedOptions }) }
                                noOptionsMessage={() => null}
                            />
                            <br />
                            <p>¿Tenés carro propio?.</p>
                            <div className="flex-labels">
                                <label><input type="radio" id="vehicle" name="vehicle" value="1"  onChange={this.changeHandler} checked={ this.state.vehicle === '1'} /> Sí</label>
                                <label><input type="radio" id="vehicle" name="vehicle" value="0"  onChange={this.changeHandler} checked={ this.state.vehicle === '0'} /> No</label>
                            </div>
                        </div>

                        <div className="card edit-contact-info">
                            <h4>Habilidades</h4>
                            <p>¿Cuáles son tus habilidades?</p>
                            <Select 
                                options={ sectorsOptions } 
                                isMulti
                                label
                                inputId="experience"
                                placeholder="Selecciona"
                                noOptionsMessage={() => null}
                                value={ this.state.experience }
                                defaultValue={ this.state.experience }
                                onChange={ (selectedOptions) => this.setState({ experience: selectedOptions }) }
                            />
                        </div>

                        <div className="card edit-contact-info">
                            <h4>Intereses</h4>
                            <p>¿Qué te gustaría aprender?</p>
                            <Select 
                                options={ sectorsOptions } 
                                isMulti
                                label
                                inputId="learn"
                                placeholder="Selecciona"
                                noOptionsMessage={() => null}
                                value={ this.state.learn }
                                defaultValue={ this.state.learn }
                                onChange={ (selectedOptions) => this.setState({ learn: selectedOptions }) }
                            />
                        </div>

                        <button className="submit-button">Guardar información</button>
                    </form>
                </div>
            );
        }
        return (
            <div style={{backgroundColor: '#F9F9F9'}}>
                <TopTools></TopTools>
                <BackButton />
                { content }
                <BottomTools></BottomTools>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        profileData: state.profile.editProfileData && state.profile.editProfileData.young,
        provinces: state.profile.editProfileData ? state.profile.editProfileData.provinces : [],
        initialCantons: state.profile.editProfileData ? state.profile.editProfileData.cantons : [],
        initialDistricts: state.profile.editProfileData ? state.profile.editProfileData.districts : [],
        academicDegrees: state.profile.editProfileData ? state.profile.editProfileData.academic_degrees : [],
        licenses: state.profile.editProfileData ? state.profile.editProfileData.licenses : [],
        sectors: state.profile.editProfileData ? state.profile.editProfileData.sectors : [],
        languages: state.profile.editProfileData ? state.profile.editProfileData.languages : [],
        languages_level: state.profile.editProfileData ? state.profile.editProfileData.languages_level : [],
        
        cantons: state.location.cantons,
        districts: state.location.districts,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchEditProfileData()),
        onUpdateProfile: (data, image) => dispatch(updateProfile(data, image)),
        onFetchCantons: provinceId => dispatch(fetchCantons(provinceId)),
        onFetchDistricts: cantonId => dispatch(fetchDistrics(cantonId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarPerfil);
import React from 'react';
import FiltroOpcion from './FiltroOpcion';
import "./Filtro.css";


// Images
import empleados from './../../assets/images/empleados.png';
import trabajo from './../../assets/images/trabajo.png';

export default class Filtro extends React.Component{
    render() {
        return (
            <div className="filtro">
                <div className="header">
                    <h1>Estoy buscando</h1>
                </div>
                <FiltroOpcion filtro="" image={trabajo} alt="Trabajo" text="TRABAJO"></FiltroOpcion>
                <FiltroOpcion filtro="" image={empleados} alt="Empleados" text="EMPLEADOS"></FiltroOpcion>
            </div>
            
        );
    }
}

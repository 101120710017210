import React from 'react';
import { connect } from 'react-redux';
import RegistroEncabezado from "./../RegistroEncabezado/RegistroEncabezado";
import $ from "jquery";
import { Link } from 'react-router-dom';
import Mapa from './Mapa';
import Geocode from "react-geocode";
import { fetchDistrics, fetchCantons } from '../../store/actions';
import axios from '../../store/axios';
import avatar_placeholder from '../../assets/images/image-placeholder-350x350.png';

Geocode.setApiKey('AIzaSyB4Yhph4TS3DZuzZ-GdP2s7EYDbIJjfayI');

// Enable or disable logs. Its optional.
Geocode.enableDebug();


class RegistroPaso1 extends React.Component {

    changeHandler = event => {
      
        const name = event.target.id;
        const value = event.target.value;
        let updatedProperty = {};
        updatedProperty[name] = value;

        if (name === 'province') {
            this.props.onFetchCantons(value);
            this.props.enviaDatos({ canton: '', district: '', ...updatedProperty});
            return 
        }
        if (name === 'canton') {
            this.props.onFetchDistricts(value);
            this.props.enviaDatos({ district: '', ...updatedProperty});
            return
        }
        this.props.enviaDatos(updatedProperty);
    }

    validateEmail = (email) => {
        // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateName = (name) => {
        var re = /\d/;
        return !re.test(name);
    }

    hideOrShowError = (name, errors, invalid) => {
        if(invalid){
            for(let item of errors){
                if(`${name}Error` === item.className){
                    document.querySelector('.' + item.className).innerHTML = item.error;
                    break;
                }
            }
        }
        else{
            $(`.${name}Error`).html('');
        }
    }

    validateFields = event => {
        let errors = []
        const name = event.target.id;
        if(event.keyCode == 9 && event.type == 'keyUp'){
            return;
        }
        // Cedula
        if ((this.props.data.identification.length < 9 || this.props.data.identification.length > 12) && name === 'identification') {
            errors.push({className: 'identificationError', error: 'La cédula debe ser de 9 a 12 números, sin guiones'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Nombre
        if (this.props.data.name.length !== 0 && name === 'name') {
            if (!this.validateName(this.props.data.name)) {
                errors.push({className: 'nameError', error: 'El nombre no puede contener números'})
                this.hideOrShowError(name, errors, true)
                return;
            }
        } else if(this.props.data.name.length == 0 && name === 'name') {
            errors.push({className: 'nameError', error: 'Por favor ingresa tu nombre'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Celular propio
        if (this.props.data.phone.length !== 8 && name === 'phone') {
            errors.push({className: 'phoneError', error: 'El número celular debe ser de 8 números, sin guiones'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Celular familiar
        if (this.props.data.phoneFamily.length !== 8 && name === 'phoneFamily') {
            errors.push({className: 'phoneFamilyError', error: 'El número celular debe ser de 8 números, sin guiones'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Email
        if (this.props.data.email.length !== 0 && name === 'email') {
            if (!this.validateEmail(this.props.data.email)) {
                errors.push({className: 'emailError', error: 'Debes ingresar un email válido'})
                this.hideOrShowError(name, errors, true)
                return;
            }
        } else if(this.props.data.email.length == 0 && name === 'email') {
            errors.push({className: 'emailError', error: 'Por favor ingresa tu email'})
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Password
        if (this.props.data.password.length < 8 && name === 'password') {
            errors.push({className: 'passwordError', error: 'La contraseña debe ser de al menos 8 caracteres'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Provincia
        if (this.props.data.province ===  '' && name === 'province') {
            errors.push({className: 'provinceError', error: 'Debes seleccionar una provincia'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Canton
        if (this.props.data.canton ===  '' && name === 'canton') {
            errors.push({className: 'cantonError', error: 'Debes seleccionar un cantón'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Distrito
        if (this.props.data.district ===  '' && name === 'district') {
            errors.push({className: 'districtError', error: 'Debes seleccionar un distrito'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }
        // Direccion
        if (this.props.data.location ===  '' && name === 'location') {
            errors.push({className: 'locationError', error: 'Debes brindar una dirección exacta'})
            this.hideOrShowError(name, errors, true)
            return;
        }
        else{
            this.hideOrShowError(name, errors, false)
        }

        if (errors.length > 0){
            errors.map((item) => {
                document.querySelector('.' + item.className).innerHTML = item.error;
                return true;
            });
            
            // Scrolling to top with animation
            $('html,body').animate({ scrollTop: 0 }, 'slow');

        }
    }

    formHandler = (event) => {
        event.preventDefault();

        let errors = []

        // Validando formulario

        // Limpiando mensajes de error anteriores
        $('.error').html('');

        // Cedula
        if (this.props.data.identification.length < 9 || this.props.data.identification.length > 12) {
            errors.push({className: 'identificationError', error: 'La cédula debe ser de 9 a 12 números, sin guiones'})
        }
        // Nombre
        if (this.props.data.name.length !== 0) {
            if (!this.validateName(this.props.data.name)) {
                errors.push({className: 'nameError', error: 'El nombre no puede contener números'})
            }
        } else {
            errors.push({className: 'nameError', error: 'Por favor ingresa tu nombre'})
        }
        // Celular propio
        if (this.props.data.phone.length !== 8) {
            errors.push({className: 'phoneError', error: 'El número celular debe ser de 8 números, sin guiones'})
        }
        // Celular familiar
        if (this.props.data.phoneFamily.length !== 8) {
            errors.push({className: 'phoneFamilyError', error: 'El número celular debe ser de 8 números, sin guiones'})
        }
        // Email
        if (this.props.data.email.length !== 0) {
            if (!this.validateEmail(this.props.data.email)) {
                errors.push({className: 'emailError', error: 'Debes ingresar un email válido'})
            }
        } else {
            errors.push({className: 'emailError', error: 'Por favor ingresa tu email'})
        }
        // Password
        if (this.props.data.password.length < 8) {
            errors.push({className: 'passwordError', error: 'La contraseña debe ser de al menos 8 caracteres'})
        }
        // Provincia
        if (this.props.data.province ===  '') {
            errors.push({className: 'provinceError', error: 'Debes seleccionar una provincia'})
        }
        // Canton
        if (this.props.data.canton ===  '') {
            errors.push({className: 'cantonError', error: 'Debes seleccionar un cantón'})
        }
        // Distrito
        if (this.props.data.district ===  '') {
            errors.push({className: 'districtError', error: 'Debes seleccionar un distrito'})
        }
        // Direccion
        if (this.props.data.location ===  '') {
            errors.push({className: 'locationError', error: 'Debes brindar una dirección exacta'})
        }

        if (errors.length > 0){
            errors.map((item) => {
                document.querySelector('.' + item.className).innerHTML = item.error;
                return true;
            });
            
            // Scrolling to top with animation
            $('html,body').animate({ scrollTop: 0 }, 'slow');

        } 
        else {
            this.checkRegisterData(this.props.data.identification, this.props.data.email)
        }

    }

    checkRegisterData = (id, email) => {
        axios.post('/comprobar-datos', {identification: id, email: email})
        .then((response) => {
            if (response.data.identification && response.data.email) {
                this.props.goNext();
            } 
            if(!response.data.identification && response.data.email){
                this.props.openModal('El número de identificación ya existe en el sistema');
            }
            if(response.data.identification && !response.data.email){
                this.props.openModal('El correo electrónico ya existe en el sistema');
            }
            if(!response.data.identification && !response.data.email){
                this.props.openModal('El número de identificación y el correo electrónico ya existen en el sistema');
            }
        })
        .catch((err)=>{
            this.props.openModal('Se produjo un error inesperado');
        })
    }

    locationPickedHandler = (lat, lng) => {
        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0]
                const location = address.formatted_address;
                const length = address.address_components.length;
                const country = address.address_components[length - 1].long_name;
            },
            error => {
                console.error(error);
            }
        );
        this.props.enviaDatos({
            latitude: lat,
            longitude: lng
        });
    }

    locationSearchHandler = event => {
        Geocode.fromAddress(event.target.value).then(
            response => {
                document.querySelector('.direccionError').innerHTML = '';
                const { lat, lng } = response.results[0].geometry.location;
                this.locationPickedHandler(lat, lng);
            },
            error => {
                document.querySelector('.direccionError').innerHTML = 'La dirección brindada no pudo ser localizada';
            }
        );
    }

    render(){
        const avatarImage = this.props.imageFile ? URL.createObjectURL(this.props.imageFile) : avatar_placeholder;

        return (
            <div className="registro paso1 show">
            <RegistroEncabezado paso="1"></RegistroEncabezado>
            <form 
                onSubmit={this.formHandler} 
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                }}
            >
                <div className="content container">
                    <h1>Mis Datos</h1>
                    <h4>1. Datos personales</h4>
                    <div className="error identificationError"></div>
                    <input type="text" id="identification" value={this.props.data.identification} onChange={this.changeHandler} onKeyUp={this.validateFields} onBlur={this.validateFields} placeholder="* Número de cédula" />

                    <div className="error nameError"></div>
                    <input type="text" id="name" value={this.props.data.name} onChange={this.changeHandler} onBlur={this.validateFields} onKeyUp={this.validateFields} placeholder="* Nombre completo" />
                    
                    <div className="error phoneError"></div>
                    <input onChange={this.changeHandler} value={this.props.data.phone} onBlur={this.validateFields} onKeyUp={this.validateFields} type="text" id="phone" placeholder="* Número de celular propio" />
                    
                    <div className="error phoneFamilyError"></div>
                    <input onChange={this.changeHandler} value={this.props.data.phoneFamily} onBlur={this.validateFields} onKeyUp={this.validateFields} type="text" id="phoneFamily" placeholder="* Número de celular de un familiar" />
                    
                    <h4>2. Datos de ingreso</h4>
                    
                    <div className="error emailError"></div>
                    <input onChange={this.changeHandler} onKeyUp={this.validateFields} onBlur={this.validateFields} value={this.props.data.email} type="email" id="email" placeholder="* Correo electrónico" />

                    <div className="error passwordError"></div>
                    <input type="password" id="password" value={this.props.data.password} onChange={this.changeHandler} onBlur={this.validateFields} onKeyUp={this.validateFields} placeholder="* Contraseña" autoComplete="new-password" />

                    <div className="card">
                        <div className="profile-general-information edit-image flex flex-center ">
                            <figure 
                                style={ {backgroundImage: `url(${avatarImage})`} }>
                            </figure>
                            <div>
                                <p>Agregar nueva fotografía.</p>
                                {/* <button>Agregar</button> */}
                                <label className="custom-file-upload">
                                    <input type="file" onChange={ this.props.onImageSelected } accept="image/*" />
                                    Agregar
                                </label>
                                <p>*Coloque el celular en posición vertical.</p>
                            </div>
                        </div>
                    </div>
                    
                    <h4>3. Dirección</h4>
                    <p>Ubicá tu dirección dando click en la dirección correcta de tu casa en el mapa o seleccioná abajo tu dirección.</p>
                    
                    <div className="error direccionError"></div>
                    {/*<input 
                        onChange={this.changeHandler} 
                        onBlur={ this.locationSearchHandler } 
                        value={this.props.data.direccion } 
                        type="text" 
                        id="direccion" 
                        placeholder="Escribí un punto de referencia" 
                    />*/}
                    
                    {/* <iframe title="Google Maps" width="100%" height="350" frameBorder="0" src="https://www.google.com/maps/embed/v1/search?q=San%20Jose%2C%20Costa%20Rica&key=AIzaSyC08zuB-lpT2hbPBpzMGmHoV8L-BriyOYc" allowFullScreen></iframe> */}
                    <Mapa 
                        latitude={ this.props.data.latitude }
                        longitude={ this.props.data.longitude }
                        onLocationClicked={ this.locationPickedHandler } 
                    />
                    <br/>
                    <h5>* Provincia</h5>
                    <div className="error provinceError"></div>
                    <select id="province" onChange={this.changeHandler} onBlur={this.validateFields} onKeyUp={this.validateFields} value={ this.props.data.province } >
                        <option value="">Elegir provincia</option>
                        { this.props.provinces.map(item => (
                            <option key={ item.id } value={ item.id }>{ item.name }</option>
                        ))}
                    </select>

                    <h5>* Cantón</h5>
                    <div className="error cantonError"></div>
                    <select id="canton" onChange={this.changeHandler} onBlur={this.validateFields} onKeyUp={this.validateFields} value={ this.props.data.canton } >
                        <option value="">Elegir cantón</option>
                        { this.props.cantons.map(item => (
                            <option key={ item.code } value={ item.code }>{ item.name }</option>
                        ))}
                    </select>
                    
                    <h5>* Distrito</h5>
                    <div className="error districtError"></div>
                    <select id="district" onChange={this.changeHandler} onBlur={this.validateFields} onKeyUp={this.validateFields} value={ this.props.data.district } >
                        <option value="">Elegir distrito</option>
                        { this.props.districts.map(item => (
                            <option key={ item.code } value={ item.code }>{ item.name }</option>
                        ))}
                    </select>

                    <h5>* Dirección exacta</h5>
                    <div className="error locationError"></div>
                    <input type="text" id="location" value={this.props.data.location} onBlur={this.validateFields} onChange={this.changeHandler} onKeyUp={this.validateFields} placeholder="Escribí tu dirección exacta" />
                    
                    <div className="buttons">
                        <Link to="/user/login" className="btn btn-default white">CANCELAR</Link>
                        {/* <a href="/user/signup/step2" className="btn btn-primary" onClick={this.formHandler}>CONTINUAR</a>*/}
                        <input className="btn btn-primary" type="submit" value="CONTINUAR" />
                    </div>

                </div>
            </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        cantons: state.location.cantons,
        districts: state.location.districts
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCantons: provinceId => dispatch(fetchCantons(provinceId)),
        onFetchDistricts: cantonId => dispatch(fetchDistrics(cantonId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RegistroPaso1);
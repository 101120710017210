import React from 'react';

export default class CampoTexto extends React.Component{
    
    renderTextField() {
        return (
            <div className="text-field-message flex justify-start nowrap">
                <textarea 
                    onChange={this.props.handler} 
                    value={this.props.message} 
                    placeholder="Escribí un nuevo mensaje" 
                    type="text"
                    id="text-field"
                    onKeyPress={ this.props.onKeyPress } 
                />
                <label htmlFor="text-field" onClick={this.props.onSend} className={ this.props.message === '' ?  'disabled' : ''}>
                    <i className="fa fa-chevron-right" aria-hidden="true">Enviar Mensaje</i>
                </label>
            </div>
        );
    }
    
    render() {
        return (
            this.renderTextField()
        );
    }
}
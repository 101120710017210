import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
    key: 'root',
    storage: localForage,
    blacklist: ['auth', 'ui', 'location', 'medals', 'help', 'profile']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(sagaMiddleware)
    )
);

const persistor = persistStore(store);

export default store;
export { persistor };

sagaMiddleware.run(rootSaga);
import React from 'react';
import Afinidad from './Afinidad';

export default class Afinidades extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      affinitiesToShow: 2,
      expanded: false
    };

    this.showMore = this.showMore.bind(this);
  } 

  renderAffinities() {
    return this.props.affinities.slice(0, this.state.affinitiesToShow).map(affinity => {
      return (
        <Afinidad
          key={affinity.id}
          affinity={affinity}
        >
        </Afinidad>
      );
    });
  }

  showMore() {
    !this.state.expanded ? 
      this.setState({
        affinitiesToShow: this.props.affinities.length,
        expanded: true
      }) :
      this.setState({
        affinitiesToShow: 2,
        expanded: false
      })
  }
  
  render() {
    return (
      <ul className="affinities-container flex flex-center justify-start">
        {this.renderAffinities()}
        <li className={this.props.affinities.length > 2 ? 'active' : null} onClick={this.showMore}>{!this.state.expanded ? `Ver todas (${this.props.affinities.length})` : 'Ver menos'}</li>
      </ul>
    );
  }
}
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './components/App/App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store/store';
import Spinner from './components/ui/Spinner';

const app = (
    <Provider store={ store }>
        <PersistGate loading={<Spinner />} persistor={ persistor }>
            <App />
        </PersistGate>
    </Provider>
);

const rootElement = document.getElementById('root');
ReactDOM.render(app, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();

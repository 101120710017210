import React, {Component} from 'react';

class ErrorBoundary extends Component {
    state = {
        hasError: false,
        errorMessage: ''
    }

    componentDidCatch = (error,info) => {
        this.setState({hasError:error,errorMessage:info});
    }


    render() {
        if (this.state.hasError){
            return <h1>El error es el siguiente: {this.state.errorMessage}</h1>;
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;
import React from 'react';
import { Link } from 'react-router-dom';
import AfinidadIndicador from './AfinidadIndicador';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const eventoBlock = props => {
    if (props.state === 'Suspendido' || props.state === 'Eliminado') {
        return null;
    }
    return (
        <Link to={`/eventos/${ props.id }`} className="blockLink">
            <div className="EventoBlock">
                <figure style={{backgroundImage: `url(${props.imgPrincipal})`}}></figure>
                <div className="EventoBlockContent">
                    <h3>{ props.Evento }</h3>
                    <p className="flex">{props.empresa}
                        <div className="VerMas flex flex-center">
                            <span>Ver + </span><i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </div> 
                        <span className="job-span-location"><i><img src={props.imgPin} alt="Pin"/></i>{props.direccion}</span>
                    </p>
                    <p>{props.fecha_evento}</p>
                    <Tooltip placement="right" mouseEnterDelay={0.5} trigger={['hover']} overlay={<span>La afinidad compara tus intereses con los temas del evento. Los eventos que presentan más círculos llenos, podrían ser los de mayor interés para vos.</span>}>
                        <p className="afinidad flex flex-center justify-start">Tu afinidad con el evento: 
                            <AfinidadIndicador afinidad={props.afinidad} />
                        </p>
                    </Tooltip> 
                </div>
            </div>
        </Link>
    );
};

export default eventoBlock;

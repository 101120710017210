import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import { fetchMessages, sendMessage } from '../../store/actions';
import { BackButton, Spinner } from '../ui';
import Mensaje from './Mensaje'
import CampoTexto from './CampoTexto'
import './Mensajes.css'

class Conversacion extends Component {

    state = {
        messagesToShow: -3,
        messageText: '',
    }
    
    componentDidMount() {
        this.requestMessages();
        this.messagesInterval = setInterval(this.requestMessages, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.messagesInterval);
    }

    requestMessages = () => {
        this.props.onFetchData(
            this.props.match.params.id,
            () => this.props.history.replace('/mensajes')
        );
    }

    handleMessage = e => {
        this.setState({ messageText: e.target.value })
    }

    showMoreMessages = () => {
        this.setState(prevState => {
            return {
                messagesToShow: (Math.abs(prevState.messagesToShow) + 5) >= this.props.messages.length 
                ? -Math.abs(this.props.messages.length) 
                : prevState.messagesToShow -= 5
            }
        })
    }

    sendMessageHandler = () => {
        const messageText = this.state.messageText;
        if (messageText !== '') {
            this.props.onSendMessage(
                this.props.match.params.id,
                messageText
            )
            this.setState({ messageText: '' });
        }
    }

    renderMessages = () => {
        // return this.props.location.state.slice(this.state.messagesToShow).map(message => {
        return this.props.messages.slice(this.state.messagesToShow).map((message, index) => {
            return (
                <Mensaje
                    key={ index }
                    author={message.from}
                    content={message.message}
                    date={message.date}    
                    image={ message.image }
                />
            )
        })
    }

    render() {
        const { messages } = this.props;
        let content = (
            <div className="container messages-container inside-conversation">
                <div className="conversations-container show">
                    <div 
                        onClick={this.showMoreMessages} 
                        className={(Math.abs(this.state.messagesToShow) >= messages.length) ? "show-more-messages hide" : "show-more-messages"}
                        >
                        { messages.length + this.state.messagesToShow } mensajes más 
                        <span>+</span>
                    </div>
                    {this.renderMessages()}
                    {/* { isLoading && <Spinner /> } */}
                </div>
                <CampoTexto 
                    handler={this.handleMessage}
                    message={this.state.messageText}
                    onSend={ this.sendMessageHandler }
                    // onKeyPress={event => {
                    //     if (event.key === 'Enter') {
                    //         event.preventDefault();
                    //         this.sendMessageHandler();
                    //     }
                    // }}
                />
            </div>
        )
        return (
            <div>
                <TopTools />
                <BackButton />
                {content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        messages: state.messaging.messages,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: (conversationId, errorCallback) => dispatch(fetchMessages(conversationId, errorCallback)),
        onSendMessage: (conversationId, message) => dispatch(sendMessage(conversationId, message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conversacion);

// Render Prop
import React, { Component } from 'react';
import { connect } from 'react-redux';
import imgUsuario from './../../assets/images/usuario.png';
import imgCandadoCerrado from './../../assets/images/contrasena.png';
import imgCandadoAbierto from './../../assets/images/open-padlock.png';
import {Redirect} from "react-router-dom";
import { login } from '../../store/actions';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const cssUsuario = {
  backgroundImage: 'url('+ imgUsuario + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '15px',
}
const cssContrasena = {
  backgroundImage: 'url('+ imgCandadoCerrado + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '15px',
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 18,
  },
}))(Tooltip);

class Login extends Component {

  constructor() {
    super();
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
  }

  handleButtonPress() {
    this.showPassTimer = setTimeout(()=>{
      this.setState({ hidden: false });
      this.hidePassTimer = setTimeout(()=>{
        this.setState({ hidden: true });
        clearTimeout(this.hidePassTimer);
      },3000);
    },1500);
  }

  handleButtonRelease() {
    clearTimeout(this.showPassTimer);
  }
 
  state = {
      username: '',
      password: '',
      hidden: true,
      passwordInputTooltipText: 'Al mantener presionado el botón izquierdo del mouse puedes observar la contraseña'
  }

  formHandler = (evt) => {
    evt.preventDefault();
    this.props.onLogin(this.state.username, this.state.password);
  }

  handleUserChange = (evt) => {
      this.setState({username: evt.target.value});
  }

  handlePassChange = (evt) => {
      this.setState({password: evt.target.value});
  }

  render(){

    if (this.props.isAuth){
        return <Redirect to='/' />;
    }
    else { 

      return (
        <div>
          <form className="login-form" onSubmit={this.formHandler}>
            <label for="username" className="login-label">
              Correo Electr&oacute;nico
            </label>
            <input
                style={cssUsuario}
                value={this.state.username}
                onChange={this.handleUserChange}
                type="text"
                name="email"
                className="form-control"
                id="username"  
                autoComplete="email"
              />


              <LightTooltip title={this.state.passwordInputTooltipText} enterDelay={500} leaveDelay={200}>

                <div
                  onTouchStart={this.handleButtonPress} 
                  onTouchEnd={this.handleButtonRelease} 
                  onMouseDown={this.handleButtonPress} 
                  onMouseUp={this.handleButtonRelease} 
                  onMouseLeave={this.handleButtonRelease}
                  
                >
                   <label for="password" className="login-label">
                    Contrase&ntilde;a
                  </label>
                  <input
                      value={this.state.password}
                      onChange={this.handlePassChange}
                      onMouseEnter={this.showToolTipPass}
                      name="password"
                      className="form-control"
                      style={{
                        backgroundImage: this.state.hidden ? 'url('+ imgCandadoCerrado + ')' : 'url('+ imgCandadoAbierto + ')' ,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '15px',
                      }}
                      type={this.state.hidden ? "password" : "text"}
                      id="password" 
                      autoComplete="current-password"
                  />
                </div>
              </LightTooltip>
            
              <input type="submit" value="INICIAR SESIÓN" className="btn btn-block btn-lg btn-primary" />
            <div className="mensajeError">{this.props.error}</div>
          </form>
        </div>);
    }
  }
}

const mapStateToProps = state => {
  return {
      isLoading: state.ui.isLoading,
      isAuth: state.auth.isAuthenticated,
      error: state.auth.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLogin: (emailOrId, password) => dispatch(login(emailOrId, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EditarExperiencia extends Component {

    onAddNew = () => {
        if(this.props.position_company[this.props.position_company.length - 1] == ''){
            return;
        };
        let position = {
            'position_company': [ ...this.props.position_company, '' ],
            'position_position': [ ...this.props.position_position, '' ],
            'position_years': [ ...this.props.position_years, '' ],
            'position_description': [ ...this.props.position_description, '' ]
        }
        this.props.onUpdate(position);
    }

    onRemove = index => {
        const updatedCompanies = this.props.position_company.filter((item, i) => index !== i);
        const updatedPositions = this.props.position_position.filter((item, i) => index !== i);
        const updatedYears = this.props.position_years.filter((item, i) => index !== i);
        const updatedDescriptions = this.props.position_description.filter((item, i) => index !== i);

        let position = {
            'position_company': updatedCompanies,
            'position_position': updatedPositions,
            'position_years': updatedYears,
            'position_description': updatedDescriptions
        }
        this.props.onUpdate(position);
    }

    onEdit = (event, index) => {
        const updatedArray = this.props[event.target.id].map((item, i) => {
            if (index === i) {
                return event.target.value;
            }
            return item;
        });
        
        let updatedProperty = {};
        updatedProperty[event.target.id] = updatedArray;
        this.props.onUpdate(updatedProperty);
    }

    render() {
        return (
            <>
                { this.props.position_company != [] ? this.props.position_company.map((item, index) => (
                    <div key={ index } className="registroNuevoIdioma">
                    <p className="add-language-button" onClick={() => this.onRemove(index) }>- Eliminar experiencia</p>
                        <input
                            type="text" 
                            className="ctlLenguaje"
                            placeholder="Nombre de la empresa" 
                            id="position_company" 
                            onChange={ e => this.onEdit(e, index) } 
                            value={ item }
                        >
                        </input>
                        <input
                            type="text" 
                            className="ctlLenguaje"
                            placeholder="Puesto" 
                            id="position_position" 
                            onChange={ e => this.onEdit(e, index) } 
                            value={ this.props.position_position[index] }
                        >
                        </input>
                        <input
                            type="number" 
                            className="ctlLenguaje"
                            placeholder="Meses en la empresa" 
                            id="position_years" 
                            onChange={ e => this.onEdit(e, index) } 
                            value={ this.props.position_years[index] }
                        >
                        </input>
                        <input
                            type="text" 
                            className="ctlLenguaje"
                            placeholder="Descripción" 
                            id="position_description" 
                            onChange={ e => this.onEdit(e, index) } 
                            value={ this.props.position_description[index] }
                        >
                        </input>
                        
                        <hr></hr>
                    </div>
                )) : null }
                <p className="add-language-button" onClick={ this.onAddNew }>+ Agregar experiencia</p>
            </>
        );
    }
}

EditarExperiencia.propTypes = {
    position_company: PropTypes.array.isRequired,
    position_position: PropTypes.array.isRequired,
    position_years: PropTypes.array.isRequired,
    position_description: PropTypes.array.isRequired
};

export default EditarExperiencia;
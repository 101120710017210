import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Centered } from '../ui';
import questionSrc from '../../assets/images/conversation.svg';
import Stars from '../ui/Stars';
import classes from './Modal.module.css';

//
//This component is exclusively used to rate the companies.
//
class RateModal extends Component {

    state = {
        stillWorking: true,
        rating: -1,
        step: 1
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }
    
    changeWorkingStatus = event => {
        const stillWorking = event.target.value === 'true' ? true : false;
        this.setState({ stillWorking });
    }

    setRating = val => this.setState({ rating: val });

    onNext = () => this.setState({ step: 2 })

    onFinish = withRating => {
        const data = {
            continue: this.state.stillWorking,
            stars: withRating ? this.state.rating : -1,
            id: this.props.id
        }

        this.props.onSendData(data)
    }
    
    render() {
        let content = (
            <>
                <Centered>
                    <img src={ questionSrc } alt="header" />
                    <br />
                    <p>{ this.props.text }</p>
                </Centered>
                <div className={ classes.optionsWrapper }>
                    <label>
                        <input 
                            type="radio"
                            name="stillWorking"
                            value={ true }
                            id="option1"
                            checked={ this.state.stillWorking === true }
                            onChange={ this.changeWorkingStatus }
                        />
                        Sigo trabajando ahí
                    </label>
                    <label>
                        <input 
                            type="radio"
                            name="stillWorking"
                            value={ false }
                            id="option2"
                            checked={ this.state.stillWorking === false }
                            onChange={ this.changeWorkingStatus }
                        />
                        Ya no estoy trabajando ahí
                    </label>
                    
                </div>
                <div className={ classes.buttonsContainer }>
                    <button onClick={ this.onNext } >
                       Confirmar
                    </button>
                </div>
            </>
        );

        if (this.state.step === 2) {
            content = (
                <>
                    <Centered>
                        <img src={ questionSrc } alt="header" />
                        <h1>¿Qué calificación le darías a la empresa?</h1>                        
                    </Centered>
                    <Stars 
                        onChange={ this.setRating }
                        value={ this.state.rating }
                    />
                    <div className={ classes.buttonsContainer }>
                        <button onClick={() => this.onFinish(false) } >
                            Omitir
                        </button>
                        <button onClick={() => this.onFinish(true) } >
                            Confirmar
                        </button>
                    </div>
                </>
            );
        }
        return (
            <div className={ classes.backdrop }>
                <div className={ classes.modal }>
                    { content }
                </div>
            </div>
        );
    }
}

RateModal.propTypes = {
    onSendData: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
};

export default RateModal;

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';
import authReducer from './auth';
import uiReducer from './ui';
import puestosReducer from './puestos';
import locationReducer from './location';
import eventsReducer from './events';
import medalsReducer from './medals';
import helpReducer from './help';
import profileReducer from './profile';
import messagingReducer from './messaging';

const authPersistConfig = {
    key: 'auth',
    storage: localForage,
    blacklist: ['registrationData', 'isSignupSuccessful', 'error']
}

const profilePersistConfig = {
    key: 'profile',
    storage: localForage,
    blacklist: ['profileData']
}

export default combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    ui: uiReducer,
    puestos: puestosReducer,
    location: locationReducer,
    events: eventsReducer,
    medals: medalsReducer,
    help: helpReducer,
    profile: persistReducer(profilePersistConfig, profileReducer),
    messaging: messagingReducer
});
import React from 'react';
import { connect } from 'react-redux';
import { Centered } from '../ui';
import approvedSrc from '../../assets/images/approved.svg';
import questionSrc from '../../assets/images/conversation.svg';
import medalSrc from '../../assets/images/medalla-large.png';
import classes from './Modal.module.css';
import { uiHideModal } from '../../store/actions';

const Modal = props => {
    if (!props.showModal) {
        return null;
    }
    const imageSrc = getImageSrc(props.variant);
    window.scrollTo(0,0);
    return (
        <div className={ classes.backdrop }>
            <div className={ classes.modal }>
                <Centered>
                    { imageSrc && (
                        <img src={ imageSrc } alt="header" />
                    )}
                    <h1>{ props.title }</h1>
                    <p>{ props.text } </p>
                    <div className={ classes.buttonsContainer }>
                        { props.variant === 2 && (
                            <button 
                                onClick={() => {
                                    if (props.secondButtonAction) {
                                        props.secondButtonAction();
                                    }
                                    props.onClose()
                                }}
                                className={ classes.secondary }
                            >
                                { props.secondButtonText }
                            </button>
                        )}
                        <button 
                            onClick={() => {
                                if (props.firstButtonAction) {
                                    props.firstButtonAction();
                                }
                                props.onClose()
                            }}
                        >
                            { props.firstButtonText}
                        </button>
                        
                    </div>
                </Centered>
            </div>
        </div>
    );
};

const getImageSrc = type => {
    switch(type) {
        case 1: return approvedSrc;
        case 2: return questionSrc;
        case 3: return medalSrc;
        default: return null;
    }
}

const mapStateToProps = state => {
    return {
        showModal: state.ui.showModal,
        isLoading: state.ui.isLoading,
        title: state.ui.modalTitle,
        text: state.ui.modalText,
        variant: state.ui.modalVariant,
        firstButtonText: state.ui.modalFirstButtonText,
        secondButtonText: state.ui.modalSecondButtonText,
        firstButtonAction: state.ui.modalFirstButtonAction,
        secondButtonAction: state.ui.modalSecondButtonAction,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClose: () => dispatch(uiHideModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

import React, { Component } from 'react';
import classes from './Filtros.module.css';
import closeImg from '../../../assets/images/filter-symbol.svg';
import interesImg from '../../../assets/images/interes.svg';
import pinImg from '../../../assets/images/pin-negro.png';
import clockImg from '../../../assets/images/icon-time.png';
import briefCaseImg from '../../../assets/images/briefcase.svg';
import schoolImg from '../../../assets/images/school.svg';
import bookImg from '../../../assets/images/book.svg';
import { Range } from 'rc-slider';
import Select from 'react-select';
import 'rc-slider/assets/index.css';


class Filtros extends Component {

    state = {
        showNearOffersFirst: false,
        distance: [0, 50],
        selectedProvinces: [],
        selectedJobDays: [],
        selectedSectors: [],
        initialCall: true,
        degrees: [],
        careers: [],
        experience_from: '',
        experience_to: ''
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data && state.initialCall) {
            const updatedState = {
                showNearOffersFirst: props.data.my_province === 1,
                initialCall: false
            }
            if (typeof props.data.distance_filter_from !== 'undefined' && typeof props.data.distance_filter_to !== 'undefined') {
                updatedState.distance = [props.data.distance_filter_from, props.data.distance_filter_to]
            }
            if (props.data.province_filter && props.filters.provinces) {
                updatedState.selectedProvinces = props.data.province_filter.map(item => props.filters.provinces.find(p => p.id === item));
            }
            if (props.data.sector_filter && props.filters.sectors) {
                updatedState.selectedSectors = props.data.sector_filter.map(item => props.filters.sectors.find(s => s.id === item));
            }
            if (props.data.jobDay_filter && props.filters.job_days) {
                updatedState.selectedJobDays = props.data.jobDay_filter.map(item => props.filters.job_days.find(jd => jd.id === item));
            }
            if (props.data.degrees && props.filters.lastAcademicDegrees) {
                updatedState.degrees = props.data.degrees.map(item => ({
                    value: item,
                    label: props.filters.lastAcademicDegrees.find(ad => ad.id === item).name
                }));
            }

            if (props.data.careers && props.filters.careers) {
                updatedState.careers = props.data.careers.map(item => ({
                    value: item,
                    label: props.filters.careers.find(c => c.id === item).name
                }));
            }

            if (props.data.experience_from) {
                updatedState.experience_from = props.data.experience_from;
            }
            if (props.data.experience_to) {
                updatedState.experience_to = props.data.experience_to;
            }
            return updatedState;
        }
        return null;
    }

   
    inputChangedHandler = event => {
        const key = event.target.id;
        const value = key === 'showNearOffersFirst' ? event.target.checked : event.target.value
        this.setState({
            [key]: value
        })
    }
   
    selectItemHandler = (array, item) => {
        const updatedArray = [ ...this.state[array], item ];
        this.setState({
            [array]: updatedArray
        })
    }

    removeItemHandler = (array, id) => {
        const updatedArray = this.state[array].filter(item => item.id !== id);
        this.setState({
            [array]: updatedArray
        })
    }

    applyFilterHandler = () => {
        const filters = {};
        
        if (this.state.selectedProvinces.length) {
            filters.province_filter = this.state.selectedProvinces.map(item => item.id);
        }
        if (this.state.selectedJobDays.length) {
            filters.jobDay_filter = this.state.selectedJobDays.map(item => item.id);
        }
        if (this.state.selectedSectors.length) {
            filters.sector_filter = this.state.selectedSectors.map(item => item.id);
        }
        if (this.state.degrees.length) {
            filters.degrees = this.state.degrees.map(item => item.value);
        }
        if (this.state.careers.length) {
            filters.careers = this.state.careers.map(item => item.value);
        }
        if (this.state.experience_from !== '') {
            filters.experience_from = this.state.experience_from;
        }
        if (this.state.experience_to !== '') {
            filters.experience_to = this.state.experience_to;
        }
        
        filters.distance_filter_from = this.state.distance[0];
        filters.distance_filter_to = this.state.distance[1];
        filters.my_province = this.state.showNearOffersFirst ? 1 : 0;

        this.props.onFilter(filters)
        this.props.onClose();
    }

    render() {
        const { filters } = this.props;
        let provinces, jobDays, sectors, academicDegrees, careers = null;
        if (filters) {
            provinces = filters.provinces.map(item => {
                const isSelected = this.state.selectedProvinces.includes(item);
                return (
                    <div 
                        key={ item.id } 
                        className={ [classes.chip, isSelected ? classes.selected : null].join(' ') }
                        onClick={ () => isSelected ? this.removeItemHandler('selectedProvinces', item.id) : this.selectItemHandler('selectedProvinces' ,item) }
                    >
                        <span>{ item.name }</span>
                    </div>
                );
            });

            jobDays = filters.job_days.map(item => {
                const isSelected = this.state.selectedJobDays.includes(item);
                return (
                    <div 
                        key={ item.id } 
                        className={ [classes.chip, isSelected ? classes.selected : null].join(' ') }
                        onClick={ () => isSelected ? this.removeItemHandler('selectedJobDays', item.id) : this.selectItemHandler('selectedJobDays' ,item) }
                    >
                        <span>{ item.title }</span>
                    </div>
                );
            });

            sectors = filters.sectors.map(item => {
                const isSelected = this.state.selectedSectors.includes(item);
                return (
                    <div 
                        key={ item.id } 
                        className={ [classes.chip, isSelected ? classes.selected : null].join(' ') }
                        onClick={ () => isSelected ? this.removeItemHandler('selectedSectors', item.id) : this.selectItemHandler('selectedSectors' ,item) }
                    >
                        <span>{ item.name }</span>
                    </div>
                );
            });

            academicDegrees = filters.lastAcademicDegrees.map(item => ({label: item.name, value: item.id }));
            
            careers = filters.careers.map(item => ({label: item.name, value: item.id }));
        }

        return (
            <div className={ classes.backdrop } onClick={ this.props.onClose }>
                <div className={ classes.modal } onClick={ e => e.stopPropagation() }>
                    <div className={ `${classes.header} flex`}>
                        <h2>Filtros</h2>
                        <div className={classes.closeFiltersX} onClick={ this.props.onClose }>
                            <img src={ closeImg } alt="cerrar" className={ classes.close }  />
                        </div>
                    </div> 
                    <div className={ classes.content }>
                        <label className="near-me">
                            <input 
                                id="showNearOffersFirst"
                                type="checkbox" 
                                value={ this.state.showNearOffersFirst } 
                                onChange={ this.inputChangedHandler }
                                defaultChecked={ this.state.showNearOffersFirst }
                            />
                            Mostrar primero las ofertas cerca del lugar donde vivo
                        </label>
                        <section>
                            <img src={ pinImg } className={ classes.sectionIcon } alt="pin" />
                            <h3>Ubicación</h3>
                            <div className={ classes.chipsWrapper }>
                                { provinces }
                            </div>
                        </section>
                        <section>
                            <img src={ pinImg } className={ classes.sectionIcon } alt="pin" />
                            <h3>Distancia de viaje</h3>
                            <div className={ classes.sliderContainer }>
                                {/* <p>{ this.state.distance === '0' ? '-' : this.state.distance === '50' ? '+50' : this.state.distance } km</p> */}
                                <p>De { this.state.distance[0] }km a { this.state.distance[1] }km </p>
                                {/* <input 
                                    id="distance"
                                    type="range" 
                                    min="0" 
                                    max="50"
                                    value={ this.state.distance } 
                                    onChange={ this.inputChangedHandler }
                                /> */}
                                <Range 
                                    id="distance"
                                    min={ 0 }
                                    max={ 50 }
                                    value={ this.state.distance } 
                                    onChange={value => this.inputChangedHandler({ target: { id: 'distance', value: value }}) }
                                    handleStyle={[{ backgroundColor: '#FFFFFF', borderColor: '#A4A2A2' }, { backgroundColor: '#FFFFFF', borderColor: '#A4A2A2' }]}
                                    railStyle={{ backgroundColor: '#A4A2A2' }}
                                    trackStyle={[{ backgroundColor: '#F2C714' }]}
                                />

                                <div className={ classes.sliderLabels }>
                                    <span>1km</span>
                                    <span>+50km</span>
                                </div>
                            </div>
                        </section>
                        <section>
                            <img src={ interesImg } className={ classes.sectionIcon } alt="sector de interes" />
                            <h3>Sector de interés</h3>
                            <div className={ classes.chipsWrapper }>
                                { sectors }
                            </div>
                        </section>
                        <section>
                            <img src={ schoolImg } className={ classes.sectionIcon } alt="grados académicos" />
                            <h3>Grados académicos</h3>
                            <div >
                                <Select 
                                    options={ academicDegrees } 
                                    isMulti
                                    inputId="degrees"
                                    placeholder=""
                                    value={ this.state.degrees }
                                    defaultValue={ this.state.degrees }
                                    onChange={ (selectedOptions) => this.setState({ degrees: selectedOptions }) }
                                    noOptionsMessage={() => null}
                                />
                            </div>
                        </section>
                        <section>
                            <img src={ bookImg } className={ classes.sectionIcon } alt="Carreras" />
                            <h3>Carreras</h3>
                            <div >
                                <Select 
                                    options={ careers } 
                                    isMulti
                                    inputId="careers"
                                    placeholder=""
                                    value={ this.state.careers }
                                    defaultValue={ this.state.careers }
                                    onChange={ (selectedOptions) => this.setState({ careers: selectedOptions }) }
                                    noOptionsMessage={() => null}
                                />
                            </div>
                        </section>
                        <section>
                            <img src={ briefCaseImg } className={ classes.sectionIcon } alt="Experiencia" />
                            <h3>Experiencia laboral</h3>
                            <div className={ classes.experienceContainer }>
                                <div>
                                    De:
                                    <input
                                        type="number" 
                                        min="0"
                                        id="experience_from" 
                                        onChange={ this.inputChangedHandler } 
                                        value={ this.state.experience_from }
                                    />
                                </div>
                                <div>
                                    Hasta:
                                    <input
                                        type="number" 
                                        min="0"
                                        id="experience_to" 
                                        onChange={ this.inputChangedHandler } 
                                        value={ this.state.experience_to }
                                    />
                                    años
                                </div>
                            </div>
                        </section>
                        <section>
                            <img src={ clockImg } className={ classes.sectionIcon } alt="reloj" />
                            <h3>Jornadas</h3>
                            <div className={ classes.chipsWrapper }>
                                { jobDays }
                            </div>
                        </section>
                        <button className={ classes.button } onClick={ this.applyFilterHandler }>Filtrar</button>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default Filtros;
import React from "react";
import Slider from "react-slick";
import {Link} from "react-router-dom";
import "./Bienvenida.css";
import imgBienvenida1 from "./../../assets/images/welcome_image_1.png";
import imgBienvenida2 from "./../../assets/images/welcome_image_2.png";
import imgBienvenida3 from "./../../assets/images/welcome_image_3.png";

export default class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'slider',
      autoplay: true,
      autoplaySpeed: 5000,
    };
    return (
        <div className="Bienvenida">
            <Slider {...settings}>
                <div>
                <img src={imgBienvenida1} alt="Bienvenida" />
                <h1>Bienvenido</h1>
                <p>Muchas gracias por darnos la oportunidad de mejorar tu condición de vida. Esta plataforma, creada por la Fundación Acción Joven, está pensada para que siempre tengás a mano los trabajos disponibles en tu zona de residencia.</p>
                </div>
                <div>
                <img src={imgBienvenida2} alt="Oportunidades" />
                <h1>Grandes Oportunidades</h1>
                <p>Te mostraremos puestos de trabajo publicados por distintas empresas que desean darle a los jóvenes una oportunidad para desarrollar sus capacidades y ganar experiencia.</p>
                </div>
                <div>
                <img src={imgBienvenida3} alt="Crezcamos" />
                <h1>Crezcamos Juntos</h1>
                <p>La plataforma incluye, además, cuáles son los eventos disponibles en la zona para que podás asistir y ganar experiencia en diversas áreas de interés para las empresas que contratan trabajadores.</p>
                </div>
            </Slider>
            <Link className="comenzar" to="/user/login">COMENCEMOS</Link>
      </div>
    );
  }
}
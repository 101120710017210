import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { Link } from 'react-router-dom';
import { fetchCaseData, openCase } from '../../store/actions';
import { BackButton, Spinner } from '../ui';
import { Redirect } from 'react-router-dom';

//Images

import interview from '../../assets/images/help1.png'
import visit from '../../assets/images/help4.png'
import analisis from '../../assets/images/help3.png'
import solucion from '../../assets/images/help5.png'

import './Ayuda.css'

class Orientacion extends Component {

    getCurrentType = () => {
        return this.props.caseTypes.find(item => item.id === this.props.match.params.id);
    }

    openCaseHandler = () => {
        this.props.onOpenCase(
            this.props.match.params.id,
            () => this.props.history.replace('/ayuda')
        )
    }

    render() {
        const { canApply, isLoading } = this.props;
        const currentType = this.getCurrentType();
        if (!canApply || !currentType) {
            return <Redirect to="/ayuda" />;
        }
        let content = null;
        if (isLoading) {
            content = <Spinner />
        } else {
            content = (
                <div className="container help-container sub-view">
                    <h4>{ currentType.name }</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore tempor incididunt ut labore et dolore.</p>
                    <h4>Procedimiento: </h4>
                    <div className="help-steps">
                        <div className="step flex justify-start flex-center nowrap">
                            <figure>
                                <img src={interview} alt="vinculacion" />
                            </figure>
                            <div>
                                <h5>1. Vinculación con un promotor</h5>
                            </div>
                        </div>
                        <div className="step flex justify-start flex-center nowrap">
                            <figure>
                                <img src={analisis} alt="estudio" />
                            </figure>
                            <div>
                                <h5>2. Estudio del caso</h5>
                            </div>
                        </div>
                        <div className="step flex justify-start flex-center nowrap">
                            <figure>
                                <img src={visit} alt="visita" />
                            </figure>
                            <div>
                                <h5>3. Posible visita</h5>
                            </div>
                        </div>
                        <div className="step flex justify-start flex-center nowrap">
                            <figure>
                                <img src={solucion} alt="solucion" />
                            </figure>
                            <div>
                                <h5>4. Generación de solución</h5>
                            </div>
                        </div>
                    </div>
                    <button className="principal-button" onClick={ this.openCaseHandler }>Solicitar asistencia</button>
                </div>
            );
        }
        
        return (
            <div>
                <TopTools />
                <BackButton />
                {content}
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        canApply: state.help.canApply,
        caseTypes: state.help.caseTypes,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchCaseTypes: () => dispatch(fetchCaseData()),
        onOpenCase: (id, callback) => dispatch(openCase(id, callback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orientacion);

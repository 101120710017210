import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { BackButton, Spinner } from '../ui';
import { reportPuesto, uiShowModal } from '../../store/actions';

class PuestoReportar extends Component {

    state = {
        justification: ''
    }

    changeHandler = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    reportJobHandler = event => {
        event.preventDefault();
        const id = this.props.match.params.id;
        const justification = this.state.justification;
        
        this.props.onReportJob(
            id, 
            justification, 
            () => this.props.history.replace('/puestos')
        );
    }

    render() {
        let content = null;
        if (this.props.isLoading) {
            content = (
                <Spinner />
            );
        } else {
            content = (
                <div className="container help-container sub-view report-job">
                    <h4>Reportar puesto: Justificación opcional</h4>
                    <p>Al reportar un puesto se le notificará a la empresa y este será evaluado por los encargados de la plataforma.</p>
                    <form onSubmit={ this.reportJobHandler }>
                        <textarea 
                            id="justification" 
                            rows="2"
                            value={ this.state.justification }
                            onChange={ this.changeHandler }
                            placeholder="Explica porque querés reportar el puesto"
                        />
                        <hr />
                        <button className="principal-button">Reportar</button>
                    </form>
                </div>
            )
        }
        return (
            <div>
                <TopTools></TopTools>
                <BackButton />
                { content }
                <BottomTools></BottomTools>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onReportJob: (id, justification, callback) => dispatch(reportPuesto(id, justification, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PuestoReportar);
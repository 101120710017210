import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    showModal: false,
    modalTitle: '',
    modalText: '',
    modalVariant: 0,
    modalFirstButtonText: '',
    modalSecondButtonText: '',
    modalFirstButtonAction: null,
    modalSecondButtonAction: null,
}

const openModal = (state, action) => {
    return {
        ...state,
        showModal: true,
        modalTitle: action.title,
        modalText: action.text,
        modalVariant: action.variant,
        modalFirstButtonText: action.firstButtonText,
        modalSecondButtonText: action.secondButtonText,
        modalFirstButtonAction: action.firstButtonAction,
        modalSecondButtonAction: action.secondButtonAction,
    }
}

const closeModal = (state, action) => {
    return {
        ...state,
        showModal: false,
        modalTitle: '',
        modalText: '',
        modalVariant: 0,
        modalFirstButtonText: '',
        modalSecondButtonText: '',
        modalFirstButtonAction: null,
        modalSecondButtonAction: null,
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UI_START_LOADING: return { ...state, isLoading: true }
        case actionTypes.UI_STOP_LOADING: return { ...state, isLoading: false }
        case actionTypes.UI_SHOW_MODAL: return openModal(state, action)
        case actionTypes.UI_HIDE_MODAL: return closeModal(state, action)
        default: return state;
    }
}

export default reducer;

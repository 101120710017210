import * as actionTypes from './actionTypes';

export const fetchHelp = () => {
    return {
        type: actionTypes.FETCH_HELP,
    };
};

export const setHelp = data => {
    return {
        type: actionTypes.SET_HELP,
        data
    };
};

export const fetchCaseData = () => {
    return {
        type: actionTypes.FETCH_CASE_DATA,
    };
};

export const setCaseData = data => {
    return {
        type: actionTypes.SET_CASE_DATA,
        data
    };
};

export const openCase = (caseType, callback) => {
    return {
        type: actionTypes.OPEN_CASE,
        caseType,
        callback
    };
};

export const setCaseResult = data => {
    return {
        type: actionTypes.SET_CASE_RESULT,
        data
    };
};
import * as actionTypes from './actionTypes';


export const fetchProfileData = () => {
    return {
        type: actionTypes.FETCH_PROFILE_DATA
    };
};

export const setProfileData = data => {
    return {
        type: actionTypes.SET_PROFILE_DATA,
        data
    };
};

export const fetchEditProfileData = () => {
    return {
        type: actionTypes.FETCH_EDIT_PROFILE_DATA
    };
};

export const setEditProfileData = data => {
    return {
        type: actionTypes.SET_EDIT_PROFILE_DATA,
        data
    };
};

export const updateProfile = (data, image) => {
    return {
        type: actionTypes.UPDATE_PROFILE,
        data,
        image
    };
};
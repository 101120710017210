import * as actionTypes from './actionTypes';

export const fetchConversation = () => {
    return {
        type: actionTypes.FETCH_CONVERSATIONS
    };
};

export const setConversations = conversations => {
    return {
        type: actionTypes.SET_CONVERSATIONS,
        conversations
    };
};

export const fetchMessages = (conversationId, errorCallback) => {
    return {
        type: actionTypes.FETCH_MESSAGES,
        conversationId,
        errorCallback
    };
};

export const setMessages = messages => {
    return {
        type: actionTypes.SET_MESSAGES,
        messages
    };
};

export const sendMessage = (conversationId, message) => {
    return {
        type: actionTypes.SEND_MESSAGE,
        conversationId,
        message
    };
};

export const sendMessageToFAJ = (message, callback) => {
    return {
        type: actionTypes.SEND_MESSAGE_TO_FAJ,
        message,
        callback
    };
};
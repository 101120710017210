import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import styles from './styles.json';
import Marker from './Marker';

const API_KEY = 'AIzaSyC08zuB-lpT2hbPBpzMGmHoV8L-BriyOYc';

const DEFAULT_CENTER = {
    lat: 9.9325427,
    lng: -84.0795782
}

const DEFAULT_ZOOM = 11;

class Mapa extends Component {

    onLocationClicked = ({x, y, lat, lng, event}) => {
		this.props.onLocationClicked(lat, lng);
    } 

    render() {
        return (
            <div style={{ width:"100%", height:350 }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: API_KEY,
                        language: 'es'
                    }}
                    options={{
                        styles: styles,
                    }}
                    defaultCenter={DEFAULT_CENTER}
                    defaultZoom={DEFAULT_ZOOM}
					onClick={ this.onLocationClicked }
					center={{
						lat: this.props.latitude,
						lng: this.props.longitude
					}}
                >
				<Marker
					lat={ this.props.latitude }
					lng={ this.props.longitude }
				/>
                </GoogleMapReact>
            </div>
        );
    }
}

export default Mapa;
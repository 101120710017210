import * as actionTypes from '../actions/actionTypes';

const initialState = {
    profileData: null,
    editProfileData: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PROFILE_DATA: return { ...state, profileData: action.data };
        case actionTypes.SET_EDIT_PROFILE_DATA: return { ...state, editProfileData: action.data };
        default: return state;
    }
}

export default reducer;

import React from 'react';
import { connect } from 'react-redux';
import './TopTools.css';
import { logout } from '../../store/actions';
import { Link } from 'react-router-dom';

// Images
import sobre from './../../assets/images/notification.png';
import salir from './../../assets/images/exit.png';

const topTools = props => (
    <div className="topTools">
        <Link className="topMensajes" to="/mensajes"><img src={sobre} alt="Notificación" /></Link>
        <Link className="topSalir" to="/user/login" onClick={ props.onLogout }>SALIR <img src={salir} alt="Salir" /></Link>
    </div>
);

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch((logout()))
    }
}

export default connect(null, mapDispatchToProps)(topTools);
import * as actionTypes from '../actions/actionTypes';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    fetchRegistrationDataSaga,
    signupSaga,
    loginSaga,
    watchExpirationTimeSaga,
    refreshTokenSaga,
    sendForgotPasswordSaga,
    checkRegistrationDataSaga
} from './auth';

import {
    fetchPuestosSaga,
    applyFiltersSaga,
    fetchPuestoSaga,
    applyPuestoSaga,
    reportPuestoSaga,
    sendRatingSaga
} from './puestos';

import {
    fetchEventsSaga,
    applyEventsFiltersSaga,
    fetchEventSaga,
    toggleEventAssistanceSaga,
    reportEventSaga,
    sendEventCodeSaga
} from './events';

import {
    fetchCantonsSaga,
    fetchDistrictsSaga
} from './location';

import {
    fetchMedalsSaga,
} from './medals';

import {
    fetchHelpSaga,
    fetchCaseDataSaga,
    openCaseSaga
} from './help';

import {
    fetchEditProfileDataSaga,
    fetchProfileDataSaga,
    updateProfileSaga
} from './profile';

import {
    fetchConversationsSaga,
    fetchMessagesSaga,
    sendMessagesSaga,
    sendMessagesToFAJSaga
} from './messaging';

const authSagas = [
    takeEvery(actionTypes.SIGNUP_INIT, fetchRegistrationDataSaga),
    takeEvery(actionTypes.CHECK_REGISTRATION_DATA, checkRegistrationDataSaga),
    takeEvery(actionTypes.SIGNUP_START, signupSaga),
    takeEvery(actionTypes.LOGIN_START, loginSaga),
    takeLatest(actionTypes.WATCH_EXPIRATION_TIME, watchExpirationTimeSaga),
    takeLatest(actionTypes.REFRESH_TOKEN, refreshTokenSaga),
    takeLatest(actionTypes.SEND_FORGOT_PASSWORD, sendForgotPasswordSaga)
];

const profileSagas = [
    takeEvery(actionTypes.FETCH_PROFILE_DATA, fetchProfileDataSaga),
    takeEvery(actionTypes.FETCH_EDIT_PROFILE_DATA, fetchEditProfileDataSaga),
    takeLatest(actionTypes.UPDATE_PROFILE, updateProfileSaga),
];

const puestosSagas = [
    takeEvery(actionTypes.FETCH_PUESTOS, fetchPuestosSaga),
    takeLatest(actionTypes.APPLY_FILTERS, applyFiltersSaga),
    takeEvery(actionTypes.FETCH_PUESTO, fetchPuestoSaga),
    takeLatest(actionTypes.APPLY_PUESTO, applyPuestoSaga),
    takeLatest(actionTypes.REPORT_PUESTO, reportPuestoSaga),
    takeLatest(actionTypes.SEND_RATING, sendRatingSaga)
];

const eventsSagas = [
    takeEvery(actionTypes.FETCH_EVENTS, fetchEventsSaga),
    takeLatest(actionTypes.APPLY_EVENTS_FILTERS, applyEventsFiltersSaga),
    takeEvery(actionTypes.FETCH_EVENT, fetchEventSaga),
    takeLatest(actionTypes.TOGGLE_EVENT_ASISTANCE, toggleEventAssistanceSaga),
    takeLatest(actionTypes.REPORT_EVENT, reportEventSaga),
    takeLatest(actionTypes.SEND_EVENT_CODE, sendEventCodeSaga),
];

const locationsSagas = [
    takeEvery(actionTypes.FETCH_CANTONS, fetchCantonsSaga),
    takeEvery(actionTypes.FETCH_DISTRICTS, fetchDistrictsSaga)
];

const medalsSagas = [
    takeEvery(actionTypes.FETCH_MEDALS, fetchMedalsSaga),
];

const helpSagas = [
    takeEvery(actionTypes.FETCH_HELP, fetchHelpSaga),
    takeEvery(actionTypes.FETCH_CASE_DATA, fetchCaseDataSaga),
    takeLatest(actionTypes.OPEN_CASE, openCaseSaga)
];

const messagingSagas = [
    takeLatest(actionTypes.FETCH_CONVERSATIONS, fetchConversationsSaga),
    takeLatest(actionTypes.FETCH_MESSAGES, fetchMessagesSaga),
    takeLatest(actionTypes.SEND_MESSAGE, sendMessagesSaga),
    takeLatest(actionTypes.SEND_MESSAGE_TO_FAJ, sendMessagesToFAJSaga),
];

export default function* rootSaga() {
    yield all([
        ...authSagas,
        ...puestosSagas,
        ...eventsSagas,
        ...locationsSagas,
        ...medalsSagas,
        ...helpSagas,
        ...profileSagas,
        ...messagingSagas
    ]);
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { Link } from 'react-router-dom';
import { fetchHelp } from '../../store/actions';

//Images

import interview from '../../assets/images/help1.png'
import study from '../../assets/images/help2.png'
import visit from '../../assets/images/help3.png'

import './Ayuda.css'

class Ayuda extends Component {

    componentDidMount() {
        this.props.onFetchData();
    }
    
    render() {
        const { canApply, groups } = this.props;
        
        let groupsContent = null;
        if (groups) {
            groupsContent = (
                <div className="link-tabs-container">
                    { groups.map(item => (
                        <Link key={ item.id } className="flex" to={`/ayuda/${item.id}`} >
                            <p>{ item.name }</p>
                            <span>></span>
                        </Link>
                    ))}
                </div>
            )
        }

        let content = (
            <div className="container help-container">
                <h4>Preguntas frecuentes</h4>
                { groupsContent }
                {/* <div className="link-tabs-container">
                    <Link className="flex" to={`/ayuda/puestos`} >
                        <p>Pregunta sobre puestos</p>
                        <span>></span>
                    </Link>
                    <Link className="flex" to={`/ayuda/eventos`}>
                        <p>Pregunta sobre eventos</p>
                        <span>></span>
                    </Link>
                    <Link className="flex" to={`/ayuda/tips`}>
                        <p>Tips y consejos</p>
                        <span>></span>
                    </Link>
                </div> */}
                <h4>¿Necesitás asistencia?</h4>
                <p>Hemos creado esta plataforma porque queremos que encontrés un buen trabajo cerca de tu zona. Si tenés alguna situación o limitación que te impida aplicar a puestos de trabajo, contános tu caso, y buscaremos la manera de ayudarte de la siguiente forma:</p>
                <div className="help-steps">
                    <div className="step flex justify-start nowrap">
                        <figure>
                            <img src={interview} alt="entrevista" />
                        </figure>
                        <div>
                            <h5>1. Entrevista personal</h5>
                            <p>Uno de nuestros agentes te entrevistará para saber que necesitás.</p>
                        </div>
                    </div>
                    <div className="step flex justify-start nowrap">
                        <figure>
                            <img src={study} alt="estudio" />
                        </figure>
                        <div>
                            <h5>2. Estudio del caso</h5>
                            <p>Intentaremos encontrar la mejor solución para tu problema.</p>
                        </div>
                    </div>
                    <div className="step flex justify-start nowrap">
                        <figure>
                            <img src={visit} alt="visita" />
                        </figure>
                        <div>
                            <h5>3. Posible visita</h5>
                            <p>Dependiendo de tu situación necesitaremos realizar una visita después de haberte brindado asistencia.</p>
                        </div>
                    </div>
                </div>
                { canApply && (
                    <Link to={`/ayuda/asistencia`} className="principal-button">Solicitar asistencia</Link>
                )}
            </div>
            
        )
        return (
            <div>
                <TopTools />
                {content}
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        groups: state.help.groups,
        canApply: state.help.canApply,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchHelp()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ayuda);

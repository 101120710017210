import * as actionTypes from './actionTypes';

export const fetchMedals = () => {
    return {
        type: actionTypes.FETCH_MEDALS,
    };
};

export const setMedals = data => {
    return {
        type: actionTypes.SET_MEDALS,
        data
    };
};
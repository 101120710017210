import * as actionTypes from '../actions/actionTypes';

const initialState = {
    groups: [],
    canApply: false,
    caseTypes: []
};

const setHelpData = (state, action) => {
    return {
        ...state,
        groups: action.data.groups,
        canApply: action.data.can_apply,
    }
}

const setCaseData = (state, action) => {
    const caseTypes = [];

    for(let key in action.data.types) {
        caseTypes.push({
            id: key,
            name: action.data.types[key]
        });
    }

    return {
        ...state,
        caseTypes
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_HELP: return setHelpData(state, action);
        case actionTypes.SET_CASE_DATA: return setCaseData(state, action);
        default: return state;
    }
}

export default reducer;
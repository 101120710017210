import { put, select } from 'redux-saga/effects';
import axios from '../axios';
import * as actions from '../actions';
import * as selectors from './selectors';

export function* fetchEventsSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        // axios.defaults.headers.common['Authorization'] = 'Bearer '+ accessToken;
        // console.log(axios)
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/eventos', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setEvents(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error.response.data);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* applyEventsFiltersSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/eventos', action.filters ,{headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setEvents(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* fetchEventSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/evento/' + action.id, {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setEvent(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* toggleEventAssistanceSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/evento/' + action.id, {}, {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setEvent(response.data));

            if (response.data.hasEvent) {
                yield put(actions.uiShowModal(
                    'Te agregamos a la lista de jóvenes que asistirán al evento', 
                    'Al estar en la lista tenés tu espacio reservado. Si por alguna razón no podés asistir, ve a Mis eventos y al detalle del evento e indicá que no podés ir. Recordá las medallas se otorgan solamente si se asiste al evento.', 
                    1, 
                    'Cerrar', 
                    '', 
                    action.callback, 
                    null
                ));
            } else {
                yield put(actions.uiShowModal(
                    'Listo', 
                    'Se ha cancelado tu asistencia al evento', 
                    1, 
                    'Cerrar', 
                    '', 
                    action.callback, 
                    null
                ));
            }
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* reportEventSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/evento/reportar/' + action.id, {}, {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.eventReportedSuccess(action.id));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* sendEventCodeSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        const data = {
            code: parseInt(action.code)
        }
        try {
            const response = yield axios.post('/api/joven/evento/asistencia/' + action.eventId, data, {headers:{'Authorization': 'Bearer ' + accessToken}});
            if (response.data.success) {
                yield put(actions.uiShowModal(
                    'Has ganado una medalla', 
                    'Se va a agregar esta nueva habilidad a tu perfil.', 
                    3, 
                    'Aceptar', 
                    '', 
                    action.callback, 
                    null
                ));
            } else {
                yield put(actions.uiShowModal(
                    'Error', 
                    'El código que ingresaste no es correcto.', 
                    0, 
                    'Cerrar', 
                    '', 
                    null, 
                    null
                ));
            }
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiShowModal(
                'Error', 
                'El código que ingresaste no es correcto.', 
                0, 
                'Cerrar', 
                '', 
                null, 
                null
            ));
            yield put(actions.uiStopLoading());
        }
    }
}
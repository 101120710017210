import * as actionTypes from '../actions/actionTypes';

const initialState = {
    registrationData: null,
    accessToken: null,
    expiresIn: 0,
    expirationTime: null,
    tokenType: '',
    scope: null,
    refreshToken: null,
    isAuthenticated: false,
    error: null,
    isSignupSuccessful: false,
    uniqueDataSuccessful: false
}

const init = (state, action) => {
    return {
        ...state,
        error: null,
        isSignupSuccessful: false,
        uniqueDataSuccessful: false
    }
}

const uniqueDataSuccess = (state, action) => {
    return {
        ...state,
        uniqueDataSuccessful: true
    }
}

const setRegistrationData = (state, action) => {
    return {
        ...state, 
        registrationData: action.data
    }
}

const signup = (state, action) => {
    return {
        ...state,
        error: null,
        isSignupSuccessful: true
    }
}

const login = (state, action) => {
    const expirationTime = new Date().getTime() + (action.data.expires_in * 1000)
    return {
        ...state,
        accessToken: action.data.access_token,
        expiresIn: action.data.expires_in,
        expirationTime: expirationTime,
        tokenType: action.data.token_type,
        scope: action.data.scope,
        refreshToken: action.data.refresh_token,
        isAuthenticated: true,
        error: null,
    }
}

const logout = (state, action) => {
    return {
        ...state,
        ...initialState
    }
}

const authFailed = (state, action) => {
    return {
        ...state,
        error: action.error
    }
}

const clearErros = (state, action) => {
    return {
        ...state,
        error: null
    }
}


const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SIGNUP_INIT: return init(state, action);
        case actionTypes.SET_REGISTRATION_DATA: return setRegistrationData(state, action);
        case actionTypes.SIGNUP_SUCCESS: return signup(state, action);
        case actionTypes.REGISTRATION_DATA_SUCCESS: return uniqueDataSuccess(state, action);
        case actionTypes.LOGIN_SUCCESS: return login(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        case actionTypes.AUTH_FAILED: return authFailed(state, action);
        case actionTypes.CLEAR_ERRORS: return clearErros(state, action);
        default: return state;
    }
}

export default reducer;
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    jobs: [],
    opportunities: [],
    applies: [],
    continueWorking: [],
    data: null,
    filters: null,
    currentPuesto: null,
}

const setPuestos = (state, action) => {
    return {
        ...state,
        jobs: action.jobs,
        opportunities: action.opportunities,
        continueWorking: action.continueWorking,
        applies: action.applies,
        data: action.data,
        filters: action.filters
    }
}

const setPuesto = (state, action) => {
    return {
        ...state, 
        currentPuesto: action.puesto,
    }
}

const puestoApplied = (state, action) => {
    const updatedApplies = [
        ...state.applies,
        action.puesto
    ]
    return {
        ...state, 
        applies: updatedApplies
    }
}

const puestoReported = (state, action) => {
    const updatedJobs = state.jobs.filter(item => item.id !== action.id);
    return {
        ...state, 
        jobs: updatedJobs
    }
}

const logout = (state) => {
    return {
        ...state,
        ...initialState
    }
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_PUESTOS: return setPuestos(state, action);
        case actionTypes.SET_PUESTO: return setPuesto(state, action);
        case actionTypes.PUESTO_APPLIED: return puestoApplied(state, action);
        case actionTypes.PUESTO_REPORTED: return puestoReported(state, action);
        case actionTypes.LOGOUT: return logout(state);
        default: return state;
    }
}

export default reducer;
import React from 'react';

export default class Mensaje extends React.Component {
    renderMessage() {
        return (
            <div className="inside-message">
                <div className="flex conversation justify-start nowrap flex-center">
                    <figure style={{backgroundImage: `url(${ this.props.image })`}}></figure>
                    <div>
                        <div className="flex justify-start flex-center">
                            {/* <i className="fa fa-users" aria-hidden="true"></i> */}
                            <h4>{this.props.author}</h4>
                        </div>
                        <span>{this.props.date}</span>
                    </div>
                </div>
                <div className="message-text-preview" dangerouslySetInnerHTML={{__html: this.props.content}} />
                {/* <p className="message-text-preview">{this.props.content}</p> */}
            </div>
        );
    }
    
    render() {
        return (
            this.renderMessage()
        );
    }
}
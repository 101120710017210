import * as actionTypes from '../actions/actionTypes';

const initialState = {
    alerts: [],
    data: null,
    events: [],
    filters: null,
    myEvents: [],
    currentEvent: null
}

const setEvents = (state, action) => {
    return {
        ...state,
        alerts: action.alerts,
        data: action.data,
        events: action.events,
        filters: action.filters,
        myEvents: action.myEvents
    }
}

const setEvent = (state, action) => {
    const currentEvent = action.event;
    //
    //The purpose of this section is to improve the performance of the app by adding or removing manually the event to myEvents and
    //avoid the task to fetch from the api when the user returns to the list of events page, if there are to many bugs presented then
    //remove this part and always fetch from the api in the componentDidMount of the Eventos component.
    //In case of removing this the Event Reported action could also be removed.
    //
    let updatedEvents = state.events;
    let updatedMyEvents = state.myEvents;
    if (state.currentEvent && currentEvent.hasEvent !== state.currentEvent.hasEvent) {
        if (currentEvent.hasEvent) {
            updatedMyEvents = [
                ...state.myEvents,
                currentEvent.event
            ]
            updatedEvents = state.events.filter(item => item.id !== currentEvent.event.id);
        } else {
            updatedMyEvents = state.myEvents.filter(item => item.id !== currentEvent.event.id);
            updatedEvents = [
                ...state.events,
                currentEvent.event
            ];
        }
    }
    //
    return {
        ...state,
        currentEvent,
        myEvents: updatedMyEvents, //remove this in case of bugs,
        events: updatedEvents, //remove this in case of bugs,
    }
}

const removeReportedEvent = (state, action) => {
    const updatedEvents = state.events.filter(item => item.id !== action.id);
    return {
        ...state,
        events: updatedEvents
    }
}


const logout = state => {
    return {
        ...state,
        ...initialState
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_EVENTS: return setEvents(state, action);
        case actionTypes.SET_EVENT: return setEvent(state, action);
        case actionTypes.EVENT_REPORTED: return removeReportedEvent(state, action);
        case actionTypes.LOGOUT: return logout(state);
        default: return state;
    }
}

export default reducer;
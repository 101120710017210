import * as actionTypes from './actionTypes';

export const signupInit = () => {
    return {
        type: actionTypes.SIGNUP_INIT
    };
};

export const setRegistrationData = data => {
    return {
        type: actionTypes.SET_REGISTRATION_DATA,
        data
    };
};

export const checkRegistrationData = (id, email) => {
    return {
        type: actionTypes.CHECK_REGISTRATION_DATA,
        id,
        email
    };
};

export const registrationUniqueDataSuccess = (data) => {
    return {
        type: actionTypes.REGISTRATION_DATA_SUCCESS,
        data
    };
};

export const signup = (data, image) => {
    return {
        type: actionTypes.SIGNUP_START,
        data,
        image
    };
};

export const login = (emailOrId, password) => {
    return {
        type: actionTypes.LOGIN_START,
        emailOrId,
        password
    };
};

export const logout = () => {
    return {
        type: actionTypes.LOGOUT,
    };
};

export const signupSuccess = () => {
    return {
        type: actionTypes.SIGNUP_SUCCESS,
    };
};

export const loginSuccess = data => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        data
    };
};

export const authFailed = error => {
    return {
        type: actionTypes.AUTH_FAILED,
        error
    };
};

export const clearErrors = () => {
    return {
        type: actionTypes.CLEAR_ERRORS
    };
};

export const watchExpirationTime = () => {
    return {
        type: actionTypes.WATCH_EXPIRATION_TIME,
    };
};

export const refreshToken = () => {
    return {
        type: actionTypes.REFRESH_TOKEN
    };
};

export const sendForgotPassword = (email, callback) => {
    return {
        type: actionTypes.SEND_FORGOT_PASSWORD,
        email,
        callback
    };
};
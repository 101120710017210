import React from 'react';
import "./Registro.css";

export default class HabilidadesSeleccionadas extends React.Component{
    render() {
        return (
            <span onClick={ () => this.props.eliminaHabilidad(this.props.id) } id={this.props.id} className="habilidadSeleccionada">
                - {this.props.name}
            </span>
        );
    }
}
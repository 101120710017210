import React, { Component } from "react";
import Axios from "axios";

export default class Autenticacion extends Component {
  componentDidMount() {
    const info = {
      grant_type: "password",
      client_id: "2_3etfvfo7uduss44c0g0s4cw8k0wwgko0gsko0wsogc0s484o40",
      client_secret: "2b8zcel2d5q8wc4wswgksogcgosokcog8s8g0004k08owgwcck",
      username: "nombre@gmail.com",
      password: "12345",
    };
    Axios.post("http://localhost:8000/oauth/v2/token", info).then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log("User not authenticate ", err);
      }
    );
  }

  render() {
    return <h1>Autenticación</h1>;
  }
}

import React from 'react';

const recorridoBlock = props => (
    <div className="PuestoBlock flex-center">
        <figure style={{backgroundImage: `url(${props.imgPrincipal})`}}></figure>
        <div className="PuestoBlockContent">
            <p className={props.isJob ? 'caption caption-job' : 'caption caption-event'}>{props.caption}</p>
            <h3>{props.puesto}</h3>
            <p>{props.empresa}</p>
            <span><i><img src={props.imgPin} alt="Pin"/></i>{props.direccion}</span>
            <span className="block-distance">{props.distancia}km de mí</span>
            <hr/>
        </div>
    </div>
);

export default recorridoBlock;

import React from 'react';
import $ from 'jquery';
import RegistroEncabezado from "./../RegistroEncabezado/RegistroEncabezado";
import HabilidadesCategorias from "./HabilidadesCategorias";
import HabilidadesSeleccionadas from "./HabilidadesSeleccionadas";
import "./Registro.css";

// Images
import img_habilidades from './../../assets/images/habilidades.png';

export default class RegistroPaso3 extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            seleccionadas: [],
        }
        this.addAbility = React.createRef()
        this.abilityValue = React.createRef()
    }

    componentDidUpdate(){
        this.hideSelectedAbilities();
    }

    componentDidMount(){
        this.hideSelectedAbilities();
    }

    hideSelectedAbilities = () => {
        if(this.props.data.seleccionadas != []){
            const containers = document.querySelectorAll('.habilidadLista');
            for(let item of this.props.data.seleccionadas){
                containers[item.catIndex].querySelectorAll('span')[item.cat].style.display = 'none';
            }
        }
    }

    seleccionarHabilidad = (evt) => {
        if (evt.currentTarget.querySelector('.habilidadLista').style.display !== 'block'){
            evt.currentTarget.querySelector('.habilidadLista').style.display = 'block';
            evt.currentTarget.querySelector('.boton').innerHTML = '-';
        }
        else if(evt.currentTarget.querySelector('.habilidadLista').style.display == 'block' && evt.target.tagName.toLowerCase() != 'span'){
            evt.currentTarget.querySelector('.habilidadLista').style.display = 'none';
            evt.currentTarget.querySelector('.boton').innerHTML = '+';
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        if(this.props.data.seleccionadas.length == 0){
            this.props.openModal('Debes ingresar al menos una habilidad');
            return;
        }
        this.props.enviaDatos({
            experience: this.props.data.seleccionadas.map(item => item.id)
        });
        this.props.goNext();
    }

    backHandler = (e) => {
        e.preventDefault();
        this.props.retornaPaso2();
    }

    nuevaHabilidad = (e) => {
        $('.nuevaHabilidad').fadeOut(500);
        $('.agregarHabilidadContainer').fadeIn(500);
    }

    btnAgregar = (e) => {
        e.preventDefault();
    }

    removeSelectedItem = () => {
    }

    // Agrega la categoría a habilidades seleccionadas
    agregarItem = (name, id, cat, catIndex) => {
        let isRepeated = false;
        for (let item of this.props.data.seleccionadas) {
            if (item.id === id && item.cat === cat) {
                isRepeated = true;
                break;
            } 
        }
        if (!isRepeated && this.props.data.seleccionadas.length < 20) {
            this.props.enviaDatos({
                seleccionadas: this.props.data.seleccionadas.concat({name, id, cat, catIndex})
            });
        }
    }

    eliminaHabilidad = (id) => {
        const element = this.props.data.seleccionadas.filter(item => item.id === id);
        const containers = document.querySelectorAll('.habilidadLista');
        containers[element[0].catIndex].querySelectorAll('span')[element[0].cat].style.display = 'inline-block';

        const updatedSeleccionadas = this.props.data.seleccionadas.filter(item => item.id !== id);
        this.props.enviaDatos({seleccionadas: updatedSeleccionadas})
    }

    // showAbilitieText() {
    //     this.addAbility.current.style.display = 'flex';
    // }

    // addAbilityText() {
    //     console.log(this.abilityValue)
    //     const name = this.abilityValue.current.value;
    //     this.props.enviaDatos({
    //         seleccionadas: this.props.data.seleccionadas.concat({name})
    //     });
    // }

    render() {
        return (
            <div className="registro paso3">
                <RegistroEncabezado paso="3"></RegistroEncabezado>
                <form onSubmit={this.submitHandler}>
                    <div className="content container">
                        <img className="icon" src={img_habilidades} alt="Habilidades" />
                        <h1>Habilidades</h1>
                        <h4>8. ¿Cuáles son tus habilidades?</h4>
                        <p>Las habilidades ayudarán a encontrar trabajos especiales para vos. Podés elegir un máximo de 20 etiquetas.</p>
                        
                        <h4>Habilidades seleccionadas: <span className="counter">{this.props.data.seleccionadas.length}</span></h4>
                        <div className="habilidadesSeleccionadas">
                        {
                            this.props.data.seleccionadas.map((habilidad,item) => (
                                <HabilidadesSeleccionadas
                                    key={item}
                                    name={habilidad.name}
                                    id={habilidad.id}
                                    catId={habilidad.category}
                                    eliminaHabilidad={this.eliminaHabilidad}
                                />)
                            )
                        }
                        </div>

                        {
                            this.props.sectors.map((categoria, item) => (
                                <HabilidadesCategorias 
                                    key={item}
                                    parentIndex={item} 
                                    name={categoria.name}
                                    habilidades={categoria.categories} 
                                    cat={categoria.id}
                                    click={this.seleccionarHabilidad}
                                    agregarItem={this.agregarItem}
                                />
                            ))
                        }
                        {/*
                            <a className="add-ability-option" onClick={this.showAbilitieText.bind(this)}>+ Escribir otra habilidad</a>
                            <div ref={this.addAbility} className="add-abilities-container flex-center justify-start">
                                <input ref={this.abilityValue} />
                                <button onClick={this.addAbilityText.bind(this)}>Agregar</button>
                            </div>
                        */}
                        
                        {/* <a href="#habilidad" onClick={this.nuevaHabilidad} className="nuevaHabilidad">+ Escribir otra habilidad</a>
                        
                        <div className="agregarHabilidadContainer">
                            <input type="text" id="txtNuevaHabilidad" placeholder="Escribir habilidad"/><br/>
                            <button className="btn btn-primary agregar" onClick={this.btnAgregar}>AGREGAR</button>
                        </div> */}

                        <div className="buttons">
                            <button className="btn btn-primary white" onClick={this.backHandler}>ATRÁS</button>
                            <input type="submit" value="CONTINUAR" className="btn btn-primary" />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
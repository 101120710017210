import React from 'react';
import imageSrc from '../../../assets/images/marker.svg';

const marker = () => {
    return (
        <div style={{ position: 'relative' }}>
            <img 
                src={ imageSrc } 
                alt="marcador" 
                style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '50%',
                    transform: 'translatex(-50%)'
                }}
            />
        </div>
    );
};

export default marker;
import { put, select } from 'redux-saga/effects';
import axios from '../axios';
import * as actions from '../actions';
import * as selectors from './selectors';

export function* fetchHelpSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/ayuda', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setHelp(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* fetchCaseDataSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/abrir-caso', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setCaseData(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* openCaseSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/abrir-caso/' + action.caseType, {} ,{headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setCaseResult(response.data));
            yield put(actions.uiShowModal(
                'Listo', 
                'Tu solicitud ha sido enviada a la fundación. Pronto te contactaremos', 
                1, 
                'Entendido', 
                '', 
                action.callback, 
                null
            ));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiShowModal(
                'Error', 
                'Ha ocurrido un error inesperado.', 
                0, 
                'Cerrar', 
                '', 
                null, 
                null
            ));
            yield put(actions.uiStopLoading());
        }
    }
}
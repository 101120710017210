import React from 'react';
import Login from './../Login/Login';
import { Link } from 'react-router-dom';
import "./Ingreso.css";

// Images
import logo from './../../assets/images/faj-logo.png';


class Ingreso extends React.Component{
    
    render() {
        return (
            <div className="ingreso">
                <div className="info-top">
                    <img className="center-block" src={logo} alt="Fundaci&oacute;n Acci&oacute;n Joven" />
                    <h1>Iniciar sesi&oacute;n</h1>
                    <p>Ingresá o creá hoy mismo tu cuenta para conocer cuáles puestos de trabajo hay disponibles cerca de tu lugar de residencia.</p>
                    <Link className="arrow" to="#login-form"><span className="glyphicon glyphicon-menu-down"></span></Link>
                </div>
                <Login/>
                <div className="olvide-contrasena">
                    <Link to="/user/forgot">Olvid&eacute; mi contrase&ntilde;a</Link>
                    <p>¿No ten&eacute;s una cuenta? <Link to="/user/signup">Crear cuenta</Link></p>

                </div>


            </div>
        );
    }
}

export default Ingreso;
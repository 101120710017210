import React from 'react';

export default class Afinidad extends React.Component{
    renderAffinity() {
        return (
            <li className="affinity">
                {this.props.affinity.name}
            </li>
        );
    }
    
    render() {
        return (
            this.renderAffinity()
        );
    }
}
import { put, select } from 'redux-saga/effects';
import axios from '../axios';
import * as actions from '../actions';
import * as selectors from './selectors';

export function* fetchEditProfileDataSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/perfil/editar', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setEditProfileData(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* fetchProfileDataSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/perfil', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setProfileData(response.data));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* updateProfileSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/perfil/editar', action.data ,{headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setEditProfileData(response.data));
            const imageResponse = yield axios.post('api/joven/perfil/editar-foto', action.image ,{headers:{'Authorization': 'Bearer ' + accessToken, 'Content-Type': 'multipart/form-data'}});
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

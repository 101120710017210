import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { sendMessageToFAJ } from '../../store/actions';
import { BackButton, Spinner } from '../ui';

import './Mensajes.css'

class MensajeFAJ extends Component {

    state = {
        messageText: ''
    };

    handleChange = e => {
        this.setState({messageText: e.target.value})
    }

    sendMessageHandler = () => {
        this.props.onSendMessage(
            this.state.messageText,
            () => this.props.history.replace('/mensajes')
        );
    }

    render() {
        let content = null;
        if (this.props.isLoading) {
            content = (
                <Spinner />
            );
        } else {
            content = (
                <div className="container messages-container">
                    <h4>Mensaje para FAJ</h4>
                    <div className="conversations-container show faj-message">
                        <textarea 
                            value={this.state.messageText} 
                            onChange={this.handleChange} 
                            placeholder="Escribe el mensaje que deseas enviar">
                        </textarea>
                    </div>
                    <button 
                        disabled={this.state.messageText === ''} 
                        className="principal-button"
                        onClick={ this.sendMessageHandler }
                    >
                        Enviar mensaje
                    </button>
                </div>
            );
        }
        return (
            <div>
                <TopTools />
                <BackButton />
                {content}
                <BottomTools />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSendMessage: (message, callback) => dispatch(sendMessageToFAJ(message, callback))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MensajeFAJ);

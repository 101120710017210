import React from 'react';

import "./RegistroEncabezado.css";

export default class RegistroEncabezado extends React.Component{
    
    registroCompletado() {
        return <h1>¡Registro completado con éxito!</h1>;
    }

    registroEnProgreso() {
        return <div><h1>Empecemos tu registro</h1><p>Completá estos datos para poder crear tu perfil</p></div>;
    }
    
    render() {
        //throw new Error ("Algo esta pasando");
        return (
            <div className="infoTop">
                { this.props.paso === "6" ? this.registroCompletado() : this.registroEnProgreso() }
                <div className="numbers">
                    <div className={this.props.paso === "1" ? 'active': (this.props.paso > "1" ? 'checked': '')}>1</div>
                    <div className={this.props.paso === "2" ? 'active': (this.props.paso > "2" ? 'checked': '')}>2</div>
                    <div className={this.props.paso === "3" ? 'active': (this.props.paso > "3" ? 'checked': '')}>3</div>
                    <div className={this.props.paso === "4" ? 'active': (this.props.paso > "4" ? 'checked': '')}>4</div>
                    <div className={this.props.paso === "5" ? 'active': (this.props.paso > "5" ? 'checked': '')}>5</div>
                </div>
            </div>
        );
    }
}

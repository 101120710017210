import * as actionTypes from './actionTypes';

export const setEvents = data => {
    return {
        type: actionTypes.SET_EVENTS,
        alerts: data.alerts,
        data: data.data,
        events: data.events,
        filters: data.filters,
        myEvents: data.my_events
    };
};

export const fetchEvents = () => {
    return {
        type: actionTypes.FETCH_EVENTS,
    };
};

export const applyEventsFilters = filters => {
    return {
        type: actionTypes.APPLY_EVENTS_FILTERS,
        filters
    };
};

export const fetchEvent = id => {
    return {
        type: actionTypes.FETCH_EVENT,
        id
    };
};

export const setEvent = data => {
    return {
        type: actionTypes.SET_EVENT,
        event: data
        // event: data.event,
        // hasEvent : data.hasEvent,
        // isEventDay: data.is_eventDay
    };
};

export const toggleEventAssistence = (id, callback) => {
    return {
        type: actionTypes.TOGGLE_EVENT_ASISTANCE,
        id,
        callback
    };
};

export const reportEvent = id => {
    return {
        type: actionTypes.REPORT_EVENT,
        id
    };
};

export const eventReportedSuccess = id => {
    return {
        type: actionTypes.EVENT_REPORTED,
        id
    };
};

export const sendEventCode = (eventId, code, callback) => {
    return {
        type: actionTypes.SEND_EVENT_CODE,
        eventId,
        code,
        callback
    };
};
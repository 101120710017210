import React from 'react';
import "./Registro.css";

export default class HabilidadesCategorias extends React.Component{


    render() {
        return (
            <div className="habilidadCategoria" onClick={this.props.click}>
                {this.props.name} <span className="boton">+</span>
                <hr />
                <div className="habilidadLista">
                {
                    this.props.habilidades.map(
                        (habilidad,item) => (
                            <span 
                                onClick={()=>{this.props.agregarItem(habilidad.name, habilidad.id, item, this.props.parentIndex)}} 
                                key={habilidad.id}
                                >
                                    + {habilidad.name}
                            </span>)
                    )
                }
                </div>
            </div>
        );
    }
}
import React from 'react';

export default class AfinidadIndicador extends React.Component{
    render() {
        let points = [];
        for (let i = 0; i < 4; i++){
            points.push(<span key={i} className={this.props.afinidad > i ? "active" : ""}></span>);
        }
        return (
            <span className="AfinidadIndicador">
                {points}
            </span>
        );
    }
}

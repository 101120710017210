import React from 'react';
import RegistroEncabezado from "./../RegistroEncabezado/RegistroEncabezado";
import Cursos from './Cursos';
import EditarExperiencia from '../Perfil/EditarExperiencia';
import "./Registro.css";

export default class RegistroPaso5 extends React.Component {

    state = {
        study: '0',
        academic_center: '0',
        academic_center_degree: '',
        career_name: '',
        academic_center_name: '',
        courses: [''],
        position_company: [],
        position_position: [],
        position_years: [],
        position_description: [],
        maxNumber1: 13
    }

    inputChangedHandler = e => {
        console.log(this.props);
        let updatedProperty = {};
        updatedProperty[e.target.name] = e.target.value;
        this.props.enviaDatos(updatedProperty);
        if(this.state.maxNumber1 == 13 && e.target.name == 'academic_center'){
            if(this.props.data.academic_center == "0"){
                if(this.props.data.academic_center_degree == "" && this.props.data.career_name == ""){
                    this.setState({maxNumber1: this.state.maxNumber1 + 1})
                }
                else if(Number(this.props.data.academic_center_degree) < 12){
                    this.setState({maxNumber1: this.state.maxNumber1 + 1})
                }
                else{
                    this.setState({maxNumber1: this.state.maxNumber1 + 3})
                }
            }
        }
        else if(this.state.maxNumber1 == 14 && e.target.name == 'academic_center'){
            this.setState({maxNumber1: this.state.maxNumber1 - 1})
        }
        else if(this.state.maxNumber1 == 16 && e.target.name == 'academic_center' && Number(e.target.value) == 0){
            this.setState({maxNumber1: this.state.maxNumber1 - 3})
        }
        else if(this.state.maxNumber1 == 14 && e.target.name == 'academic_center_degree' && Number(e.target.value) >= 12){
            this.setState({maxNumber1: this.state.maxNumber1 + 2})
        }
        else if(this.state.maxNumber1 == 16 && e.target.name == 'academic_center_degree' && Number(e.target.value) < 12){
            this.setState({maxNumber1: this.state.maxNumber1 - 2})
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        const data = {
            ...this.props.data
        }
        if (this.props.academicDegrees && data.academic_center_degree !== '') {
            if (!this.props.academicDegrees[parseInt(data.academic_center_degree)].show_careers) {
                data.career_name = '';
                data.academic_center_name = '';
            }
        }
        this.props.enviaDatosFinales(data);
    }

    backHandler = (e) => {
        e.preventDefault();
        this.props.retornaPaso4();
    }

    coursesUpdateHandler = updatedArray => {
        this.props.enviaDatos({
            courses: updatedArray
        });
    }

    updateInformationHandler = updatedProperty => {
        this.props.enviaDatos(updatedProperty);
    }

    render() {
        let showCareerField = false;
        if (this.props.academicDegrees && this.props.data.academic_center_degree !== '') {
            showCareerField = this.props.academicDegrees[parseInt(this.props.data.academic_center_degree - 1)].show_careers
        }
        console.log(showCareerField);
        return (
            <div className="registro paso5">
                <RegistroEncabezado paso="5"></RegistroEncabezado>
                <form onSubmit={this.submitHandler}>
                    <div className="content container">
                        <h1>Experiencia</h1>
                        <h4>10. ¿Tenés experiencia laboral?</h4>
                        <p>Podés agregar varios trabajos con su respectivo puesto y meses de experiencia. Si no tenés experiencia<br></br>
                        laboral no hay ningún problema, dejá este espacio vacío.</p>
                        <h4>Experiencia</h4>
                        <EditarExperiencia
                            position_company={ this.props.data.position_company }
                            position_position={ this.props.data.position_position }
                            position_years={ this.props.data.position_years }
                            position_description={ this.props.data.position_description }
                            onUpdate={ this.updateInformationHandler }
                        />
                        <h1>Cursos</h1>
                        <h4>11. ¿Estás estudiando actualmente?</h4>
                        <div className="flex-labels">
                            <label>
                                <input 
                                    type="radio" 
                                    name="study" 
                                    value="1" 
                                    checked={ this.props.data.study === '1'}
                                    onChange={ this.inputChangedHandler }
                                /> 
                                Sí
                            </label>
                            <label>
                                <input 
                                    type="radio" 
                                    name="study" 
                                    value="0" 
                                    checked={ this.props.data.study === '0'}
                                    onChange={ this.inputChangedHandler }
                                /> 
                                No
                            </label>
                        </div>
                        <h4>12. ¿Cursaste estudios en algún centro académico?</h4>
                        <div className="flex-labels">
                            <label>
                                <input 
                                    type="radio" 
                                    name="academic_center" 
                                    value="1" 
                                    checked={ this.props.data.academic_center === '1'}
                                    onChange={ this.inputChangedHandler }
                                /> 
                                Sí
                            </label>
                            <label>
                                <input 
                                    type="radio" 
                                    name="academic_center" 
                                    value="0" 
                                    checked={ this.props.data.academic_center === '0'}
                                    onChange={ this.inputChangedHandler }
                                /> 
                                No
                            </label>
                        </div>

                        { this.props.data.academic_center === '1' && (
                            <>
                                <h4>13. ¿Tú último nivel cursado?</h4>
                                <select 
                                    id="academic_center_degree" 
                                    name="academic_center_degree" 
                                    onChange={this.inputChangedHandler} 
                                    value={ this.props.data.academic_degree }
                                >
                                    <option 
                                        value="">Educación
                                    </option>
                                    { this.props.academicDegrees && this.props.academicDegrees.map(item => (
                                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                                    ))}
                                </select>
                            </>
                        )}
                       
                       { (showCareerField && this.props.data.academic_center === '1') && (
                           <>
                            <h4>14. ¿Cuál fue la carrera que estudiaste?</h4>
                            <select 
                                id="career_name" 
                                name="career_name" 
                                onChange={this.inputChangedHandler} 
                                value={ this.props.data.career_name }
                            >
                                <option 
                                    value="">Carrera
                                </option>
                                { this.props.careers.map(item => (
                                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                                ))}
                            </select>
                            <h4>15. ¿En que centro educativo estudiaste?</h4>
                            <input 
                                type="text" 
                                name="academic_center_name" 
                                placeholder="Nombre del centro educativo"
                                onChange={ this.inputChangedHandler } 
                            />
                           </>
                       )}
                       
                        <h4><span>{this.state.maxNumber1}.</span>¿Has llevado algún curso libre o técnico?</h4>
                        <p>Podés agregar varios cursos libres o técnicos. Si no has llevado alguno no hay ningún problema, dejá este espacio vacío.</p>

                        <Cursos data={ this.props.data.courses } onChange={ this.coursesUpdateHandler } />

                        <div className="recordatorio">
                            <h4>Recordá</h4>
                            <p>Esta aplicación no garantiza bajo ninguna circunstancia la obtención o permanencia en ninguna de las ofertas.</p>
                        </div>

                        <div className="buttons">
                            <button className="btn btn-primary white" onClick={this.backHandler}>ATRÁS</button>
                            <input type="submit" value="CONTINUAR" className="btn btn-primary" />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
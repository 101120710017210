import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Bienvenida from './components/Bienvenida/Bienvenida';
import Ingreso from './components/Ingreso/Ingreso';
import Filtro from './components/Filtro/Filtro';
import Registro from './components/Registro/Registro';
import Puestos from './components/Puestos/Puestos';
import Login from './components/Login/Login';
import NotFound from './components/NotFound/NotFound';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import OlvidoContrasena from './components/OlvidoContrasena/OlvidoContrasena';
import Autenticacion from './components/Login/Autenticacion';
import PuestoDetalle from './components/Puestos/PuestoDetalle';
import PuestoReportar from './components/Puestos/PuestoReportar';
import Eventos from './components/Eventos/Eventos';
import EventoDetalle from './components/Eventos/EventoDetalle';
import FormTest from './components/FormTest/FormTest';
import Oportunidades from './components/Oportunidades';
import Perfil from './components/Perfil';
import EditarPerfil from './components/Perfil/EditarPerfil';
import Medallas from './components/Perfil/Medallas';
import Ayuda from './components/Ayuda';
import AyudaPreguntas from './components/Ayuda/AyudaPreguntas';
import Asistencia from './components/Ayuda/Asistencia';
import Orientacion from './components/Ayuda/Orientacion';
import Mensajes from './components/Mensajes/Mensajes';
import Conversacion from './components/Mensajes/Conversacion';
import MensajeFAJ from './components/Mensajes/MensajeFAJ';
import Modal from '../src/components/Modal';

const Router = props => {
    const routes = [];
    if (props.isAuth) {
        // routes.push({ path: '/user/filter', component: Filtro })
        routes.push({ path: '/user/login', component: Ingreso })
        routes.push({ path: '/perfil', component: Perfil })
        routes.push({ path: '/perfil/medallas', component: Medallas })
        routes.push({ path: '/perfil/editar', component: EditarPerfil })
        routes.push({ path: '/ayuda', component: Ayuda })
        routes.push({ path: '/ayuda/asistencia/:id', component: Orientacion })
        routes.push({ path: '/ayuda/asistencia', component: Asistencia })
        routes.push({ path: '/ayuda/:id', component: AyudaPreguntas })
        routes.push({ path: '/puestos', component: Puestos })
        routes.push({ path: '/puestos/oportunidades', component: Oportunidades })
        routes.push({ path: '/puestos/reportar/:id', component: PuestoReportar })
        routes.push({ path: '/puestos/:id', component: PuestoDetalle })
        // routes.push({ path: '/puestos/aplicados', component: PuestosAplicados })
        routes.push({ path: '/eventos', component: Eventos })
        routes.push({ path: '/eventos/:id', component: EventoDetalle });
        routes.push({ path: '/form-test', component: FormTest })
        routes.push({ path: '/mensajes', component: Mensajes })
        routes.push({ path: '/conversacion/:id', component: Conversacion })
        routes.push({ path: '/mensajes/faj', component: MensajeFAJ })
        routes.push({ path: '/', component: Puestos })
    } else {
        routes.push({ path: '/user/login', component: Ingreso })
        routes.push({ path: '/user/auth', component: Autenticacion })
        routes.push({ path: '/user/forgot', component: OlvidoContrasena })
        routes.push({ path: '/user/login_basic', component: Login })
        routes.push({ path: '/user/signup', component: Registro })
        routes.push({ path: '/', component: Bienvenida })
    }
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <>
                <Switch>
                    { routes.map(item => (
                        <Route exact key={ item.path } {...item} />
                    ))}
                    <Route component={NotFound} />
                </Switch>
                <Modal />
                </>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuthenticated,
    }
}

export default connect(mapStateToProps)(Router);
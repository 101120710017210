import { put, select } from 'redux-saga/effects';
import axios from '../axios';
import * as actions from '../actions';
import * as selectors from './selectors';

export function* fetchConversationsSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/mensajes', {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setConversations(response.data.conversations));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* fetchMessagesSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.get('/api/joven/mensajes/' + action.conversationId , {headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.setMessages(response.data.messages));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            if (action.errorCallback) {
                action.errorCallback();
            }
            yield put(actions.uiStopLoading());
        }
    }
}

export function* sendMessagesSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/mensajes/escribir/' + action.conversationId, { message: action.message } ,{headers:{'Authorization': 'Bearer ' + accessToken}});
            yield put(actions.fetchMessages(action.conversationId));
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}

export function* sendMessagesToFAJSaga(action) {
    const accessToken = yield select(selectors.accessToken);
    if (accessToken) {
        yield put(actions.uiStartLoading());
        try {
            const response = yield axios.post('/api/joven/mensajes/faj/create', { message: action.message } ,{headers:{'Authorization': 'Bearer ' + accessToken}});
            if (action.callback) {
                action.callback();
            }
            yield put(actions.uiStopLoading());
        } catch(error) {
            // console.log(error);
            yield put(actions.uiStopLoading());
        }
    }
}
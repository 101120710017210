import React from 'react';
import { Link } from 'react-router-dom';
import RegistroEncabezado from "./../RegistroEncabezado/RegistroEncabezado";
import "./Registro.css";

// Images
import img_listo from './../../assets/images/listo.png';

export default class RegistroFinal extends React.Component{
    
    render() {
        //throw new Error ("Algo esta pasando");
        return (
            <div className="registro paso6">
                <RegistroEncabezado paso="6">¡Registro completado con éxito!</RegistroEncabezado>
                <div className="content">
                    <img className="imagenListo" src={img_listo} alt="Listo" />
                    <h1 className="success-title">¡Todo listo!</h1>
                    <p>Ya tu perfil está configurado, podés empezar tu búsqueda de empleo y oportunidades.</p>
                    <div className="buttons">
                        <Link to="/user/login" className="btn btn-primary">COMENZAR</Link>
                    </div>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopTools from '../Internal/TopTools';
import BottomTools from '../Internal/BottomTools';
import { BackButton, Spinner } from '../ui';
import { fetchMedals } from '../../store/actions';
import medal from '../../assets/images/medalla-large.png';

class Medallas extends Component {

    componentDidMount() {
        this.props.onFetchData();
    }
    

    renderMedals = (medals) => {
        return medals.map(medalObject => {
            return (
                <div className="big-medal-container flex justify-start nowrap" key={ medalObject.id }>
                    <img src={medal} alt="medalla "/>
                    <div>
                        <h3>{medalObject.name}</h3>
                        <p>{medalObject.obtainedAt}</p>
                        <span>{ medalObject.aquiredAt }</span>
                    </div>
                </div>
            )
        });
    }

    render() {
        let content = null;
        if (this.props.isLoading) {
            content = (
                <Spinner />
            )
        } else {
            content = (
                <div className="container path-container medals-page-container">
                    {this.renderMedals(this.props.medals)}
                </div>
            )
        }
        return (
            <div>
                <TopTools></TopTools>
                <BackButton />
                { content }
                <BottomTools></BottomTools>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        medals: state.medals.medals,
        isLoading: state.ui.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchData: () => dispatch(fetchMedals()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Medallas);
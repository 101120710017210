import { put } from 'redux-saga/effects';
import axios from '../axios';
import * as actions from '../actions';

export function* fetchCantonsSaga(action) {
    try {
        const response = yield axios.get(`/ajax/province/${action.provinceId}/cantons`);
        yield put(actions.setCantons(response.data))
    } catch(error) {
        // console.log(error)
    }
}

export function* fetchDistrictsSaga(action) {
    try {
        const response = yield axios.get(`/ajax/canton/${action.cantonId}/districts`);
        yield put(actions.setDistrics(response.data))
    } catch(error) {
        // console.log(error)
    }
}